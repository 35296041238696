import React, { useEffect, useState, useRef } from 'react';
import {
  Input,
  Select,
  DatePicker,
  Button,
  Space,
  Menu,
  Dropdown,
  Popover,
  Card,
  Tag
} from 'antd';
import { history, connect, useIntl } from 'umi';
import DocumentTitle from 'react-document-title';
import {
  deepCopy,
  cleanObject,
  showErrorMsg,
  eventDebounce,
} from 'cognitiveleap-core-us/utils/utils';
import { getFormatDate, getGroupTag } from 'utils/utils';
// import { UpOutlined, DownOutlined } from '@ant-design/icons';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import ExpandedTable from './components/ExpandedTable';
import ManageChildModal from 'components/ClassGroupModal/ManageChildModal';
import ConnectTrainPlan from 'components/ClassGroupModal/ConnectTrainPlan';
import AddOrEditClassModal from 'components/ClassGroupModal/AddOrEditClassModal';
import moment from 'moment';

import { GetGroupList, AssignTrainers } from 'services/group';
import { PostClassList } from 'services/userManager';

import styles from './index.less';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ClassList = ({ currentUser }) => {

  const intl = useIntl()

  const { auth, changedTenant, current, id: userId } = currentUser || {};
  const { institutionType } = changedTenant;
  const { grantedPolicies } = auth;
  const { roles = [] } = current;
  const hasAssignCoachPermission =
    grantedPolicies['Business.SubjectManagement.UserCoach.Assign'] === true; // 分配训练师权限
  const hasGetCoachListPermission = grantedPolicies['Business.TenantUserManagement'] === true; // 获取训练师列表权限
  const isEnterpriseAgent = roles.some(item => item.name === 'EnterpriseAgent')
  const isEnterpriseAdmin = roles.some(item => item.name === 'EnterpriseAdmin')

  const listSupport = {
    name: {
      showText: intl.formatMessage({ id: 'className'}),
      showType: 'Text',
    },
    type: {
      showText: intl.formatMessage({ id: 'classType'}),
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: [
          { text: `${intl.formatMessage({ id: 'OneToOne'})}`, value: 'OneToOne' },
          { text: `${intl.formatMessage({ id: 'OneToTwo'})}`, value: 'OneToTwo' },
          { text: `${intl.formatMessage({ id: 'OneToThree'})}`, value: 'OneToThree' },
          { text: `${intl.formatMessage({ id: 'OneToFour'})}`, value: 'OneToFour' },
          { text: `${intl.formatMessage({ id: 'OneToFive'})}`, value: 'OneToFive' },
          { text: `${intl.formatMessage({ id: 'OneToSix'})}`, value: 'OneToSix' },
          { text: `${intl.formatMessage({ id: 'OneToMany'})}`, value: 'OneToMany' },
        ],
      },
      render: (text, _) => {
        return getGroupTag(text, intl)
      },
    },
    groupLinkUsers: {
      showText: intl.formatMessage({ id: 'numberOfChildren'}),
      showType: 'Text',
      render: (text, _) => {
        if (text.length === 0) {
          return 0;
        }
        return text.length;
      },
    },
    capacity: {
      showText: intl.formatMessage({ id: 'maximum'}),
      showType: 'Text',
      render: (text, record) => {
        if (record.type === 'OneToOne') {
          return 1;
        }
        return text;
      },
    },
    // ageStage: {
    //   showText: intl.formatMessage({ id: 'stage'}),
    //   showType: 'Text',
    //   render: (text, _) => {
    //     if (text === 'GradeOrTeenSchool') {
    //       return `${intl.formatMessage({ id: 'grade_teenage'})}`;
    //     } else if (text === 'PreSchool') {
    //       return `${intl.formatMessage({ id: 'preschool'})}`;
    //     }
    //     return `${intl.formatMessage({ id: 'GoldenAge'})}`;
    //   },
    // },
    groupTrainingPlans: {
      showText: intl.formatMessage({ id: 'currentTrainingPlan'}),
      showType: 'Text',
      render: (text, _) => {
        return text
          .filter((item) => item.status === 'Started')
          .map((item) => item.trainingPlanV2.displayName);
      },
    },
    groupLinkTrainers: {
      showText: intl.formatMessage({ id: 'trainer'}),
      showType: 'Text',
      render: (text, record) => {
        if (text.length === 0) {
          return '';
        } else if (text.length === 1) {
          return text[0].name;
        } else {
          return (
            <Popover
              title={intl.formatMessage({ id: 'allTrainer'})}
              key={record.id}
              content={text.map((item) => {
                return item ? (
                  <div key={item.id} className={styles.popover}>
                    {item.name}
                  </div>
                ) : (
                  <div className={styles.popover}>-</div>
                );
              })}
            >
              <span style={{ cursor: 'pointer' }}>
                {text[0].name + ' - (' + text.length + ')'}
              </span>
            </Popover>
          );
        }
      },
    },
    creationTime: {
      showText: intl.formatMessage({ id: 'classCreateTime'}),
      showType: 'Time',
    },
  };

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={true}
        onClose={onClose}
        style={{
          marginRight: 5,
          color: closable ? '#000000' : '#b8b8b8',
        }}
      >
        {label}
      </Tag>
    );
  };

  const assignTrainContent = {
    Properties: [
      {
        EditorType: 'SingleWithSearch',
        Value: null,
        Setting: {
          DropdownOptions: [],
          Required: true,
          Props: {
            mode: 'multiple',
            defaultValue: [],
            optionLabelProp: 'label',
            tagRender
          },
        },
        ShowTitle: intl.formatMessage({ id: 'assignCoach'}),
        FormKey: 'trainerIds',
        Description: null,
      },
    ],
  };

  const currentRecord = useRef({});

  const [listData, setListData] = useState([]); // 班级列表数据

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // 一些操作
  const [options, setOptions] = useState({
    classType: 'add',
    // open: true,
  });

  // 弹窗数据
  const [modalContent, setModalContent] = useState({
    assignContent: assignTrainContent,
  });

  // 搜索数据
  const [searchKey, setSearchKey] = useState({
    Filter: null,
    Types: null,
    AgeStage: null,
    beginTime: null,
    endTime: null,
  });

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [loading, setLoading] = useState({
    tableLoading: false,
    classModalLoading: false,
    trainModalLoading: false,
    assignModalLoading: false,
  });

  // 连续打开弹窗，写在一个State里不好操作
  const [classModal, setClassModal] = useState(false);
  const [trainModal, setTrainModal] = useState(false);
  const [selectChildModal, setSelectChildModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);

  const getListData = async () => {
    setLoading({ ...loading, tableLoading: true });

    // 搜索不跳过数据
    // const noSkip = searchKey.Filter || searchKey.Types || searchKey.AgeStage;

    const res = await GetGroupList(
      cleanObject({
        ...searchKey,
        SkipCount: (pagination.current - 1) * pagination.pageSize,
        //SkipCount: noSkip === null ? (pagination.current - 1) * pagination.pageSize : 0,
        MaxResultCount: pagination.pageSize,
      }),
    );
    if (res.response.ok) {
      setListData(res.data.items);
      setPagination({
        ...pagination,
        total: res.data.totalCount || 0,
      });
    } else {
      showErrorMsg(res, intl);
    }
    setLoading({ ...loading, tableLoading: false });
  };

  useEffect(() => {
    eventDebounce(() => getListData(), 350);
  }, [searchKey, pagination.current]);

  // 添加修改班级
  const addOrEditClass = (type, record = {}) => {
    setOptions({
      ...options,
      classType: type,
    });
    if (type === 'edit') {
      currentRecord.current = record;
    }
    setClassModal(true);
  };

  // 关联训练计划弹窗
  const connectedTrainPlan = (record = {}) => {
    setTrainModal(true);
    if (JSON.stringify(record) !== '{}') {
      currentRecord.current = record;
    }
  };

  // 关联训练师弹窗
  const assignCoachModal = async (record = {}) => {
    currentRecord.current = record;
    if (hasAssignCoachPermission && hasGetCoachListPermission) {
      const res = await PostClassList({
        roles: ["Coach", "IndependentCoach", "SeniorCoach", "Senior Coach"],
        skipCount: 0,
        maxResultCount: 999
      });
      if (res.response.ok) {
        const { items } = res.data || {};
        let tempData = deepCopy(assignTrainContent);
        // const newData = items.filter((item) => item.isActive);

        tempData.Properties[0].Setting.DropdownOptions = items.map((item) => {
          const { id, name, email, isActive, state, roles } = item || {};
          const { displayName, name: roleName } = roles[0] || {};

          const showName = name || intl.formatMessage({ id: 'No Name' });

          if (roleName === 'IndependentCoach') {
            return {
              Value: showName + ' - ' + displayName + ' - ' + email,
              Id: id,
              ShowLabel: showName,
              DisableToolTip: intl.formatMessage({ id: 'Permission Class Module' }),
            };
          }

          if (!isActive) {
            return {
              Value: showName + ' - ' + displayName + ' - ' + email,
              Id: id,
              ShowLabel: showName,
              DisableToolTip: intl.formatMessage({ id: 'AccountNotActivated' }),
            };
          }

          if (state !== 'Effective') {
            return {
              Value: showName + ' - ' + displayName + ' - ' + email,
              Id: id,
              ShowLabel: showName,
              DisableToolTip: intl.formatMessage({ id: 'Invalid Account' }),
            };
          }

          return {
            Value: showName + ' - ' + displayName + ' - ' + email,
            Id: id,
            ShowLabel: showName,
          };
        });

        // 显示已有训练师
        if (record.groupLinkTrainers.length > 0) {
          const trainerList = record.groupLinkTrainers.map((item) => {
            return item.id;
          });
          tempData.Properties[0].Value = trainerList;
          tempData.Properties[0].Setting.Props.defaultValue = trainerList;
        }

        setModalContent({
          ...modalContent,
          assignContent: tempData,
        });
      } else {
        showErrorMsg(res, intl);
      }
      setAssignModal(true);
    } else {
      const { id } = record;
      await AssignTrainers({
        groupId: id,
        trainerIds: [userId],
      });
      getListData();
      connectedTrainPlan();
    }
  };

  // 班级列表
  const tableProps = {
    listData,
    listSupport: listSupport,
    filters: { type: searchKey.Types },
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: (record) => <ExpandedTable allData={record} />,
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      };
    },
    loading: loading.tableLoading,
    pagination,
    onTableChange(page, filt) {
      if (filt !== undefined) {
        const { type } = filt;
        setSearchKey({
          ...searchKey,
          Types: type,
        });
      }

      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }

      expandedRowKeys.length > 0 && setExpandedRowKeys([]);
    },
    Actions: !(isEnterpriseAgent || isEnterpriseAdmin) && [
      {
        showText: intl.formatMessage({ id: 'detail'}),
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                window.open(`/system/center/detail/groupClassDetail?classId=${record.id}`, '_blank');
                // history.push({
                //   pathname: '/system/center/detail/groupClassDetail',
                //   query: {
                //     classId: record.id,
                //   },
                // });
              }}
            >
              {intl.formatMessage({ id: 'detail'})}
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'assignCoach'}),
        itemRender(record) {
          return hasAssignCoachPermission ? (
            <a
              onClick={(e) => {
                e.stopPropagation();
                assignCoachModal(record);
              }}
            >
              {intl.formatMessage({ id: 'assignCoach'})}
            </a>
          ) : null;
        },
      },
      {
        showText: intl.formatMessage({ id: 'associatePlan'}),
        itemRender(record) {
          // 有训练计划不可编辑了
          const activePlan = record.groupTrainingPlans.find(
            (plan) => plan.status === 'Started',
          );
          // 没有有效的训练，或者没有安排过训练
          if (!activePlan || record.groupTrainingPlans.length === 0) {
            return (
              <a
                onClick={(e) => {
                  e.stopPropagation();
                  connectedTrainPlan(record);
                }}
              >
                {intl.formatMessage({ id: 'associatePlan'})}
              </a>
            );
          }
          return null;
        },
      },
      {
        showText: intl.formatMessage({ id: 'manageChildren'}),
        itemRender(record) {
          return (
            <a
              onClick={async (e) => {
                e.stopPropagation();

                currentRecord.current = record;
                setSelectChildModal(true);
              }}
            >
              {intl.formatMessage({ id: 'manageChildren'})}
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'more'}),
        itemRender(record) {
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="edit">
                    <div
                      style={{ width: '80px', textAlign: 'center' }}
                      onClick={(e) => {
                        e.stopPropagation();

                        addOrEditClass('edit', record);
                      }}
                    >
                      {intl.formatMessage({ id: 'edit'})}
                    </div>
                  </Menu.Item>
                  {/* <Menu.Item key="stopUse">
                    <div style={{ width: '80px', textAlign: 'center' }}>
                      停用
                    </div>
                  </Menu.Item> */}
                </Menu>
              }
              placement="bottom"
            >
              <a>{intl.formatMessage({ id: 'more'})}</a>
            </Dropdown>
          );
        },
      },
    ],
  };

  // 关联计划弹窗
  const connectTrainProps = {
    type: options.classType,
    currentItem: currentRecord.current,
    closeModal: () => {
      setTrainModal(false);
    },
    reloadPage: getListData,
    openSelectChild: () => {
      setSelectChildModal(true);
    },
  };

  // 分配训练师弹窗props
  const assignModalProps = {
    title: intl.formatMessage({ id: 'assignCoach'}),
    visible: assignModal,
    content: modalContent.assignContent,
    loading: loading.assignModalLoading,
    cancelText: intl.formatMessage({ id: 'later'}),
    onSubmitText: intl.formatMessage({ id: 'sureConfirm'}),
    onCancel() {
      setAssignModal(false);
    },
    async onOk(value) {
      setLoading({
        ...loading,
        assignModalLoading: true,
      });
      const { id, groupTrainingPlans } = currentRecord.current;
      const res = await AssignTrainers({
        groupId: id,
        ...value,
      });
      if (res.response.ok) {
        const { classType } = options;
        if (classType === 'add') {
          // 已经有管理计划则跳过
          if (groupTrainingPlans.length > 0) {
            // 没有儿童信息则打开管理儿童弹窗
            const { groupLinkUsers = [] } = currentRecord.current;
            groupLinkUsers.length === 0 && setSelectChildModal(true);
          } else {
            connectedTrainPlan();
          }
        }
        getListData();
      } else {
        showErrorMsg(res, intl);
      }
      setLoading({
        ...loading,
        assignModalLoading: false,
      });
      setAssignModal(false);
    },
  };

  // 管理儿童弹窗props
  const manageProps = {
    visible: selectChildModal,
    currentRecord: currentRecord.current,
    institutionType,
    reloadPage: getListData,
    closeModal: () => {
      setSelectChildModal(false);
    },
  };

  // 添加修改班级弹窗props
  const addOrEditClassModalProps = {
    type: options.classType,
    visible: classModal,
    editData: currentRecord.current,
    closeModal: () => {
      setClassModal(false);
      // type 改为 add 保持弹窗打开连贯性
      setOptions({
        ...options,
        classType: 'add',
      });
    },
    reloadPage: getListData,
    assignCoach: assignCoachModal,
  };

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'classList'})}>
      <Card
        title={<b style={{ fontSize: '20px' }}>{intl.formatMessage({ id: 'classList'})}</b>}
        bordered={false}
        extra={
          !(isEnterpriseAgent || isEnterpriseAdmin) && <div className={styles.pageName}>
            <Button
              type="primary"
              className={styles.addButton}
              onClick={() => addOrEditClass('add')}
            >
              {intl.formatMessage({ id: 'addClass'})}
            </Button>
          </div>
        }
      >
        <div className={styles.classList}>
          <span>{intl.formatMessage({ id: 'keyword'})}&nbsp;</span>
          <Search
            placeholder={intl.formatMessage({ id: 'className'})}
            style={{ height: 32, width: 220 }}
            value={searchKey.Filter}
            onChange={(e) => {
              setPagination({
                current: 1,
                pageSize: 10,
                total: 0,
              });
              setSearchKey({
                ...searchKey,
                Filter: e.target.value,
              });
            }}
          />
          {/* <div className={styles.Type}>
            <span>班级类型 </span>
            <Select
              placeholder={'全部'}
              style={{ minWidth: 220 }}
              value={searchKey.Types}
              onChange={(value) => {
                setSearchKey({
                  ...searchKey,
                  Types: value,
                });
              }}
            >
              <Option value={''}>全部</Option>
              <Option value={'OneToOne'}>一对一</Option>
              <Option value={'OneToMany'}>一对多</Option>
            </Select>
          </div> */}
          {/* <div className={styles.AgeStage}>
            <span>{intl.formatMessage({ id: 'stage'})} </span>
            <Select
              placeholder={intl.formatMessage({ id: 'all'})}
              style={{ minWidth: 220 }}
              value={searchKey.AgeStage}
              onChange={(value) => {
                setSearchKey({
                  ...searchKey,
                  AgeStage: value,
                });
              }}
            >
              <Option value={''}>{intl.formatMessage({ id: 'all'})}</Option>
              <Option value={'PreSchool'}>{intl.formatMessage({ id: 'preschool'})}</Option>
              <Option value={'GradeOrTeenSchool'}>{intl.formatMessage({ id: 'grade_teenage'})}</Option>
              <Option value={'GoldenAge'}>{intl.formatMessage({ id: 'GoldenAge'})}</Option>
            </Select>
          </div> */}
          <div className={styles.topSearch}>
            <span className={styles.searchItemLabel}>{intl.formatMessage({ id: 'classCreateTime'})} </span>
            <RangePicker
              placeholder={[intl.formatMessage({ id: 'startTime'}), intl.formatMessage({ id: 'endTime'})]}
              value={[
                searchKey.beginTime
                  ? moment(searchKey.beginTime, 'YYYY-MM-DD')
                  : null,
                searchKey.endTime
                  ? moment(searchKey.endTime, 'YYYY-MM-DD')
                  : null,
              ]}
              onChange={(date) => {
                setSearchKey({
                  ...searchKey,
                  beginTime: date[0] ? getFormatDate(date[0], true) : null,
                  endTime: date[1] ? getFormatDate(date[1], false) : null,
                });
              }}
            />
          </div>
          <Space className={styles.space}>
            <Button
              onClick={() =>
                setSearchKey({
                  Filter: null,
                  Types: null,
                  AgeStage: null,
                  beginTime: null,
                  endTime: null,
                })
              }
            >
              {intl.formatMessage({ id: 'clear'})}
            </Button>
            {/* <div
              className={styles.extra}
              onClick={() => setOptions({ ...options, open: !options.open })}
            >
              {options.open ? '展开' : '收起'}
              {options.open ? <DownOutlined /> : <UpOutlined />}
            </div> */}
          </Space>
          {!(isEnterpriseAgent || isEnterpriseAdmin) && <Button
            type="primary"
            className={styles.addButton}
            onClick={() => addOrEditClass('add')}
          >
            {intl.formatMessage({ id: 'addClass'})}
          </Button>
          }
        </div>
        {/* {!options.open && (
          <div className={styles.searchTime}>
            <span className={styles.searchItemLabel}>创建时间 </span>
            <RangePicker
              placeholder={['开始日期', '结束日期']}
              value={[
                searchKey.beginTime
                  ? moment(searchKey.beginTime, 'YYYY-MM-DD')
                  : null,
                searchKey.endTime
                  ? moment(searchKey.endTime, 'YYYY-MM-DD')
                  : null,
              ]}
              onChange={(date) => {
                setSearchKey({
                  ...searchKey,
                  beginTime: date[0] ? getFormatDate(date[0], true) : null,
                  endTime: date[1] ? getFormatDate(date[1], false) : null,
                });
              }}
            />
          </div>
        )} */}
        <div className={styles.magicTable}>
          <MagicTable {...tableProps} />
        </div>
        {/* 添加编辑班级 Modal */}
        <AddOrEditClassModal {...addOrEditClassModalProps} />
        {/* 训练计划 Modal */}
        {trainModal && <ConnectTrainPlan {...connectTrainProps} />}
        {/* 分配训练师 Modal */}
        <MagicFormModal {...assignModalProps} />
        {/* 管理儿童 Modal */}
        {selectChildModal && <ManageChildModal {...manageProps} />}
      </Card>
    </DocumentTitle>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  ClassList,
);

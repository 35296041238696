import React, { useState } from 'react';
import { Button, Space, Tag } from 'antd';
import { useIntl } from 'umi';
import config from 'utils/config';
import {
  getToken,
  getTenantId,
  getUserTenantId,
} from 'cognitiveleap-core-us/utils/auth';
import PlannedProgram from 'components/PlannedProgram/PlannedProgram';

import { PostCustomPlan } from 'services/rocketService/Assessment';

import moment from 'moment';

import styles from '../detail.less';

const { mobileUrl } = config;

const StepThree = ({ reloadPage, activePlan, nextPage }) => {
  const intl = useIntl();

  const [open, setOpen] = useState(true);
  const [customBtnLoading, setCustomBtnLoading] = useState(false);

  const {
    customizedPlan,
    displayName,
    id: userOfflineTrainingPlanId,
  } = activePlan || {};

  const { recommendUserAssessment, id, hasNewer, creationTime } =
    customizedPlan || {};
  const {
    assessment,
    finishTime,
    id: userAssessmentId,
  } = recommendUserAssessment || {};
  const { title } = assessment || {};

  const plannedProgramProps = {
    open,
    activePlan,
    onClose: () => {
      setOpen(false);
    },
  };

  const clickCustomPlan = async () => {
    setCustomBtnLoading(true);
    const res = await PostCustomPlan({
      userOfflineTrainingPlanId,
    });

    if (res.response.ok) {
      reloadPage();
    }
    setCustomBtnLoading(false);
  };

  return (
    <div className={styles.tabContent}>
      <div className={styles.stepThree}>
        <div className={styles.stepThreeText}>
          {displayName} {intl.formatMessage({ id: 'Customizing plan' })}
        </div>
        <Space
          className={styles.stepThreeText}
          size={'large'}
          align="start"
          direction="vertical"
        >
          <div className={styles.stepThreeDes}>
            <a
              onClick={() => {
                setOpen(true);
              }}
            >
              {displayName} {intl.formatMessage({ id: 'Training Plan' })}
            </a>
            <div className={styles.time}>
              {intl.formatMessage({ id: 'Created Time' })}：
              {moment(creationTime).format('MM/DD/YYYY HH:mm')}
            </div>
          </div>
          <div className={styles.stepThreeDes}>
            <Space>
              <a
                onClick={() => {
                  const reportUrl = `${mobileUrl}/m/rocketReport?to=${userAssessmentId}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`;
                  window.open(reportUrl, '_blank');
                }}
              >
                {title}
              </a>
              {hasNewer && (
                <Tag color="default">
                  {intl.formatMessage({ id: 'New Update' })}
                </Tag>
              )}
            </Space>

            <div className={styles.time}>
              {intl.formatMessage({ id: 'CompletionTime' })}：
              {moment(finishTime).format('MM/DD/YYYY HH:mm')}
            </div>
          </div>
        </Space>
        <Space size={'large'}>
          <Button loading={customBtnLoading} onClick={clickCustomPlan}>
            {intl.formatMessage({ id: 'RegeneratePlan' })}
          </Button>
          <Button type="primary" onClick={nextPage}>
            {intl.formatMessage({ id: 'next' })}
          </Button>
        </Space>
      </div>
      {open && <PlannedProgram {...plannedProgramProps} />}
    </div>
  );
};

export default StepThree;

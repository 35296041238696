import {
  GetGetItemsGrouped,
  GetUserOfflinePlanForPunch,
  PostPunch,
  PutPunch,
  NotDoneItem,
  NextCourse,
  GoToNextCourse,
  PostNumPunch,
  PutNumPunch,
  GetUserOfflinePlanForNumPunch,
  GetLatelyCourseApi,
  PostCourseAdjustmentApi,
  onGuardianNotDoItem,
  PostMusicFeedback,
  GetCategoryPunch,
  PostCategoryPunch,
  PutCategoryPunch,
} from 'services/rocketService/UserOfflinePlan'
import { message } from 'antd'
import { getDvaApp } from 'umi'
import { CenterGetSubjectInfo } from 'services/userManager'
import { getNextKey, getDisabledValue, getHasDoneValue, getMusicHasDone, isParentalInterviewsNeed } from 'utils/utils'

export default {
  namespace: 'userOfflinePlanDetail',
  state: {
    planItems: [],
    pagination: {
      current: 1,
      pageSize: 9,
      total: 0,
    },
    UserOfflinePlanId: null,
    selectCourseInfo: null,
    drawerVisible: false,
    guardianDrawerVisible: false,
    selectGuardianCourseInfo: null,
    selectCategoryInfo: [],
    classAdjustmentModalVisible: false,
    addClassType: 'addcclass', // 调整课程的类型
    choicedCClass: [], // 穿梭框右边的课程，也是需要提交到后端的C课程
    latelyCourse: null,   // 获取最近上的课程和C课程
    musicPlayerDrawerVisible: false,
    currentMusicPlanInfo: null,
    subjectInfo: null,
    activeKey: [],
    isCurrentNum: false
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *pageLoading({ payload }, { call, put, select }) {
      yield put({
        type: 'updateState', payload: { UserOfflinePlanId: payload.id }
      })
      yield put({ type: 'loadList' })

    },

    *loadList(_, { call, put, select }) {
      const { pagination, UserOfflinePlanId } = yield select(state => state.userOfflinePlanDetail)
      const { isOpen } = yield select(state => state.musicplayer)
      let subjectInfo = null
      let query = {
        skipCount: 0,
        maxResultCount: 10,
        UserOfflinePlanId,
        currentOnly: false,
      }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }
      const res = yield call(GetGetItemsGrouped, query)
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }

        const { userOfflineTrainingPlan } = items[0]
        const useInfoRes = yield call(CenterGetSubjectInfo, userOfflineTrainingPlan.userId)
        if (useInfoRes) {
          subjectInfo = useInfoRes.data
        }

        yield put({ type: 'updateState', payload: { pagination: newPagination, planItems: items, subjectInfo } })

        if (isOpen) {
          yield put({ type: 'updateCurrentlist' })
        }
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'loadList' })
    },

    *onFeedback({ payload: data }, { call, put, select }) {
      const { pagination: { total } } = yield select(state => state.userOfflinePlanDetail)
      const { userPlanId, num, id } = data
      const res = yield call(GetUserOfflinePlanForPunch, {
        UserOfflinePlanId: userPlanId,
        num,
      })
      if (res.response.ok) {
        const { planItems = [], nonePlaybook = [] } = res.data || {}

        const categoryDisabled = planItems.some(item => item.hasDone == null) || nonePlaybook.some(item => item.hasDone == null)

        if (!categoryDisabled) {
          yield put({
            type: 'onCategoryFeedback',
            payload: { userPlanId, num }
          })
        }

        yield put({
          type: 'updateState',
          payload: { drawerVisible: true, selectCourseInfo: res.data, isCurrentNum: num === total },
        })
        yield put({
          type: 'findActiveKeys',
          payload: { type: 'training', id }
        })
      }
    },

    *onGuardianFeedback({ payload: data }, { call, put, select }) {
      const { userPlanId, num } = data;
      const res = yield call(GetUserOfflinePlanForNumPunch, {
        UserOfflinePlanId: userPlanId,
        Num: num,
        ForGuardian: true
      })
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { guardianDrawerVisible: true, selectGuardianCourseInfo: res.data }
        })
        yield put({
          type: 'findActiveKeys',
          payload: { type: 'guardian', }
        })
      }
    },

    *onCategoryFeedback({ payload: data }, { call, put, select }) {
      const { userPlanId, num } = data;
      const res = yield call(GetCategoryPunch, {
        UserOfflinePlanId: userPlanId,
        Num: num,
        ForGuardian: false
      })
      if (res.response.ok) {
        const { feedbacks } = res.data || {};

        const _selectCategoryInfo = {
          id: 'category',
          hasDone: Object.keys(feedbacks || {}).length > 0,
          ...res.data,
          feedbacks: [
            feedbacks
          ]
        }

        yield put({
          type: 'updateState',
          payload: { selectCategoryInfo: _selectCategoryInfo }
        })
      }
    },

    *findActiveKeys({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo, selectCourseInfo, subjectInfo } = yield select(state => state.userOfflinePlanDetail)
      const { currentUser } = yield select(state => state.user)
      const { audioRecordPunch, hasDone: guardianHasDone, userOfflineTrainingPlan } = selectGuardianCourseInfo || {}
      const { planItems, nonePlaybook } = selectCourseInfo || {}
      const { isBind, isLogout } = subjectInfo || {}
      const { auth, changedTenant } = currentUser || {}
      const { grantedPolicies } = auth || {}

      const rocketProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.Rocket')['status'] : true;
      const hasOfflinePlanWritePermission = grantedPolicies['RocketSystem.UserOfflinePlan.Write'] === true

      const musicHasDone = getMusicHasDone({ audioRecordPunch })

      const guardianId = getDisabledValue({ hasDone: guardianHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) === false
        && getHasDoneValue({ hasDone: guardianHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })
        ? userOfflineTrainingPlan.id : null
      const musicId = audioRecordPunch !== null ?
        (getDisabledValue({ hasDone: musicHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) === false
          && getHasDoneValue({ hasDone: musicHasDone, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) ? 'music' : null) : null

      const { type, id } = payload
      let activeKey

      const nextKey = getNextKey({ planitems: planItems, nonePlayBooks: nonePlaybook, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission })

      switch (type) {
        case 'guardian':
          activeKey =  musicId || nextKey || 'categoryFeedback'
          break
        case 'music':
          activeKey = nextKey || guardianId
          break
        default:
          if (id) {
            activeKey = getNextKey({ id, planitems: planItems, nonePlayBooks: nonePlaybook, rocketProjectStatus, isBind, isLogout, hasOfflinePlanWritePermission }) || nextKey || guardianId || musicId
          }
          else {
            activeKey = nextKey || guardianId || musicId
          }
          break
      }

      yield put({
        type: 'updateState',
        payload: {
          activeKey: activeKey !== null ? [activeKey] : []
        },
      })

    },

    *onChangeCollapse({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo, selectCourseInfo } = JSON.parse(JSON.stringify(yield select(state => state.userOfflinePlanDetail)))
      const { activeKey, type, hasDone } = payload || {}
      const { audioRecordPunch } = selectGuardianCourseInfo || {}
      let { planItems, nonePlaybook } = selectCourseInfo || {}

      yield put({
        type: 'updateState',
        payload: {
          activeKey
        }
      })

      if (type === 'guardian') {
        // 代表已做
        if (hasDone == true) {
          selectGuardianCourseInfo.isEdit = false
        } else {
          selectGuardianCourseInfo.isEdit = true
        }
        yield put({
          type: 'updateState',
          payload: {
            selectGuardianCourseInfo
          }
        })
      }


      if (type === "activity") {
        let current = null
        planItems = planItems.map(item => {
          if (item.id === (activeKey && activeKey.length && activeKey[activeKey.length - 1])) {
            const { hasDone } = item || {}
            current = item
            if (hasDone == true) {
              item.isEdit = false
            } else {
              item.isEdit = true
            }
          }
          return item
        })
        if (!current) {
          nonePlaybook = nonePlaybook.map(item => {
            if (item.id === (activeKey && activeKey.length && activeKey[activeKey.length - 1])) {
              const { hasDone } = item || {}
              current = item
              if (hasDone == true) {
                item.isEdit = false
              } else {
                item.isEdit = true
              }
            }
            return item
          })
        }
        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo: {
              ...selectCourseInfo,
              planItems,
              nonePlaybook
            }
          }
        })
      }

      if (type === 'music') {
        const { punched, listenedList } = audioRecordPunch || {}
        let musicHasDone = null
        if (punched === false) {
          musicHasDone = null
        }
        if (punched && listenedList.length === 0) {
          musicHasDone = false
        }
        if (punched && listenedList.length > 0) {
          musicHasDone = true
        }
        if (musicHasDone == true) {
          selectCourseInfo.musicIsEdit = false
        } else {
          selectCourseInfo.musicIsEdit = true
        }
        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo
          }
        })
      }
    },

    *submitGuardianFeedback({ payload }, { call, put, select }) {
      const { submitData, guardianHasDone } = payload
      const res = yield call(guardianHasDone == null ? PostNumPunch : PutNumPunch, submitData);
      if (res.response.ok) {
        const { userOfflineTrainingPlan, num } = res.data
        yield put({
          type: 'onGuardianFeedback',
          payload: { userPlanId: userOfflineTrainingPlan.id, num },
        })
      } else {
        const { error } = res.data || {};
        message.error(error.message);
      }
    },

    *closeDrawer(_, { call, put, select }) {
      yield put({
        type: 'updateState',
        payload: { drawerVisible: false, guardianDrawerVisible: false, selectCategoryInfo: [] },
      })
      yield put({
        type: 'loadList',
      })
    },

    *submitFeedback({ payload }, { call, put, select }) {
      const { planItemId, hasDone } = payload
      const res = yield call(hasDone == null ? PostPunch : PutPunch, payload)
      if (res.response.ok) {
        const { userOfflineTrainingPlanId, num } = res.data
        yield put({
          type: 'onFeedback',
          payload: { userPlanId: userOfflineTrainingPlanId, num, id: planItemId },
        })
      } else {
        const { error } = res.data || {}
        message.error(error.message)
      }
    },

    *submitCategoryFeedback({ payload }, { call, put, select }) {
      const { hasDone, num, userOfflineTrainingPlanId } = payload
      // 如果hasDone==null代表的是从未提交过，使用PostPunch，如果是修改就使用PutPunch
      const res = yield call(hasDone == null ? PostCategoryPunch : PutCategoryPunch, payload)
      if (res.response.ok) {
        yield put({
          type: 'onCategoryFeedback',
          payload: { userPlanId: userOfflineTrainingPlanId, num },
        })
      } else {
        const { error } = res.data || {}
        message.error(error.message)
      }
    },

    *onNotDoItem({ payload: id }, { call, put, select }) {
      const res = yield call(NotDoneItem, id)
      if (res.response.ok) {
        const { userOfflineTrainingPlanId, num } = res.data
        yield put({
          type: 'onFeedback',
          payload: { userPlanId: userOfflineTrainingPlanId, num, id },
        })
      } else {
        const { error } = res.data || {}
        message.error(error.message)
      }
    },

    *onGuardianNotDoItem({ payload: { offlinePlanId, num, hasDone, notFinishAudios } }, { call, put, select }) {
      const res = yield call(onGuardianNotDoItem, {
        offlinePlanId,
        num,
        hasDone,
        notFinishAudios
      })
      if (res.response.ok) {
        yield put.resolve({
          type: 'onFeedback',
          payload: {
            userPlanId: offlinePlanId,
            num
          }
        })
        yield put.resolve({
          type: 'onGuardianFeedback',
          payload: {
            userPlanId: offlinePlanId,
            num
          },
        })
      }
    },

    *finishCourse({ payload }, { call, put, select }) {
      const { userPlanId, num } = payload
      const res = yield call(NextCourse, { offlinePlanId: userPlanId, num })
      if (res.response.ok) {
        yield put({
          type: 'loadList',
        })
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },

    *eidtItem({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo, selectCourseInfo, selectCategoryInfo } = JSON.parse(JSON.stringify(yield select(state => state.userOfflinePlanDetail)))
      const { userOfflineTrainingPlan, audioRecordPunch } = selectGuardianCourseInfo || {}
      const { data, type } = payload || {}
      let { planItems, nonePlaybook } = selectCourseInfo || {}
      if (type === "guardian") {
        const { id } = userOfflineTrainingPlan || {}
        selectGuardianCourseInfo.isEdit = true
        yield put({
          type: 'updateState',
          payload: {
            activeKey: id
          }
        })
        yield put({
          type: 'updateState',
          payload: {
            selectGuardianCourseInfo
          }
        })
      }

      if (type === "activity") {
        const { hasDone, id } = data || {}
        yield put({
          type: 'updateState',
          payload: {
            activeKey: id
          }
        })
        if (hasDone == true) {
          planItems = planItems.map(item => {
            if (item.id === id) {
              item.isEdit = true
            }
            return item
          })
          nonePlaybook = nonePlaybook.map(item => {
            if (item.id === id) {
              item.isEdit = true
            }
            return item
          })
        } else {
          planItems = planItems.map(item => {
            if (item.id === id) {
              item.isEdit = false
            }
            return item
          })
          nonePlaybook = nonePlaybook.map(item => {
            if (item.id === id) {
              item.isEdit = false
            }
            return item
          })
        }
        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo: {
              ...selectCourseInfo,
              planItems,
              nonePlaybook
            }
          }
        })
      }
      if (type === 'category') {
        selectCategoryInfo.isEdit = true

        yield put({
          type: 'updateState',
          payload: {
            selectCategoryInfo,
            activeKey: 'categoryFeedback'
          }
        })
      }
      if (type === "music") {
        const { punched, listenedList } = audioRecordPunch || {}
        let musicHasDone = null
        if (punched === false) {
          musicHasDone = null
        }
        if (punched && listenedList.length === 0) {
          musicHasDone = false
        }
        if (punched && listenedList.length > 0) {
          musicHasDone = true
        }

        if (musicHasDone == true) {
          selectCourseInfo.musicIsEdit = true
        } else {
          selectCourseInfo.musicIsEdit = false
        }

        yield put({
          type: 'updateState',
          payload: {
            activeKey: 'music'
          }
        })

        yield put({
          type: 'updateState',
          payload: {
            selectCourseInfo
          }
        })
      }
    },

    *startNextCourseImplement({ payload }, { call, put, select }) {
      const { userPlanId, num, intl } = payload;
      const res = yield call(GoToNextCourse, { offlinePlanId: userPlanId, num })
      if (res.response.ok) {
        message.success(intl.formatMessage({ id: 'success' }))
        yield put({
          type: 'loadList',
        })
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },

    *startNextCourse({ payload }, { call, put, select }) {
      const { userPlanId, num, intl } = payload
      const { subjectInfo: { id, name, stage } } = yield select(state => state.userOfflinePlanDetail)

      if (isParentalInterviewsNeed(num, 'next') && stage !== '黄金时代') {

        const res = yield call(GetParentalInterviewsNeed, {
          UserOfflinePlanId: userPlanId,
          Num: num,
          SubjectId: id
        })

        if (res.response.ok) {
          if (res.data.need) {
            Modal.warning({
              title: intl.formatMessage({ id: 'shouleParentInterview' }, { name }),
              content: null,
              onOk: () => {
                getDvaApp()._store.dispatch({ 
                  type: 'userOfflinePlanDetail/startNextCourseImplement',
                  payload
                })
              }
            })
          } else {
            yield put({
              type: 'startNextCourseImplement',
              payload
            })
          }
        }
      } else {
        yield put({
          type: 'startNextCourseImplement',
          payload
        })
      }
    },

    *getLatelyCourse({ payload: _ }, { call, put, select }) {
      const { UserOfflinePlanId } = yield select(state => state.userOfflinePlanDetail);
      const res = yield call(GetLatelyCourseApi, {
        planId: UserOfflinePlanId
      });
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: { latelyCourse: res.data },
        })
      }
    },

    *courseAdjusment({ payload: { courses = [], cCourse = [] } }, { call, put, select }) {
      const { UserOfflinePlanId } = yield select(state => state.userOfflinePlanDetail);
      const { planInfo } = yield select(state => state.musicplayer)
      const musicPlayerId = planInfo && planInfo.userOfflineTrainingPlan && planInfo.userOfflineTrainingPlan.id
      const res = yield call(PostCourseAdjustmentApi, {
        courses,
        cCourse,
        planId: UserOfflinePlanId
      });
      if (UserOfflinePlanId == musicPlayerId) {
        yield put({ type: 'musicplayer/closeGlobalMusicPlayer' })
      }
      if (res.response.ok) {
        yield put({
          type: 'loadList',
        })
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    },
    *updateCurrentlist({ payload: _ }, { call, put, select }) {
      const { planItems } = yield select(state => state.userOfflinePlanDetail);
      const { isOpen: isOpening, planInfo, currentIndex } = yield select(state => state.musicplayer)
      const id = planInfo.userOfflineTrainingPlan && planInfo.userOfflineTrainingPlan.id

      planItems && planItems.length > 0 && planItems.forEach(item => {
        item.isOpening = false;
      })
      const hasPlanInfo = planItems && planItems.length > 0 && planItems.find(item => {
        return item.userOfflineTrainingPlan.id == id
      })
      if (hasPlanInfo) {
        hasPlanInfo.isOpening = isOpening;
        hasPlanInfo.iLs.tracks.forEach(item => {
          item.isOpening = false;
        });
        hasPlanInfo.iLs.tracks[currentIndex].isOpening = isOpening
        yield put({
          type: 'updateState',
          payload: {
            planItems
          }
        })
      }
    },

    *musicFeedback({ payload }, { call, put, select }) {
      const { selectGuardianCourseInfo } = yield select(state => state.userOfflinePlanDetail)
      const res = yield call(PostMusicFeedback, payload.data)
      if (res.response.ok) {
        yield put({
          type: 'updateState',
          payload: {
            selectGuardianCourseInfo: Object.assign({}, selectGuardianCourseInfo, { audioRecordPunch: res.data })
          }
        })
        yield put({
          type: 'findActiveKeys',
          payload: { type: 'music', }
        })
      } else {
        const { data: { error } } = res;
        message.error(error.message);
      }
    }
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/center/detail/userOfflinePlanDetail') {
    //       dispatch({ type: 'pageLoading', payload: location.query })
    //     }
    //   })
    // },
  },
}

import { IntlManager } from 'utils/helper'

export const tableHeadTitle = () => {
    const formatMessage = IntlManager.intl.formatMessage
    return [
        { name: `${formatMessage({ id: 'studentName' })}: `, style: { width: '11%' } },
        { name: `${formatMessage({ id: 'session' })}: `, style: { width: '7%' } },
        { name: `${formatMessage({ id: 'playingDurationMins' })}: `, style: { width: '13%' } },
        { name: `${formatMessage({ id: 'trainingDate' })}: `, style: { width: '13%' } },
        { name: `${formatMessage({ id: 'trainer' })}: `, style: { width: '12%' } }
    ]
}

export const tableContentHeadTitle = () => {
    const formatMessage = IntlManager.intl.formatMessage

    return [
        { name: formatMessage({ id: 'numberOrderOther' }), style: { width: '35px', maxWidth: '35px' }, propertyName: 'index' },
        { name: formatMessage({ id: 'trainingActivities' }), style: { width: '174px', maxWidth: '174px' }, propertyName: 'planname' },
        { name: formatMessage({ id: 'trainType' }), style: { width: '130px', maxWidth: '130px' }, propertyName: 'categoriesType' },
        { name: formatMessage({ id: 'groupOne' }), style: { width: '65px', maxWidth: '65px', 'word-wrap': 'break-word', 'word-break': 'keep-all' }, propertyName: 'firstRound' },
        { name: formatMessage({ id: 'groupTwo' }), style: { width: '65px', maxWidth: '65px', 'word-wrap': 'break-word', 'word-break': 'keep-all' }, propertyName: 'secondRound' },
        { name: formatMessage({ id: 'groupThere' }), style: { width: '65px', maxWidth: '65px', 'word-wrap': 'break-word', 'word-break': 'keep-all' }, propertyName: 'thirdRound' },
        { name: formatMessage({ id: 'activityCompletion' }),style: { width: '65px', maxWidth: '65px', 'word-wrap': 'break-word', 'word-break': 'keep-all'  }, propertyName: 'activityCompletion' },
        { name: formatMessage({ id: 'standardity' }),style: { width: '65px', maxWidth: '65px', 'word-wrap': 'break-word', 'word-break': 'keep-all'  }, propertyName: 'adherenceToStandards' },
        { name: formatMessage({ id: 'cofidence' }),style: { width: '65px', maxWidth: '65px', 'word-wrap': 'break-word', 'word-break': 'keep-all'  }, propertyName: 'confidenceLevel' },
        { name: formatMessage({ id: 'levelOfLikeability' }),style: { width: '75px', maxWidth: '70px', 'word-wrap': 'break-word', 'word-break': 'keep-all'  }, propertyName: 'enjoymentLevel' },
    ]
}

export const tablePsychologyHeadTitle = () => {
    return [
        {
            key: 'emotionalState',
            valueText: '',
            twoKey: 'behavioralActivity',
            twoValueText: '',
            haveData: false,
        },
        {
            key: 'focusAttention',
            valueText: '',
            twoKey: 'socialInteraction',
            twoValueText: '',
            haveData: false,
        },
        {
            key: 'selfRegulation',
            valueText: '',
            twoKey: 'communicationStyle',
            twoValueText: '',
            haveData: false,
        },
        {
            key: 'motivationLevel',
            valueText: '',
            haveData: false,
        },
    ]
}
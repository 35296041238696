import React, { useEffect, useState, useRef } from 'react';
import { connect, useIntl } from 'umi';
import { Spin, Button, message, Space, Popover } from 'antd';
import moment from 'moment';
import { RedoOutlined } from '@ant-design/icons';

import { GetTrialPlan, GetBaseLineSurvey } from 'services/trialPlan';
import { GetAssessmentDetail } from 'services/rocketService/Assessment';

import StartTrialModal from './StartTrialModal';
import TrialSessionContent from './TrialSessionContent';
import AssessmentQrCode from 'components/AssessmentQrCode';

import styles from '../detail.less';

const TrialSession = ({
  subjectAssessment,
  currentUser,
  subjectDetail,
  dispatch,
  loading: modelLoading,
}) => {
  const intl = useIntl();

  const childRef = useRef(null);

  const isSurveyLoading =
    modelLoading.effects['subjectAssessment/getAssessmentQrCode'];

  const { assessmentQrData, assessmentQrUrlModalVisible, assessmentQrUrl } =
    subjectAssessment;

  const { subjectInfo } = subjectDetail || {};
  const { id: userId } = subjectInfo || {};

  const [trialData, setTrialData] = useState({});
  const [surveyData, setSurveyData] = useState({});
  const [assessmentData, setAssessmentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const { assessment, userAssessment } = surveyData || {};
  const { title, id: assessmentId } = assessment || {};
  const {
    id: userAssessmentId,
    creationTime,
    finishTime,
  } = userAssessment || {};

  const hasCreatedSurvey = Object.keys(userAssessment || {}).length > 0;
  const hasFinishedSurvey = finishTime != null || finishTime != undefined;

  const initData = async (callback) => {
    setLoading(true);

    const res = await GetTrialPlan({ userId });

    if (res.response.ok) {
      setTrialData(res.data || {});

      if (Object.keys(assessmentData || {}).length == 0) {
        const surveyRes = await GetBaseLineSurvey({ userId });

        if (surveyRes.response.ok) {
          setSurveyData(surveyRes.data || {});

          const { userAssessment } = surveyRes.data || {};

          if (Object.keys(userAssessment || {}).length > 0) {
            const { id } = userAssessment || {};
            const assessmentRes = await GetAssessmentDetail(id);

            if (assessmentRes.response.ok) {
              setAssessmentData(assessmentRes.data || {});
            } else {
              const {
                data: { error },
              } = assessmentRes;
              message.error(error.message);
            }
          }
        } else {
          const {
            data: { error },
          } = surveyRes;
          message.error(error.message);
        }
      }

      callback && callback(res.data);
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (userId) initData();
  }, [userId]);

  const onStartTrial = () => {
    setVisible(true);
  };

  const editAssessment = (type) => {
    let tempRecord = {
      userId,
      assessmentId,
      id: userAssessmentId,
    };
    if (type == 'again') {
      delete tempRecord.id;
    }
    dispatch({
      type: 'subjectAssessment/getAssessmentQrCode',
      payload: {
        record: tempRecord,
        intl,
        from: 'Retest',
      },
    });
  };

  const qrCodeProps = {
    url: assessmentQrUrl,
    currentUser,
    visible: assessmentQrUrlModalVisible,
    assessmentInfo: assessmentQrData,
    onCancel: () => {
      initData();
      dispatch({
        type: 'subjectAssessment/updateState',
        payload: {
          assessmentQrUrlModalVisible: false,
        },
      });
    },
    downloadAssessmentQR: () => {
      dispatch({
        type: 'subjectAssessment/downloadAssessmentQRPhoto',
        payload: { intl },
      });
    },
  };

  const showOptions = () => {
    if (!hasCreatedSurvey) {
      return (
        <Button
          type="link"
          loading={isSurveyLoading}
          onClick={() => editAssessment('again')}
        >
          {intl.formatMessage({ id: 'Start filling in' })}
        </Button>
      );
    }
    if (hasFinishedSurvey) {
      return (
        <a
          onClick={() => {
            const url = '/questionnaireView?id=' + userAssessmentId;
            window.open(url, '_blank');
          }}
        >
          {intl.formatMessage({ id: 'assessmentDetails' })}
        </a>
      );
    }
    return (
      <Space size={1} align="start">
        <Button type="link" loading={isSurveyLoading} onClick={editAssessment}>
          {intl.formatMessage({ id: 'Continue Filling' })}
        </Button>
        <RedoOutlined onClick={() => initData()} />
      </Space>
    );
  };

  const EmptyCom = (
    <div className={styles.trialPlan}>
      <div className={styles.trialPlanText}>
        {intl.formatMessage({ id: 'Child has not yet started' })}
      </div>
      {Object.keys(surveyData).length > 0 && (
        <Space className={styles.stepTwoText} size={2} align="start">
          <div className={styles.stepTwoDes}>
            {title}
            {hasCreatedSurvey && (
              <div className={styles.time}>
                {intl.formatMessage({
                  id: hasFinishedSurvey ? 'Complete Time' : 'classCreateTime',
                })}
                :{' '}
                {moment(hasFinishedSurvey ? finishTime : creationTime).format(
                  'MM/DD/YYYY HH:mm',
                )}
              </div>
            )}
          </div>
          {showOptions()}
        </Space>
      )}

      <Popover
        content={
          !hasFinishedSurvey ? (
            <div>{intl.formatMessage({ id: 'StartQuestionOpenBaseline' })}</div>
          ) : null
        }
      >
        <Button
          type="primary"
          onClick={onStartTrial}
          disabled={!hasFinishedSurvey}
        >
          {intl.formatMessage({ id: 'trialStart' })}
        </Button>
      </Popover>
    </div>
  );

  const modalProps = {
    userId,
    visible,
    onReload: () => {
      initData((data) => childRef.current.addProgramsModal(data));
    },
    onCloseModal: () => setVisible(false),
  };

  const contentProps = {
    userId,
    trialData,
    assessmentData,
    subjectInfo,
    onReload: initData,
  };

  if (!Object.keys(trialData).length && loading) {
    return (
      <div
        style={{
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.tabContent}>
      {Object.keys(trialData || {}).length > 0 ? (
        <TrialSessionContent {...contentProps} onRef={childRef} />
      ) : (
        EmptyCom
      )}

      {visible && <StartTrialModal {...modalProps} />}
      <AssessmentQrCode {...qrCodeProps} />
    </div>
  );
};

export default connect(
  ({ loading, subjectAssessment, user, subjectDetail }) => ({
    loading,
    subjectAssessment,
    subjectDetail,
    currentUser: user.currentUser,
  }),
)(TrialSession);

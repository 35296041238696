import React, { useState, useEffect } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Spin, Row, Col, Tabs, Button, Space, Modal, message } from 'antd';
import { useIntl, connect } from 'umi';
import CardHead from 'components/CardHead';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import AddContractModal from 'components/AddContractModal';
import PaymentModal from 'components/AddContractModal/PaymentModal';

import { showAttributeName } from 'utils/feedback';

import {
  GetUserContractDetail,
  GetReasonQuestion,
  PostStopUserContract,
  PostReEnableUserContract,
} from 'services/userContract';

import styles from './index.less';

import moment from 'moment';

const { confirm } = Modal;

const listSupport = (intl) => {
  return {
    creationTime: {
      showText: intl.formatMessage({ id: 'Latest Submission' }),
      showType: 'Time',
    },
    payDate: {
      showText: intl.formatMessage({ id: 'Payment Date' }),
      showType: 'Time',
    },
    payType: {
      showText: intl.formatMessage({ id: 'Types' }),
      showType: 'Text',
      render: (_, record) => {
        const { amount } = record || {};

        return intl.formatMessage({ id: amount >= 0 ? 'Payment' : 'Refund' });
      },
    },
    amount: {
      showText: intl.formatMessage({ id: 'amount' }),
      showType: 'Text',
    },
    remark: {
      showText: intl.formatMessage({ id: 'Comments' }),
      showType: 'Text',
    },
    creator: {
      showText: intl.formatMessage({ id: 'Operator' }),
      showType: 'Text',
    },
  };
};

const contractInfoKeyIndicators = (contractDetail, reasonQuestion, intl) => {
  const {
    amount,
    contractType,
    creator,
    discount,
    discountReason,
    files,
    lastModificationTime,
    paidAmount,
    paidClass,
    price,
    reason,
    signTime,
    stopTime,
    totalClass,
    records = [],
  } = contractDetail || {};

  const {
    signReason,
    renewalReason,
    discountReason: discountReason2,
  } = reasonQuestion || {};

  return [
    {
      title: intl.formatMessage({ id: 'Signing Date' }),
      data: moment(signTime).format('MM/DD/YYYY'),
    },
    {
      title: intl.formatMessage({ id: 'Contract Status' }),
      data: intl.formatMessage({
        id: stopTime == null ? 'ContractActive' : 'ContractStop',
      }),
    },
    {
      title: intl.formatMessage({ id: 'Paid Amount' }),
      data: paidAmount,
    },
    {
      title: intl.formatMessage({ id: 'Latest Submission' }),
      data: lastModificationTime
        ? moment(lastModificationTime).format('MM/DD/YYYY HH:mm')
        : '-',
    },
    {
      title: intl.formatMessage({ id: 'Submitter' }),
      data: creator,
    },
    {
      title: intl.formatMessage({ id: 'Contract Amount' }),
      data: amount,
    },
    {
      title: intl.formatMessage({ id: 'Paid Sessions' }),
      data: paidClass,
    },
    {
      title: intl.formatMessage({ id: 'Payment Times' }),
      data: records.length,
    },
    {
      title: intl.formatMessage({ id: 'Contract Type' }),
      data: intl.formatMessage({
        id: contractType === 'First' ? 'Initial' : 'Renewal',
      }),
    },
    {
      title: intl.formatMessage({ id: 'Unit Session Fee' }),
      data: price.toFixed(2),
    },
    {
      title: intl.formatMessage({ id: 'Amount Due' }),
      data: stopTime == null ? (amount - paidAmount).toFixed(1) : 0,
    },
    {
      title: intl.formatMessage({ id: 'Discount Applied' }),
      data: intl.formatMessage({ id: discount ? 'yes' : 'no' }),
    },
    {
      title: intl.formatMessage({
        id: contractType === 'First' ? 'Signing Reason' : 'Renewal Reason',
      }),
      data:
        '\n' +
        showAttributeName(
          'Checkbox',
          reason,
          {
            programFeedbackProperty:
              contractType === 'First' ? signReason : renewalReason,
          },
          '\n',
        ),
    },
    {
      title: intl.formatMessage({ id: 'PrePaid Sessions' }),
      data: totalClass,
    },
    {
      title: intl.formatMessage({ id: 'Contract Attachment' }),
      colSpan: 12,
      data:
        files.length > 0 ? (
          <Space direction='vertical' size={1}>
            {files.map((item) => {
              const { fileName, url } = item || {};
              return (
                <a href={url} target="_blank" key={url}>
                  {fileName}
                </a>
              );
            })}
          </Space>
        ) : (
          '-'
        ),
    },
    {
      title: intl.formatMessage({ id: 'Discount Reason' }),
      data: discountReason
        ? '\n' +
          showAttributeName(
            'Checkbox',
            discountReason,
            {
              programFeedbackProperty: discountReason2,
            },
            '\n',
          )
        : '',
      isShow: discount,
    },
  ].filter((item) => item.isShow !== false);
};

const ContractInfo = ({
  currentUser,
  keyIndicatorsData,
  userData,
  reloadPage,
  isAdmin,
}) => {
  const intl = useIntl();

  const { auth } = currentUser;
  const { grantedPolicies } = auth || {};
  const haveCreateContractPermission =
    grantedPolicies['RocketSystem.UserContract.Create'];

  const { contracts = [] } = keyIndicatorsData || {};

  const [type, setType] = useState('add');
  const [activeKey, setActiveKey] = useState('1');
  const [contractDetail, setContractDetail] = useState({});
  const [userContractId, setUserContractId] = useState('');
  const [currentRecordItem, setCurrentRecordItem] = useState({});
  const [visible, setVisible] = useState({
    payModalVisible: false,
    contractModalVisible: false,
  });
  const [reasonQuestion, setReasonQuestion] = useState({});

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
  });

  const { records = [], stopTime } = contractDetail || {};

  const getContractDetail = async (Id) => {
    setActiveKey(Id);
    setUserContractId(Id);
    setLoading({
      ...loading,
      tableLoading: true,
    });

    const res = await GetUserContractDetail({
      Id,
    });

    if (res.response.ok) {
      const { records = [] } = res.data || {};
      setContractDetail(res.data);
      setPagination({
        ...pagination,
        total: records.length,
      });
    }

    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  const initData = async () => {
    const res = await GetReasonQuestion();

    if (res.response.ok) {
      setReasonQuestion(res.data);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    getContractDetail(contracts[0]?.id);
  }, [contracts.length]);

  const tableProps = {
    listData: records || [],
    listSupport: listSupport(intl),
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions:
      isAdmin || stopTime !== null || !haveCreateContractPermission
        ? []
        : [
            {
              showText: intl.formatMessage({ id: 'edit' }),
              itemRender(record) {
                return (
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentRecordItem(record);
                      setVisible({
                        ...visible,
                        payModalVisible: true,
                      });
                    }}
                  >
                    {intl.formatMessage({ id: 'edit' })}
                  </a>
                );
              },
            },
          ],
  };

  const showContent = () => {
    return (
      <Spin spinning={loading.tableLoading}>
        <div style={{ margin: '10px 20px 20px 20px', whiteSpace: 'pre-line' }}>
          {Object.keys(contractDetail).length > 0 &&
            Object.keys(reasonQuestion).length > 0 && (
              <Row gutter={[20, 20]}>
                {contractInfoKeyIndicators(
                  contractDetail,
                  reasonQuestion,
                  intl,
                ).map((item) => {
                  const { title, data, colSpan = 6 } = item || {};

                  return (
                    <Col key={title} span={colSpan} xs={12} sm={8} lg={6}>
                      <div>
                        {title}: {data}
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
        </div>

        <div>
          <CardHead
            type="v2"
            title={intl.formatMessage({ id: 'Payment Record' })}
            extra={
              !isAdmin &&
              stopTime == null &&
              haveCreateContractPermission && (
                <Button
                  type="primary"
                  onClick={() => {
                    setCurrentRecordItem({});
                    setVisible({ ...visible, payModalVisible: true });
                  }}
                >
                  {intl.formatMessage({ id: 'PaymentButton' })}
                </Button>
              )
            }
          />
          <MagicTable {...tableProps} />
        </div>
      </Spin>
    );
  };

  const paymentModalProps = {
    userContractId,
    visible: visible.payModalVisible,
    currentRecordItem,
    closeModal: () => {
      setVisible({
        ...visible,
        payModalVisible: false,
      });
    },
    onReloadPage: () => {
      reloadPage(() => getContractDetail(userContractId));
    },
  };

  const addContractModalProps = {
    userData,
    contractDetail:
      type === 'add'
        ? {}
        : {
            ...contractDetail,
            reason2: contractDetail.reason,
          },
    visible: visible.contractModalVisible,
    type,
    closeModal: () => {
      setVisible({
        ...visible,
        contractModalVisible: false,
      });
    },
    reloadPage: () => {
      if (type === 'add') {
        reloadPage((data) => getContractDetail(data[0]?.id));
      } else {
        reloadPage(() => getContractDetail(userContractId));
      }
    },
  };

  const operations = (
    <Space>
      <Button
        onClick={async () => {
          if (stopTime) {
            const res = await PostReEnableUserContract({ id: userContractId });

            if (res.response.ok) {
              message.success(
                intl.formatMessage({ id: 'Contract Reactivated' }),
              );
              getContractDetail(userContractId);
            }
          } else {
            confirm({
              title: intl.formatMessage({ id: 'Stop Contract' }),
              icon: <ExclamationCircleOutlined />,
              content: intl.formatMessage({ id: 'Stop Contract Des' }),
              okText: intl.formatMessage({ id: 'ContractStop' }),
              onOk: async () => {
                const res = await PostStopUserContract({ id: userContractId });

                if (res.response.ok) {
                  getContractDetail(userContractId);
                }
              },
            });
          }
        }}
      >
        {intl.formatMessage({
          id: stopTime ? 'Activate Contract' : 'Stop Contract',
        })}
      </Button>
      {!stopTime && (
        <Button
          onClick={() => {
            setType('edit');
            setVisible({
              ...visible,
              contractModalVisible: true,
            });
          }}
        >
          {intl.formatMessage({ id: 'Edit Contract' })}
        </Button>
      )}
    </Space>
  );

  return (
    <div className={styles.ContractInfo}>
      <CardHead
        title={intl.formatMessage({ id: 'Contract Details' })}
        type="v2"
        extra={
          !isAdmin &&
          haveCreateContractPermission && (
            <Button
              type="primary"
              onClick={() => {
                setType('add');
                setVisible({ ...visible, contractModalVisible: true });
              }}
            >
              {intl.formatMessage({ id: 'Add Contract' })}
            </Button>
          )
        }
      />
      <Tabs
        activeKey={activeKey}
        onChange={(value) => {
          getContractDetail(value);
        }}
        tabBarExtraContent={
          !isAdmin && haveCreateContractPermission && operations
        }
        items={contracts.map((item, index) => {
          const { id, code } = item;
          return {
            label: `${intl.formatMessage({ id: 'ContractTabTitle' })}-${code}`,
            key: id,
            children: showContent(),
          };
        })}
      />
      <PaymentModal {...paymentModalProps} />
      <AddContractModal {...addContractModalProps} />
    </div>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  ContractInfo,
);

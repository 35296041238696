import React, { useEffect, useState } from 'react';
import {
  Input,
  Select,
  DatePicker,
  Button,
  Space,
  Popover,
  Card,
} from 'antd';
import { connect, useIntl } from 'umi';
import DocumentTitle from 'react-document-title';
import {
  cleanObject,
  showErrorMsg,
  eventDebounce,
} from 'cognitiveleap-core-us/utils/utils';
import { getFormatDate, getGroupTag } from 'utils/utils';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import ExpandedTable from './components/ExpandedTable';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect';
import moment from 'moment';

import { GetGroupList } from 'services/group';

import styles from './index.less';

const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ClassList = ({ currentUser }) => {
  const intl = useIntl()

  const listSupport = {
    name: {
      showText: '班级名称',
      showType: 'Text',
    },
    type: {
      showText: '班级类型',
      showType: 'Text',
      filter: {
        hasFilter: true,
        filterOptions: [
          { text: '一对一', value: 'OneToOne' },
          { text: '一对二', value: 'OneToTwo' },
          { text: '一对三', value: 'OneToThree' },
          { text: '一对四', value: 'OneToFour' },
          { text: '一对五', value: 'OneToFive' },
          { text: '一对六', value: 'OneToSix' },
          { text: '一对多', value: 'OneToMany' },
        ],
      },
      render: (text, _) => {
        return getGroupTag(text, intl)
      },
    },
    groupLinkUsers: {
      showText: '儿童人数',
      showType: 'Text',
      render: (text, _) => {
        if (text.length === 0) {
          return 0;
        }
        return text.length;
      },
    },
    capacity: {
      showText: '最多人数',
      showType: 'Text',
      render: (text, record) => {
        if (record.type === 'OneToOne') {
          return 1;
        }
        return text;
      },
    },
    // ageStage: {
    //   showText: '阶段',
    //   showType: 'Text',
    //   render: (text, _) => {
    //     if (text === 'GradeOrTeenSchool') {
    //       return '学龄/青少年';
    //     } else if (text === 'PreSchool') {
    //       return '学前';
    //     }
    //     return '黄金时代';
    //   },
    // },
    groupTrainingPlans: {
      showText: '当前训练计划',
      showType: 'Text',
      render: (text, _) => {
        return text
          .filter((item) => item.status === 'Started')
          .map((item) => item.trainingPlanV2.displayName);
      },
    },
    groupLinkTrainers: {
      showText: '训练师',
      showType: 'Text',
      render: (text, record) => {
        if (text.length === 0) {
          return '';
        } else if (text.length === 1) {
          return text[0].name;
        } else {
          return (
            <Popover
              title="全部训练师"
              key={record.id}
              content={text.map((item) => {
                return item ? (
                  <div key={item.id} className={styles.popover}>
                    {item.name}
                  </div>
                ) : (
                  <div className={styles.popover}>-</div>
                );
              })}
            >
              <span style={{ cursor: 'pointer' }}>
                {text[0].name + ' - (' + text.length + ')'}
              </span>
            </Popover>
          );
        }
      },
    },
    creationTime: {
      showText: '班级创建时间',
      showType: 'Time',
    },
  };

  const { canChangeList } = currentUser;

  const [currentTenant, setCurrentTenant] = useState({
    id: '',
    displayName: '全部',
  });

  const [listData, setListData] = useState([]); // 班级列表数据

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  // 搜索数据
  const [searchKey, setSearchKey] = useState({
    Filter: null,
    Types: null,
    AgeStage: null,
    beginTime: null,
    endTime: null,
  });

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [loading, setLoading] = useState({
    tableLoading: false
  });

  const getListData = async () => {
    setLoading({ ...loading, tableLoading: true });

    // 搜索不跳过数据
    // const noSkip = searchKey.Filter || searchKey.Types || searchKey.AgeStage;

    const res = await GetGroupList(
      cleanObject({
        ...searchKey,
        SkipCount: (pagination.current - 1) * pagination.pageSize,
        //SkipCount: noSkip === null ? (pagination.current - 1) * pagination.pageSize : 0,
        MaxResultCount: pagination.pageSize,
        TenantId: currentTenant.id,
      }),
    );
    if (res.response.ok) {
      setListData(res.data.items);
      setPagination({
        ...pagination,
        total: res.data.totalCount || 0,
      });
    } else {
      showErrorMsg(res, intl);
    }
    setLoading({ ...loading, tableLoading: false });
  };

  useEffect(() => {
    eventDebounce(() => getListData(), 350);
  }, [searchKey, pagination.current, currentTenant]);

  // 班级列表
  const tableProps = {
    listData,
    listSupport: listSupport,
    filters: { type: searchKey.Types },
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: (record) => <ExpandedTable allData={record} getListData={getListData} />,
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      };
    },
    loading: loading.tableLoading,
    pagination,
    onTableChange(page, filt) {
      if (filt !== undefined) {
        const { type } = filt;
        setSearchKey({
          ...searchKey,
          Types: type,
        });
      }

      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }

      expandedRowKeys.length > 0 && setExpandedRowKeys([]);
    }
  };

  const changedTenantList = (id) => {
    if (id) {
      const newCurrentTenant = canChangeList.find((item) => item.id == id);
      setCurrentTenant({ ...newCurrentTenant });
    } else {
      setCurrentTenant({ id: '', displayName: '全部' });
    }
  };

  return (
    <DocumentTitle title={'班级列表'}>
      <Card
        title={<b style={{ fontSize: '20px' }}>班级列表</b>}
        bordered={false}
        extra={
          <AnalysisTenantSelect
            showAll={true}
            currentTenant={currentTenant}
            onChange={changedTenantList}
          />
        }
      >
        <div className={styles.classList}>
          <span>关键字&nbsp;</span>
          <Search
            placeholder="班级名称"
            style={{ height: 32, width: 220 }}
            value={searchKey.Filter}
            onChange={(e) => {
              setSearchKey({
                ...searchKey,
                Filter: e.target.value,
              });
            }}
          />
          {/* <div className={styles.AgeStage}>
            <span>阶段 </span>
            <Select
              placeholder={'全部'}
              style={{ minWidth: 220 }}
              value={searchKey.AgeStage}
              onChange={(value) => {
                setSearchKey({
                  ...searchKey,
                  AgeStage: value,
                });
              }}
            >
              <Option value={''}>全部</Option>
              <Option value={'PreSchool'}>学前</Option>
              <Option value={'GradeOrTeenSchool'}>学龄/青少年</Option>
              <Option value={'GoldenAge'}>黄金时代</Option>
            </Select>
          </div> */}
          <div className={styles.topSearch}>
            <span className={styles.searchItemLabel}>创建时间 </span>
            <RangePicker
              placeholder={['开始日期', '结束日期']}
              value={[
                searchKey.beginTime
                  ? moment(searchKey.beginTime, 'YYYY-MM-DD')
                  : null,
                searchKey.endTime
                  ? moment(searchKey.endTime, 'YYYY-MM-DD')
                  : null,
              ]}
              onChange={(date) => {
                setSearchKey({
                  ...searchKey,
                  beginTime: date[0] ? getFormatDate(date[0], true) : null,
                  endTime: date[1] ? getFormatDate(date[1], false) : null,
                });
              }}
            />
          </div>
          <Space className={styles.space}>
            <Button
              onClick={() =>
                setSearchKey({
                  Filter: null,
                  Types: null,
                  AgeStage: null,
                  beginTime: null,
                  endTime: null,
                })
              }
            >
              清空
            </Button>
          </Space>
        </div>
        <div className={styles.magicTable}>
          <MagicTable {...tableProps} />
        </div>
      </Card>
    </DocumentTitle>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  ClassList,
);

import React from 'react'
import { Drawer, Spin, message } from 'antd'
import { LeftOutlined, ClockCircleFilled, AlignLeftOutlined } from '@ant-design/icons';
import { connect } from 'umi'
import MusicPlayer from '../MusicPlayer'
import styles from './index.less';
import { convertDuration } from 'utils/utils'
import { IntlManager } from 'utils/helper'
import { convertTimeToSecond } from 'cognitiveleap-core-us/utils/utils'

const intl = IntlManager.MyIntl()

const MusicPlayerDrawer = ({ musicplayer, visible, onClose, dispatch, loading }) => {
  const { currentIndex, from, planInfo, userCourseNum, isOpen, cacheLoading, statusLoading } = musicplayer

  const isLoading = cacheLoading || statusLoading

  const tracks = planInfo && planInfo.iLs && planInfo.iLs.tracks
  const track = tracks && tracks[currentIndex]

  const drawerHeader = <div className={styles.drawerHeader}>
    <div
      className={styles.drawerHeaderIcon}
      onClick={() => { onClose() }}
    >
      <LeftOutlined />
    </div>
    <span>
      {from === 'trialSessionPlan' ? intl('TrialSession' ) : from === 'groupClassDetail' ? track && track.courseCode.split(' ')[0] + ' ' + userCourseNum : intl('whichMusic', { num: planInfo && planInfo.num || 1 })}
    </span>
  </div>


  return (
    <Drawer
      title={drawerHeader}
      placement='right'
      closable={false}
      onClose={onClose}
      open={visible}
      width={540}
      className={styles.musicPlayerDrawer}
    >
      <Spin spinning={isLoading} tip={cacheLoading && intl('resourceBuffering')}>
        <MusicPlayer />
        {
          tracks && tracks.length > 0 &&
          <div className={styles.category}>
            <div className={styles.categoryTitle}>{intl('tableOfContent')}</div>
            {
              tracks.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={styles.categoryItem}
                    onClick={() => {
                      if (/*item.track && item.track.url && index != currentIndex*/ false) {
                        dispatch({ type: 'musicplayer/changeMusicByIndex', payload: { index } })
                      }
                    }}
                  >
                    <div className={currentIndex == index ? styles.categoryItemTitleOpening : styles.categoryItemTitle}>
                      {
                        from === 'trialSessionPlan' ? (
                          <span>
                              {index + 1} {item.code}
                          </span>
                        ) : (
                          <span>
                              {index + 1} {from !== 'groupClassDetail' ? item.courseCode + '-' + item.code : item.courseCode.split(' ')[1] + '-' + item.code}
                          </span>
                        )
                      }
                    </div>
                    <div className={styles.categoryItemContent}>
                      <div className={styles.categoryItemContentLeft}>
                        <ClockCircleFilled className={currentIndex == index ? styles.categoryItemContentIconOpening : styles.categoryItemContentIcon} />
                        <span>{convertDuration(convertTimeToSecond(item.duration))}</span>
                      </div>
                      {
                        currentIndex == index && isOpen &&
                        <div className={styles.categoryIsOpening}>
                          <AlignLeftOutlined
                            style={{
                              transform: "rotate(-90deg)",
                              color: "#24A9DC",
                              fontSize: "19px",
                              marginRight: "8px",
                            }}
                          />
                        </div>
                      }
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
      </Spin>
    </Drawer>
  )
}

export default connect(({
  musicplayer,
  loading
}) => ({
  musicplayer,
  loading
}))(MusicPlayerDrawer)

import style from './index.less';
import { history, useIntl, connect } from 'umi';
import { UserOutlined } from '@ant-design/icons';
import { Fragment, useEffect } from 'react';
import { Button, Avatar, Divider, Card, Spin, Tabs, Popconfirm, Modal, message, Collapse, Drawer } from 'antd';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { showGender, getCourseStatus, showCourseValidity } from 'utils/utils';
import subject_default from 'assets/subject_default.png'
import monent from 'moment';
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import { getCorrentRateAndResult } from 'cognitiveleap-core-us/utils/utils'

const { Panel } = Collapse;

const handOption = {
    "Right": "右利手",
    "LeftHand": "左利手",
    "Unknown": "未知"
}

const addWayOption = {
    "Tenant": "中心添加",
    "User": "扫码授权"
}

const { TabPane } = Tabs;

const UserDetail = ({ adminUserDetail, loading, dispatch, location, currentUser }) => {
    const { adminRoles: [{name} = {}] = [] } = currentUser || {}
    const intl = useIntl()
    useEffect(() => {
        dispatch({ type: 'adminUserDetail/initPage', payload: location.query })
    }, [])

    const {
        userInfo,
        tabActiveKey,
        selectModalVisible,
        userClassTablePagination,
        userGrantCourse,
        userCourseList,
        content,
        drawerVisible,
        selectCourse,
        collapseActiveKey
    } = adminUserDetail;
    const { courses } = userGrantCourse || {}
    const { email, phone, picture, nickName, gender, subjects, userAddWay } = userInfo || {};
    const isLoading = loading.models.adminUserDetail;
    const postCourseLoading = loading.effects['adminUserDetail/postCourseToUser']

    const { title, course } = selectCourse || {}
    const { contents } = course || {}

    let tenantList = []
    tenantList = subjects && Array.isArray(subjects) && subjects.length > 0 ?
        Array.from(new Set(subjects.map(item => item.userAddWay && item.userAddWay.addTenant && item.userAddWay.addTenant.displayName))).filter(item => item !== null)
        :
        []

    const userClassTableListSupport = {
        grantTime: {
            showText: '开通时间',
            showType: 'Time',
            addtional: {
                format: 'YYYY/MM/DD HH:mm',
            },
        },
        title: {
            showText: '课程名称',
            showType: 'Text',
        },
        length: {
            showText: '课时数',
            showType: 'Text',
        },
        grantTenant: {
            showText: '本中心开通',
            showType: '开通中心'
        },
        validity: {
            showText: '有效期',
            showType: 'Text',
        },
        showStatus: {
            showText: '状态',
            showType: 'Text',
        },
        deletionTime: {
            showText: '停止授权时间',
            showType: 'Time',
            addtional: {
                format: 'YYYY/MM/DD HH:mm',
            },
        },
    }

    const userClassTableProps = {
        listSupport: userClassTableListSupport,
        pagination: userClassTablePagination,
        listData: userCourseList.map((item, index) => {
            return {
                ...item,
                validity: showCourseValidity(item, intl),
                showStatus: getCourseStatus(item, intl),
                title: item.course.title,
                length: item.course.length,
                id: item.course.id,
                key: index
            }
        }),
        onTableChange(pagi, filt = {}, sorter = {}) {
            let data = { userClassTablePagination }
            if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi }
            if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
            dispatch({ type: 'adminUserDetail/changeUserClassTable', payload: data })
        },
        Actions: [
            {
                showText: '停止授权',
                itemRender(record) {
                    const { isDeleted, status } = record
                    return (
                        !isDeleted && status !== 'Expired' ?
                        <Popconfirm
                            placement="topLeft"
                            title={'确定要停止授权吗？'}
                            onConfirm={() => dispatch({ type: 'adminUserDetail/cancelCourseToUser', payload: record })}
                            okText="确定"
                            cancelText="取消"
                        >
                            <a>停止授权</a>
                        </Popconfirm>
                        :
                        null
                    )
                }
            },
            {
                showText: '测试结果',
                itemRender(record) {
                    const { course } = record
                    const { contents } = course
                    let questionnaire = contents.map(item => item.questionnaires)
                    questionnaire = questionnaire.flat(Infinity)
                    return (questionnaire && questionnaire.length > 0 ? <a onClick={() => dispatch({ type: 'adminUserDetail/showTestResult', payload: record })}>测试结果</a> : null)
                },
            }
        ]
    }

    const selectCourseModalProps = {
        title: '开通课程',
        visible: selectModalVisible,
        loading: postCourseLoading,
        content,
        onCancel: () => {
            dispatch({ type: "adminUserDetail/updateState", payload: { selectModalVisible: false } })
        },
        onOk: (data) => {
            const { courseIds, startDate, endDate } = data
            const selectItem = courses.find(item => item.id === courseIds)
            const selectItemTitle = selectItem ? selectItem.title : ''
            if (monent(startDate).valueOf() > monent(endDate).valueOf()) {
                message.error('结束日期应大于开始日期');
                return
            }
            const title = `确认为${nickName ? nickName : '未知'}开通${selectItemTitle}有效期：${showCourseValidity({ startTime: startDate, endTime: endDate }, intl)}吗？提交后不可修改`
            Modal.confirm({
                title: <div style={{ color: 'red' }}>{title}</div>,
                okText: '确定',
                cancelText: '取消',
                onOk() {
                    if (!postCourseLoading) {
                        dispatch({ type: "adminUserDetail/postCourseToUser", payload: data })
                    }
                }
            });
        }
    }

    const goToSubjectDetail = (id) => {
        history.push({
            pathname: '/system/manage/subjectDetail',
            query: {
                id
            }
        })
    }

    const showUserSubject = (subjects = []) => {
        return (
            subjects && subjects.length && subjects.length > 0 ?
                subjects.map((item, index) => {
                    return (
                        <div
                            className={style.userSubjectItem}
                            key={index}
                            onClick={() => { goToSubjectDetail(item.id) }}
                        >
                            <div className={style.userSubjectItemLeft}>
                                <Avatar
                                    size={48}
                                    src={item.picture || subject_default}
                                    shape='square'
                                    icon={<UserOutlined />}
                                />
                            </div>
                            <div className={style.userSubjectItemRight}>
                                <div className={style.userSubjectItemRightTop}>
                                    <div className={style.userSubjectItemRightTopName}>{item.name}</div>
                                    {/*<span className={style.userSubjectItemRightTopDescriptionOn}>已解绑</span>*/}
                                </div>
                                <div className={style.userSubjectItemRightBottom}>
                                    <div className={style.userSubjectItemRightBottomItem}>
                                        <div className={style.userSubjectItemRightBottomSex}>{showGender(item.gender, intl)}</div>
                                        <Divider type="vertical" className={style.userSubjectItemRightBottomItemDivider} />
                                        <div>{item.birthDay && monent(item.birthDay).format('YYYY/MM/DD')}</div>
                                        {
                                            item.userAddWay && item.userAddWay.addWay &&
                                            <Fragment>
                                                <Divider type="vertical" className={style.userSubjectItemRightBottomItemDivider} />
                                                <div>{addWayOption[item.userAddWay.addWay]}</div>
                                            </Fragment>
                                        }
                                    </div>
                                    <div className={style.userSubjectItemRightBottomItem}>{item.guardianRoleName}</div>
                                    {
                                        item.guardians && item.guardians.length > 0 && item.guardians[0].roleDefinition && item.guardians[0].roleDefinition.displayName &&
                                        <div className={style.userSubjectItemRightBottomItem}>{item.guardians[0].roleDefinition.displayName} {nickName}</div>
                                    }
                                    {
                                        item.dominantHand &&
                                        <div className={style.userSubjectItemRightBottomItem}>{handOption[item.dominantHand]}</div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })
                :
                <div></div>
        )
    }

    const onChangeCollapse = async (value) => {
        // 查找当前打开的小节，如果没有myquestionnaire属性就去获取API
        if (collapseActiveKey.length < value.length) {
            let openId = ''
            value.forEach(item => {
                if (!collapseActiveKey.includes(item)) {
                    openId = item
                }
            })
            const content = contents.find(item => item.id === openId)
            if (content && !content.myquestionnaire) {
                await dispatch({ type: 'adminUserDetail/getAppUserQuestionnarie', payload: content })
            }
        }
        dispatch({ type: 'adminUserDetail/updateState', payload: { collapseActiveKey: value } })
    }

    return (
        <Spin spinning={isLoading}>
            <div className={style.userDetail}>
                <div className={style.userDetailTop}>
                    <Button onClick={() => history.goBack()} className={style.topButton}>{intl.formatMessage({ id: 'Return' })}</Button>
                    <p className={style.detailDescript}>{intl.formatMessage({ id: 'guardianDetails' })}</p>
                </div>
                <div className={style.userContent}>
                    <div className={style.userDetailInfo}>
                        <div className={style.userDetailInfoLeft}>
                            <Avatar
                                size={72}
                                src={picture || subject_default}
                                shape='square'
                                icon={<UserOutlined />}
                            />
                        </div>
                        <div className={style.userDetailInfoRight}>
                            <div className={style.userDetailInfoRightTop}>
                                <div className={style.userDetailInfoRightTopName}>{nickName}</div>
                                <div className={nickName ? style.userDetailInfoRightSex : style.userDetailInfoRightSexEmpty}>{showGender(gender, intl)}</div>
                                {
                                    userAddWay && userAddWay.addWay &&
                                    <Fragment>
                                        <Divider type="vertical" className={style.userDetailInfoRightDivider} />
                                        <div className={style.userDetailInfoRightAddStyle}>{addWayOption[userAddWay.addWay]}</div>
                                    </Fragment>
                                }
                            </div>
                            <div className={style.userDetailInfoRightBottom}>
                                <div>{phone}</div>
                                {
                                    email && (
                                        <Fragment>
                                            <Divider type="vertical" className={style.userDetailInfoRightBottomDivider} />
                                            <div>{email}</div>
                                        </Fragment>
                                    )
                                }
                            </div>
                            <div className={style.userDetailInfoRightCenterName}>
                                {
                                    tenantList.length > 0 && tenantList.map((tenantListItem, tenantListIndex) => {
                                        if (tenantList.length - 1 === tenantListIndex) {
                                            return <span key={tenantListIndex}>{tenantListItem}</span>
                                        } else {
                                            return <span key={tenantListIndex}>{tenantListItem}，</span>
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <Card title={<strong>{intl.formatMessage({ id: 'childrenList' })}</strong>} bordered={false}>
                        <div className={style.userSubject}>
                            {showUserSubject(subjects)}
                        </div>
                    </Card> 
                    {
                        name === 'agent' ? null :
                            <Tabs activeKey={tabActiveKey} >
                                <TabPane tab={intl.formatMessage({ id: 'course' })} key="class">
                                    <div>
                                        <Button onClick={() => dispatch({ type: 'adminUserDetail/openCourses' })}>{intl.formatMessage({ id: 'openCourses' })}</Button>
                                        <MagicTable {...userClassTableProps} />
                                        <MagicFormModal {...selectCourseModalProps} />
                                    </div>
                                </TabPane>
                            </Tabs>
                    }
                </div>
            </div>
            <Drawer
                open={drawerVisible}
                title={<b style={{ fontSize: '18px' }}>{title}</b>}
                width={720}
                onClose={() => dispatch({ type: 'adminUserDetail/updateState', payload: { drawerVisible: false } })}
            >
                <Collapse
                    onChange={onChangeCollapse}
                    activeKey={collapseActiveKey}
                >
                    {
                        contents && contents.map(item => {
                            const { name, myquestionnaire, id } = item || {}
                            const { questionnaireAndAnswer } = myquestionnaire || {}
                            const { answer, questionnaire } = questionnaireAndAnswer || {}
                            const { answerContent, creationTime } = answer || {}
                            const { jsonString, correctRate } = questionnaire || {}
                            const correntRateAndResult = getCorrentRateAndResult(jsonString, answerContent, correctRate)
                            const { correntCount, questionArr, correntRate } = correntRateAndResult || {}
                            const showSurveyProps = {
                                contents: questionArr,
                                choice: answerContent ? JSON.parse(JSON.stringify(answerContent)) : {}
                            }
                            return (
                                <Panel
                                    header={name}
                                    key={id}
                                    extra={
                                        <div>
                                            {
                                                myquestionnaire &&
                                                <div>
                                                    <div style={{ fontWeight: '600' }}>{intl.formatMessage({ id: 'Answer results' },{correntCount, correntRate})}</div>
                                                    <div>{monent(creationTime).format('YYYY/MM/DD HH:mm')}</div>
                                                </div>
                                            }
                                        </div>
                                    }
                                >
                                    {
                                        myquestionnaire ?
                                            <div style={{ padding: '30px 0 0 30px' }}><ShowSurvey {...showSurveyProps} /></div>
                                            :
                                            <div style={{ height: '100px', lineHeight: '100px', textAlign: 'center' }}>
                                                <span style={{ fontWeight: 600 }}>{intl.formatMessage({ id: 'emptyData' })}</span>
                                            </div>
                                    }

                                </Panel>
                            )
                        })
                    }
                </Collapse>
            </Drawer>
        </Spin>
    )
}

export default connect(({ adminUserDetail, loading, user }) => ({ adminUserDetail, loading, currentUser: user.currentUser}))(UserDetail);
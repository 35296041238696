import React, { useState, useEffect, useRef } from 'react';
import { connect, history, useIntl, getLocale } from 'umi';
import {
  Row,
  Col,
  Button,
  Modal,
  List,
  Card,
  Spin,
  message,
  Divider,
  Dropdown,
  Menu,
  Space
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  getDurationTime,
  isParentalInterviewsNeed,
  showRemainingTimesMsg,
  showUserContractMsg,
  showCoachTimesMsg,
  isActiveCredentiales,
  getVersionReportUrl,
} from 'utils/utils';
import CourseCard from 'components/CourseCard';
import FeedbackDrawer from 'components/FeedbackDrawer';
import SelectPlanTypeModal from './SelectPlanTypeModal';
import ArrangeAssessmentModal from './ArrangeAssessmentModal';
import EndPlanModal from 'components/EndPlanModal';
import CheckEndPlanModal from 'components/CheckEndPlanModal';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import AdjustLevelModal from 'components/AdjustLevelModal';
import moment from 'moment';
import RocketProjectTip from 'components/RocketProjectTip';
import AddTrainModal from 'components/UserConfigurationModal/AddTrainModal';
import ShowItem from 'components/ShowVideoItem';
import ClassAdjustmentModal from 'components/ClassAdjustmentModal';
import DownLoadModal from 'components/DownLoadModal';
import BatchDownloadModal from 'components/BatchDownloadModal';
import AssessmentQrCode from 'components/AssessmentQrCode';
import RecordsTabs from 'components/RecordsTabs';
import styles from '../detail.less';
import ExtendClassModal from './ExtendClassModal';
import { eventDebounce, deepCopy } from 'cognitiveleap-core-us/utils/utils';
import { isEqualPlan } from '../../../../models/music';
import ArrangeCourse from './ArrangeCourse';
import StartPlanModal from './StartPlanModal';
import constant from './constant';
import { GetDownloadPdf } from 'services/puppeteer';

const getRemainingTimesStyle = (times) => {
  return parseInt(times) <= 5 ? { color: 'red' } : {};
};

const StepFive = ({
  activePlan,
  reloadPage,
  subjectAssessment,
  subjectOfflinePlan,
  currentUser,
  subjectDetail,
  loading,
  dispatch,
  musicPlanInfo,
  musicIsOpen,
}) => {
  const { status: currentPlanStatus, id: planId } = activePlan || {};

  const {
    currentlist,
    drawerVisible,
    selectCourseInfo,
    planTypeModalVisible,
    ilsActiveKey,
    recordPagination,
    ilsparentRecord,
    ilstrainerRecord,
    ilsappRecord,
    shouldGetData,
    classAdjustmentModalVisible,
    addClassType,
    choicedCClass,
    latelyCourse,
    currentAdjustmentCourseInfo,
    selectGuardianCourseInfo,
    selectCategoryInfo,
    arrangeAssessmentModalVisible,
    endPlanModalVisible,
    currentEndPlan,
    arrangeAssessmentData,
    currentTablePlan,
    activeKey,
    arrangeCourseDrawerVisible,
    currentArrangeCoursePlanInfo,
    arrangeCourseModalVisible,
    arrangeCourseContent,
    adjustLevelModalVisible,
    selectAddjustLevelUserOfflinePlan,
    arrangeCourseList,
    isCurrentNum,
    videoVisible,
    currentProgramId,
    endCourseLoading,
    offlinePlanNum,
    extendModalVisible,
    currentClassItem,
    trainModalVisible,
    independentCoachConfig,
    trainerCredentialses,
    submitAdjustLevelLoading,
    allowReloadTab, // 是否允许刷新tab
    checkEndPlanVisible,
  } = subjectOfflinePlan;

  const {
    assessmentQrData,
    assessmentQrUrlModalVisible,
    assessmentQrUrl,
  } = subjectAssessment;

  const intl = useIntl();
  const durationRef = useRef();

  const { auth, changedTenant, staff, current } = currentUser;
  const { grantedPolicies } = auth;
  const { institutionType, timesSetting } = changedTenant || {};
  const roles = currentUser.current ? currentUser.current.roles : [];
  const hasOfflinePlanWritePermission =
    grantedPolicies['RocketSystem.UserOfflinePlan.Write'] === true;
  const hasOfflinePlanReadPermission =
    grantedPolicies['RocketSystem.UserOfflinePlan'];
  const hasCalendarPermission = grantedPolicies['Business.Calendar'];
  const rocketProjectStatus =
    changedTenant && changedTenant.projectStatus
      ? changedTenant.projectStatus.find(
          (item) => item.name == 'Project.Rocket',
        )['status']
      : true;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [duration, setDuration] = useState(0);
  const [percent, setPercent] = useState(0);
  const [loadPageVisible, setLoadPageVisible] = useState(false);
  const [downCurrentNum, setDownCurrentNum] = useState(0);

  const { subjectInfo, classAndMusicInfo } = subjectDetail;
  const { userContract } = classAndMusicInfo || {}

  const { totalTimes, remainingTimes } = offlinePlanNum;

  if (subjectInfo === null) return <Spin />;
  const { isBind, isLogout, name, stage } = subjectInfo || {};
  const { userOfflineTrainingPlan } = currentArrangeCoursePlanInfo || {};
  const { displayName, currentNum } = userOfflineTrainingPlan || {};
  const latelyCourseIsLoading =
    loading.effects['subjectOfflinePlan/getLatelyCourse'];
  const musicPlayerId =
    musicPlanInfo &&
    musicPlanInfo.userOfflineTrainingPlan &&
    musicPlanInfo.userOfflineTrainingPlan.id;
  const drawerProps = {
    isBind,
    isLogout,
    rocketProjectStatus,
    hasOfflinePlanWritePermission,
    hasOfflinePlanReadPermission,
    categoryInfo: selectCategoryInfo,
    guardianCourseInfo: selectGuardianCourseInfo,
    allData: selectCourseInfo,
    visible: drawerVisible,
    isCurrentNum,
    activeKey,
    onClose: async () => {
      // Modal.confirm({
      //   title: '确定关闭吗',
      //   content: '未提交的内容将无法保存',
      //   okText: '确认',
      //   cancelText: '取消',
      //   onOk() {

      //   },
      //   onCancel() {},
      // })
      await dispatch({
        type: 'subjectOfflinePlan/closeDrawer',
        payload: { intl },
      });
    },
    onSubmit(feedbacks, trainingProgramId, planItemId, hasDone) {
      dispatch({
        type: 'subjectOfflinePlan/submitFeedback',
        payload: { feedbacks, trainingProgramId, planItemId, hasDone },
      });
    },
    onCategorySubmit(data) {
      dispatch({
        type: 'subjectOfflinePlan/submitCategoryFeedback',
        payload: data,
      });
    },
    onChangeCollapse(data, type, hasDone) {
      dispatch({
        type: 'subjectOfflinePlan/onChangeCollapse',
        payload: { activeKey: data, type, hasDone },
      });
    },
    onGuardianFeedback: (submitData, guardianHasDone) => {
      dispatch({
        type: 'subjectOfflinePlan/submitGuardianFeedback',
        payload: { submitData, guardianHasDone },
      });
    },
    onNotDo(id) {
      dispatch({
        type: 'subjectOfflinePlan/onNotDoItem',
        payload: id,
      });
    },
    onGuardianNotDo({ id, guardianNum, notFinishAudios }) {
      dispatch({
        type: 'subjectOfflinePlan/onGuardianNotDoItem',
        payload: {
          offlinePlanId: id,
          num: guardianNum,
          notFinishAudios,
          hasDone: false,
        },
      });
    },
    onMusicFeedBack: (data) => {
      dispatch({
        type: 'subjectOfflinePlan/musicFeedback',
        payload: {
          data,
        },
      });
    },
    eidtItem(data, type) {
      dispatch({
        type: 'subjectOfflinePlan/eidtItem',
        payload: {
          data,
          type,
        },
      });
    },
    loading: loading.models.subjectOfflinePlan,
  };

  const getDefaultType = (birthDay) => {
    //大于6岁 返回
    return moment(birthDay).add(6, 'years').isBefore(moment())
      ? 'GradeSchool'
      : 'PreSchool';
  };

  const planTypeSelectModalProps = {
    loading: loading.models.subjectOfflinePlan,
    value: getDefaultType(subjectInfo.birthDay),
    visible: planTypeModalVisible,
    onCancel() {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: { planTypeModalVisible: false },
      });
    },
    onOk(data) {
      const postData = {
        ...data,
        subjectId: subjectInfo.id,
      };
      createOfflinePlan(postData);
    },
  };

  const adjustLevelModalProps = {
    visible: adjustLevelModalVisible,
    currentItem: selectAddjustLevelUserOfflinePlan,
    submitAdjustLevelLoading,
    onOk: (data) => {
      dispatch({ type: 'subjectOfflinePlan/submitAdjustLevel', payload: data });
    },
    onCancel: () => {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: { adjustLevelModalVisible: false },
      });
    },
  };

  const downLoadProps = {
    visible: loadPageVisible,
    percent,
    duration,
  };

  const onStartPlan = (values) => {
    const { qualificationMedals = [] } = activePlan;

    const { current } = currentUser || {};
    const { roles = [] } = current || {};
    const { usedTimes = 0, totalTimes = 0 } = independentCoachConfig || {};
    const isIndependentCoach = roles.some(
      (role) => role.name === 'IndependentCoach',
    );
    const cardStatus = isActiveCredentiales(
      qualificationMedals,
      trainerCredentialses,
    );
    const coachShowToast =
      cardStatus === 'Active' ||
      cardStatus === 'NotActive' ||
      cardStatus === 'Expired';

    if (coachShowToast && isIndependentCoach) {
      if (cardStatus === 'Expired') {
        message.warning('您的证书已过期，请联系您的培训负责人');
      } else {
        const freeTimes = totalTimes - usedTimes;
        if (showCoachTimesMsg(intl, freeTimes, 'click')) return;
      }
    }

    if (
      showRemainingTimesMsg(
        intl,
        remainingTimes,
        currentUser,
        'offlinePlan',
        'click',
        () => dispatch({ type: 'subjectOfflinePlan/goSettings' }),
      )
    )
      return;

    if (
      showUserContractMsg(
        intl,
        userContract,
        currentUser,
        () => dispatch({ type: 'subjectDetail/updateState', payload: { addContractModalVisible: true } }),
      )
    )
      return;

    dispatch({
      type: 'subjectOfflinePlan/startOfflinePlan',
      payload: {
        reloadPage,
        values: {
          ...values,
          planId,
        },
      },
    });
  };

  const onFinishCourse = (userPlan, num) => {
    const { id: userPlanId, totalNum } = userPlan;

    // 最后一节课关闭训练计划使用
    if (num === totalNum) {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: {
          currentEndPlan: userPlan,
        },
      });
    }
    dispatch({
      type: 'subjectOfflinePlan/checkTryCourse',
      payload: { userPlanId, num, totalNum, intl },
    });
  };

  const isEnterAdmin = () => {
    return JSON.stringify(roles) !== '[]'
      ? roles.some((r) => r.name === 'EnterpriseAdmin')
      : false;
  };

  const rolesName = roles.length > 0 && roles[0].name;

  const showOptionRoles =
    rolesName === 'CenterAdmin' ||
    rolesName === 'SeniorCoach' ||
    rolesName === 'Senior Coach';

  const onStartNextCourse = (userPlanId, num) => {
    if (isParentalInterviewsNeed(num, 'next') && stage !== '黄金时代') {
      dispatch({
        type: 'subjectOfflinePlan/nextTipParentInterview',
        payload: { userPlanId, num, intl },
      });
    } else {
      dispatch({
        type: 'subjectOfflinePlan/startNextCourse',
        payload: { userPlanId, num, intl },
      });
    }
  };

  const onGoToCourseDetail = (
    userPlanId,
    num,
    currentNum,
    planType,
    displayName,
  ) => {
    history.push({
      pathname: '/system/center/detail/userOfflineCourseDetail',
      query: {
        userPlanId,
        num,
        currentNum,
        planType,
        userOrClassName: encodeURI(name),
        trainPlanName: encodeURI(displayName),
      },
    });
  };

  const classAdjustment = (e, courseInfo) => {
    e && e.stopPropagation();
    dispatch({
      type: 'subjectOfflinePlan/getLatelyCourse',
      payload: {
        id: courseInfo.userOfflineTrainingPlan.id,
      },
    });
    dispatch({
      type: 'subjectOfflinePlan/updateState',
      payload: {
        classAdjustmentModalVisible: true,
        addClassType: 'addcclass',
        choicedCClass: [],
        currentAdjustmentCourseInfo: courseInfo,
      },
    });
  };

  const arrangeCourse = (e, courseInfo) => {
    e && e.stopPropagation();
    dispatch({ type: 'subjectOfflinePlan/arrangeCourse', payload: courseInfo });
  };

  const extendClass = (e, courseInfo) => {
    e && e.stopPropagation();
    dispatch({ type: 'subjectOfflinePlan/extendClass', payload: courseInfo });
  };

  const updateData = (type, value) => {
    dispatch({
      type: 'subjectOfflinePlan/updateState',
      payload: {
        [type]: value,
      },
    });
  };

  const classAdjustmentModalProps = {
    userOfflineTrainingPlan:
      currentAdjustmentCourseInfo?.userOfflineTrainingPlan || {},
    classAdjustmentModalVisible,
    addClassType,
    latelyCourse,
    choicedCClass,
    updateData,
    loading: loading.effects['subjectOfflinePlan/courseAdjusment'],
    latelyCourseIsLoading,
    classAdjustmentOnok: (type, value) => {
      const id =
        currentAdjustmentCourseInfo &&
        currentAdjustmentCourseInfo.userOfflineTrainingPlan.id;
      if (value.length == 0) {
        return message.warning(intl.formatMessage({ id: 'pleaseSelectLeast' }));
      }
      if (id == musicPlayerId) {
        dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' });
      }
      dispatch({
        type: 'subjectOfflinePlan/courseAdjusment',
        payload: {
          courses:
            type == 'addpreoneclass' || type == 'addpretwoclass' ? value : [],
          cCourse: type == 'addcclass' ? value : [],
          planId: id,
          intl,
        },
      });
    },
  };

  const openMusicDrawer = (currentMusicPlanInfo) => {
    dispatch({
      type: 'musicplayer/openMusicPlayerDrawer',
      payload: {
        planInfo: currentMusicPlanInfo,
        from: 'subjectOfflinePlan',
        subjectInfo,
      },
    });
  };

  const onMusicPlayerDrawer = (currentMusicPlanInfo) => {
    const isSamePlan = isEqualPlan(currentMusicPlanInfo, musicPlanInfo);
    if (musicPlanInfo && isSamePlan && musicIsOpen) {
      dispatch({
        type: 'musicplayer/updateState',
        payload: { musicPlayerDrawerVisible: true },
      });
    } else {
      if (musicPlanInfo == null || !musicIsOpen) {
        openMusicDrawer(currentMusicPlanInfo);
      } else {
        Modal.confirm({
          title: intl.formatMessage({ id: 'musicPlayingProceed' }),
          okText: intl.formatMessage({ id: 'leave' }),
          cancelText: intl.formatMessage({ id: 'cancel' }),
          onOk: () => {
            openMusicDrawer(currentMusicPlanInfo);
          },
          onCancel() {},
        });
      }
    }
  };

  const createOfflinePlan = (postData) => {
    if (!constant.isCreatePlanLoading) {
      constant.isCreatePlanLoading = true;
      dispatch({
        type: 'subjectOfflinePlan/createOfflinePlan',
        payload: { data: postData, intl },
      });
    }
  };

  const arrangeAssessmentProps = {
    subjectInfo,
    dataSource: arrangeAssessmentData,
    visible: arrangeAssessmentModalVisible,
    onOk: () => {
      updateData('arrangeAssessmentModalVisible', false);
      dispatch({
        type: 'subjectOfflinePlan/submitArrangeAssessment',
        payload: { intl },
      });
    },
    onCancel: () => {
      updateData('arrangeAssessmentModalVisible', false);
    },
  };

  const endPlanModalProps = {
    subjectInfo,
    planInfo: currentEndPlan,
    currentClassItem,
    visible: endPlanModalVisible,
    onOk: (values) => {
      updateData('endPlanModalVisible', false);
      dispatch({
        type: 'subjectOfflinePlan/endPlan',
        payload: { intl, reloadPage, values },
      });
    },
    onCancel: () => {
      updateData('endPlanModalVisible', false);
    },
  };

  const extendModalProps = {
    visible: extendModalVisible,
    currentClassItem,
    subjectInfo,
    confirmLoading: loading.effects['subjectOfflinePlan/extendClassOk'],
    handleOk: (values, currentILs, newILs) => {
      // 新的音乐模块
      const { mode } = values;
      // 选择最新FAS测评结果续课，测评结果与当前音乐模块一样时
      const { courseCode: currentCourseCode } = currentILs || {};
      // 当前音乐模块
      const { courseCode: newCourseCode } = newILs || {};
      const showNewContent =
        mode === 'New' &&
        currentCourseCode?.toLocaleLowerCase() ===
          newCourseCode?.toLocaleLowerCase();

      Modal.confirm({
        title: intl.formatMessage({ id: 'Are you sure to add sessions' }),
        icon: <ExclamationCircleOutlined />,
        content: (
          <span style={{ color: 'red' }}>
            {showNewContent &&
              intl.formatMessage(
                { id: 'Note that the replaced' },
                { courseCode: currentCourseCode?.toUpperCase() },
              )}
            {intl.formatMessage({ id: 'Please operate with caution' })}
          </span>
        ),
        onOk() {
          dispatch({
            type: 'subjectOfflinePlan/extendClassOk',
            payload: { intl, params: values, callback: reloadPage },
          });
        },
      });
    },
    handleCancel: () => {
      updateData('extendModalVisible', false);
    },
  };

  const ArrangeCourseProps = {
    datasource: arrangeCourseList,
    visible: arrangeCourseDrawerVisible,
    addCourse() {
      dispatch({ type: 'subjectOfflinePlan/addCourse' });
    },
    onclose() {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: {
          arrangeCourseDrawerVisible: false,
        },
      });
    },
    onEdit(values) {
      dispatch({ type: 'subjectOfflinePlan/editCourse', payload: values });
    },
    onDelete(values) {
      Modal.confirm({
        title: (
          <div style={{ color: 'red' }}>
            {intl.formatMessage({ id: 'sureDeleteIt' })}
          </div>
        ),
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk() {
          dispatch({
            type: 'subjectOfflinePlan/deleteCourse',
            payload: {
              values,
              intl,
            },
          });
        },
      });
    },
  };

  const showItemProps = {
    visible: videoVisible,
    programId: currentProgramId,
    from: 'subjectPlanDetail',
    userOfflineTrainingPlan,
    onClose() {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: {
          currentProgramId: null,
          videoVisible: false,
        },
      });
    },
  };

  const ArrangeCourseModalProps = {
    title: intl.formatMessage({ id: 'scheduleSession' }),
    visible: arrangeCourseModalVisible,
    content: arrangeCourseContent,
    otherTopContent: (
      <div
        style={{ textAlign: 'center', fontSize: '18px', marginBottom: '25px' }}
      >
        <span style={{ fontWeight: 600 }}>
          {intl.formatMessage(
            { id: 'scheduleSessionsChild' },
            { name, trainName: displayName },
          )}
        </span>
      </div>
    ),
    onCancel() {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: { arrangeCourseModalVisible: false },
      });
    },
    onOk(value) {
      Modal.confirm({
        title: (
          <span style={{ color: 'red' }}>
            {intl.formatMessage({ id: 'submitDes' })}
          </span>
        ),
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk() {
          eventDebounce(() => {
            dispatch({
              type: 'subjectOfflinePlan/submitCourse',
              payload: {
                value,
                intl,
              },
            });
          }, 300);
        },
      });
    },
  };

  const addTrainModalProps = {
    visible: trainModalVisible,
    onCancel: () => {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: {
          trainModalVisible: false,
        },
      });
    },
    currentItem: {
      subject: subjectInfo,
      name: subjectInfo?.name,
      remainingTimes,
    },
    reloadTable: () => {
      dispatch({ type: 'subjectOfflinePlan/loadList' });
    },
    getRemainingTimesStyle,
  };

  const batchDownloadModalProps = {
    isModalVisible,
    modalInfo,
    totalNum: downCurrentNum,
    onClose: () => {
      Modal.destroyAll();
      setIsModalVisible(false);
    },
  };

  const qrCodeProps = {
    url: assessmentQrUrl,
    currentUser,
    visible: assessmentQrUrlModalVisible,
    assessmentInfo: assessmentQrData,
    onCancel: () => {
      dispatch({
        type: 'subjectAssessment/updateState',
        payload: {
          assessmentQrUrlModalVisible: false,
        },
      });
    },
    downloadAssessmentQR: () => {
      dispatch({
        type: 'subjectAssessment/downloadAssessmentQRPhoto',
        payload: { intl },
      });
    },
  };

  const checkEndPlanProps = {
    open: checkEndPlanVisible,
    onCancel: () => {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: {
          checkEndPlanVisible: false,
        },
      });
    },
    onEndPlan: () => {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: {
          endPlanModalVisible: true,
          checkEndPlanVisible: false,
        },
      });
    },
    onEndClass: () => {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: {
          checkEndPlanVisible: false,
        },
      });

      const { userOfflineTrainingPlan, planItems = [], nonePlaybook = [], numPunchOutput = {} } = currentClassItem || {}
      const { currentNum } = userOfflineTrainingPlan || {};
      const { feedbacks: guardianFeedback, hasDone: guardianHasDone } = deepCopy(numPunchOutput || { feedbacks: [], hasDone: false })
      const guardianFeedbackFlag = guardianHasDone == false ? true : guardianFeedback.length > 0

      if (planItems.every(item => item.hasDone !== null) && nonePlaybook.every(item => item.hasDone !== null) && guardianFeedbackFlag) {
        onFinishCourse(userOfflineTrainingPlan, currentNum);
      } else {
        message.warning(intl.formatMessage({ id: 'submitFeedBackDes' }))
      }
    }
  }

  const startPlanProps = {
    activePlan,
    onStartPlan,
    btnLoading: loading.effects['subjectOfflinePlan/startOfflinePlan'],
  };

  if (currentPlanStatus === 'Created') {
    return <StartPlanModal {...startPlanProps} />;
  }

  const recordsTabsProps = {
    activePlan,
    allowReloadTab
  }

  return (
    <div className={styles.tabContent}>
      {!rocketProjectStatus && (
        <div style={{ marginBottom: '20px' }}>
          <RocketProjectTip />
        </div>
      )}
      {hasOfflinePlanWritePermission &&
      rocketProjectStatus &&
      isBind &&
      !isLogout ? (
        <Row type="flex" justify="space-between">
          <Col>
            {remainingTimes !== null &&
              remainingTimes !== undefined &&
              timesSetting && (
                <div>
                  {intl.formatMessage({ id: 'Remaining Sessions' })}:&nbsp;
                  <span
                    style={{
                      ...(parseInt(remainingTimes) <= 5
                        ? { color: 'red' }
                        : {}),
                      ...{ fontWeight: 'bold', fontSize: '20px' },
                    }}
                  >
                    {remainingTimes}
                  </span>
                  <Divider type="vertical" />
                  {intl.formatMessage({ id: 'Total Sessions' })}:&nbsp;
                  {totalTimes}
                  {showOptionRoles && <Divider type="vertical" />}
                  {showOptionRoles && (
                    <a
                      onClick={() =>
                        dispatch({ type: 'subjectOfflinePlan/goSettings' })
                      }
                    >
                      {intl.formatMessage({ id: 'Settings' })}
                    </a>
                  )}
                </div>
              )}
          </Col>
          <Col></Col>
        </Row>
      ) : null}
      {currentlist.length === 0 ? (
        <span></span>
      ) : (
        <Spin spinning={loading.models.subjectOfflinePlan}>
          <List
            grid={{ gutter: 16, column: 1 }}
            dataSource={currentlist}
            renderItem={(item) => {
              const {
                userOfflineTrainingPlan,
                iLs,
                planItems,
                numPunchOutput,
                nonePlaybook,
                musicProgress,
              } = item;
              const {
                displayName,
                status,
                currentNum,
                totalNum,
                id,
                start,
                canNext,
                assessmentStatus,
                userId,
                hasClassRecord,
              } = userOfflineTrainingPlan;

              const allFeedbackDone =
                planItems.every((e) => e.hasDone !== null) &&
                nonePlaybook.every((e) => e.hasDone !== null) &&
                numPunchOutput.hasDone !== null;

              const courseCardProps = {
                allData: item,
                isBind,
                isLogout,
                canNext,
                hasOfflinePlanWritePermission,
                hasOfflinePlanReadPermission,
                rocketProjectStatus,
                endCourseLoading: endCourseLoading && id === currentProgramId,
                musicProgress,
                // onAdjustLevel: () => {
                //   dispatch({
                //     type: 'subjectOfflinePlan/onAdjustLevel',
                //     payload: item,
                //   });
                // },
                onMusicPlayerDrawer,
                isCurrent: true, /// TODO 可能要自己算
                onClickCard: async (userPlanId, num) => {
                  onGoToCourseDetail(
                    userPlanId,
                    num,
                    currentNum,
                    iLs && iLs.audioType,
                    displayName,
                  );
                },
                onClickCardItem: (programId, e) => {
                  e.stopPropagation();
                  dispatch({
                    type: 'subjectOfflinePlan/updateState',
                    payload: {
                      currentProgramId: programId,
                      videoVisible: true,
                      currentArrangeCoursePlanInfo: item,
                    },
                  });
                },
                onFinishCourse: onFinishCourse,
                onStartNextCourse: onStartNextCourse,
                onFeedback: async (userPlanId, num) => {
                  if (num === totalNum) {
                    // 为后边续课弹窗赋值
                    dispatch({
                      type: 'subjectOfflinePlan/updateState',
                      payload: { currentClassItem: item },
                    });
                  }
                  await dispatch({
                    type: 'subjectOfflinePlan/onFeedback',
                    payload: { userPlanId, num },
                  });
                  await dispatch({
                    type: 'subjectOfflinePlan/onGuardianFeedback',
                    payload: { userPlanId, num },
                  });
                },
                previewHfsRecord: async (userPlanId, num) => {
                  const {
                    userOfflineTrainingPlan: { ilsVersion },
                  } = item;
                  const pathName = await getVersionReportUrl(ilsVersion, userPlanId, num);
                  const url =
                    pathName +
                    `?subjectId=${
                      subjectInfo.id
                    }&userPlanId=${userPlanId}&num=${num}&trainerId=${
                      staff && staff.id
                    }&type=preview&culture=${getLocale()}`;
                  window.open(url, '_blank');
                },
                onDownloadHfsRecord: async (userPlanId, num) => {
                  // 调试打开页面使用
                  // const url = `/hfsTrainingRecord?subjectId=${subjectInfo.id}&userPlanId=${userPlanId}&num=${num}&trainerId=${staff && staff.id}`
                  // window.open(url, '_blank')
                  let countDown;
                  const fileBaseName =
                    subjectInfo.name + '-' + displayName + '-';
                  const origin = window.location.origin;
                  const CognitiveleapUser =
                    localStorage.getItem('CognitiveleapUser');
                  const userTenantId = localStorage.getItem('userTenantId');
                  const localStorageTenantId = localStorage.getItem('tenantId');

                  const {
                    userOfflineTrainingPlan: { ilsVersion },
                  } = item;
                  const pathName = await getVersionReportUrl(ilsVersion, userPlanId, num);

                  // 需要打印的页面完整url
                  const url = [
                    origin +
                      pathName +
                      `?subjectId=${
                        subjectInfo.id
                      }&userPlanId=${userPlanId}&num=${num}&trainerId=${
                        staff && staff.id
                      }&CognitiveleapUser=${CognitiveleapUser}&userTenantId=${userTenantId}&tenantId=${localStorageTenantId}&culture=${getLocale()}`,
                  ];
                  const totalTime = getDurationTime(url.length);
                  durationRef.current = totalTime;
                  countDown = setInterval(() => {
                    if (durationRef.current > 0) {
                      durationRef.current--;
                      setPercent(
                        parseInt(
                          ((totalTime - durationRef.current) / totalTime) * 100,
                        ),
                      );
                      setDuration(durationRef.current);
                    }
                  }, 1000);
                  setLoadPageVisible(true);
                  await GetDownloadPdf(
                    { urlList: { ...url }, formatSize: 'Letter' },
                    intl.formatMessage(
                      { id: 'downLoadWhichFeedback' },
                      { fileBaseName, num },
                    ),
                  );
                  setLoadPageVisible(false);
                  clearInterval(countDown);
                  setDuration(0);
                  setPercent(0);
                },
                // onChangeNum: (userPlanId, num) => {
                //   console.log(userPlanId, num);
                //   // dispatch({
                //   //   type: 'subjectOfflinePlan/changeNum',
                //   //   payload: { userOfflinePlanId: userPlanId, num }
                //   // })
                // }
              };
              return (
                <List.Item>
                  <div style={{ marginBottom: 24 }}>
                    <div>
                      {status === 'Started' ? (
                        <CourseCard {...courseCardProps} />
                      ) : null}
                    </div>
                    <div style={{ marginBottom: 2 }}>
                      <Card
                        hoverable
                        type="inner"
                        extra={
                          status === 'None' && (
                            <Button
                              onClick={(e) =>
                                onStartPlan(e, userOfflineTrainingPlan)
                              }
                              size="small"
                              style={{ marginRight: 10 }}
                            >
                              {intl.formatMessage({ id: 'startExerise' })}
                            </Button>
                          )
                        }
                      >
                        <div
                          className={styles.listTitle}
                          onClick={() =>
                            history.push({
                              pathname:
                                '/system/center/detail/userOfflinePlanDetail',
                              query: {
                                id,
                                trainPlanName: encodeURI(displayName),
                                userName: encodeURI(name),
                              },
                            })
                          }
                        >
                          <div>
                            <span className={styles.planTitle}>
                              {displayName}
                            </span>
                            <span className={styles.planCurrentNum}>
                              {currentNum}
                            </span>
                            <span>{`/${totalNum}`}</span>
                          </div>
                          <div className={styles.planOptions}>
                            <Space split={<Divider type="vertical" />} size={1} wrap>
                              {status !== 'None' && status !== 'Created' && (
                                <>
                                  <Button
                                    type="link"
                                    className={styles.classAdjustmentButton}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      dispatch({
                                        type: 'subjectOfflinePlan/showChartsAndFeedbackRecords',
                                        payload: userOfflineTrainingPlan,
                                      });
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: 'charts_feedback',
                                    })}
                                  </Button>
                                </>
                              )}
                              {currentNum > 0 && (
                                <>
                                  <Button
                                    type="link"
                                    className={styles.classAdjustmentButton}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setIsModalVisible(true);
                                      setModalInfo(userOfflineTrainingPlan);
                                      setDownCurrentNum(currentNum);
                                    }}
                                  >
                                    {intl.formatMessage({
                                      id: 'batchDownloadForm',
                                    })}
                                  </Button>
                                </>
                              )}
                              <a
                                onClick={(e) => {
                                  e.stopPropagation();
                                  history.push({
                                    pathname:
                                      '/system/center/detail/userOfflinePlanDetail',
                                    query: {
                                      id,
                                      trainPlanName: encodeURI(displayName),
                                      userName: encodeURI(name),
                                    },
                                  });
                                }}
                              >
                                {intl.formatMessage({ id: 'planDetail' })}
                              </a>
                              {hasOfflinePlanWritePermission &&
                                rocketProjectStatus &&
                                isBind &&
                                !isLogout &&
                                !allFeedbackDone &&
                                !canNext && (
                                  <span>
                                    <Button
                                      type="link"
                                      className={styles.classAdjustmentButton}
                                      onClick={(e) => classAdjustment(e, item)}
                                    >
                                      {intl.formatMessage({
                                        id: 'adjustSessionTime',
                                      })}
                                    </Button>
                                  </span>
                                )}
                              {hasOfflinePlanWritePermission &&
                                rocketProjectStatus &&
                                isBind &&
                                !isLogout &&
                                !canNext && (
                                  <span>
                                    <Button
                                      type="link"
                                      className={styles.classAdjustmentButton}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        dispatch({
                                          type: 'subjectOfflinePlan/onAdjustLevel',
                                          payload: item,
                                        });
                                      }}
                                    >
                                      {intl.formatMessage({
                                        id: 'adjustSessionDifficulty',
                                      })}
                                    </Button>
                                  </span>
                                )}
                              {hasOfflinePlanWritePermission &&
                                rocketProjectStatus &&
                                isBind &&
                                !isLogout &&
                                !canNext &&
                                Boolean(assessmentStatus.length) && (
                                  <span>
                                    <Button
                                      type="link"
                                      className={styles.classAdjustmentButton}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        const { assessment, userAssessment } =
                                          assessmentStatus[0] || {};
                                        let _userAssessment = {
                                          ...userAssessment,
                                        };
                                        const { id: assessmentId, title } =
                                          assessment || {};
                                        delete _userAssessment?.id;
                                        dispatch({
                                          type: 'subjectAssessment/getAssessmentQrCode',
                                          payload: {
                                            record: {
                                              ..._userAssessment,
                                              assessmentName: title,
                                              assessmentId,
                                              userId
                                            },
                                            intl,
                                            from: 'Retest'
                                          },
                                        });
                                      }}
                                    >
                                      {intl.formatMessage({ id: 'Retest' })}
                                    </Button>
                                  </span>
                                )}
                              {hasOfflinePlanWritePermission &&
                                hasCalendarPermission &&
                                rocketProjectStatus &&
                                isBind &&
                                !isLogout &&
                                !canNext && (
                                  <span>
                                    <Button
                                      type="link"
                                      className={styles.classAdjustmentButton}
                                      onClick={(e) => arrangeCourse(e, item)}
                                    >
                                      {intl.formatMessage({
                                        id: 'scheduleSession',
                                      })}
                                    </Button>
                                  </span>
                                )}
                              {status != 'Ended' &&
                                currentNum === totalNum &&
                                !isEnterAdmin() && (
                                  <span>
                                    <Button
                                      type="link"
                                      className={styles.classAdjustmentButton}
                                      onClick={(e) => extendClass(e, item)}
                                    >
                                      {intl.formatMessage({
                                        id: 'Add Sessions Class',
                                      })}
                                    </Button>
                                  </span>
                                )}
                              {status != 'Ended' && !isEnterAdmin() && (
                                <Dropdown
                                  overlay={
                                    <Menu>
                                      <Menu.Item key="terminateThePlan">
                                        <div
                                          style={{
                                            width: '80px',
                                            textAlign: 'center',
                                            color: '#1890ff',
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            if (!hasClassRecord) {
                                              dispatch({
                                                type: 'subjectOfflinePlan/updateState',
                                                payload: {
                                                  endPlanModalVisible: true,
                                                  currentClassItem: item,
                                                  currentEndPlan:
                                                    userOfflineTrainingPlan,
                                                },
                                              });
                                            } else {
                                              dispatch({
                                                type: 'subjectOfflinePlan/updateState',
                                                payload: {
                                                  currentClassItem: item,
                                                  checkEndPlanVisible: true,
                                                  currentEndPlan:
                                                    userOfflineTrainingPlan,
                                                },
                                              });
                                            }
                                          }}
                                        >
                                          {intl.formatMessage({
                                            id: 'terminateThePlan',
                                          })}
                                        </div>
                                      </Menu.Item>
                                    </Menu>
                                  }
                                  placement="bottom"
                                >
                                  <a onClick={(e) => e.stopPropagation()}>
                                    {intl.formatMessage({ id: 'more' })}
                                  </a>
                                </Dropdown>
                              )}
                            </Space>
                          </div>
                          <div>
                            <span>
                              {intl.formatMessage({ id: 'start' })}:
                            </span>
                            <span>
                              {moment(start).format('YYYY/MM/DD HH:mm')}
                            </span>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                </List.Item>
              );
            }}
          />
        </Spin>
      )}
      {currentPlanStatus === 'Started' && (
        <RecordsTabs {...recordsTabsProps} />
      )}
      <SelectPlanTypeModal {...planTypeSelectModalProps} />
      <ClassAdjustmentModal {...classAdjustmentModalProps} />
      <ArrangeAssessmentModal {...arrangeAssessmentProps} />
      <EndPlanModal {...endPlanModalProps} />
      {extendModalVisible && <ExtendClassModal {...extendModalProps} />}
      <FeedbackDrawer {...drawerProps} />
      {trainModalVisible && <AddTrainModal {...addTrainModalProps} />}

      {/* 排课 */}
      <ArrangeCourse {...ArrangeCourseProps} />
      {/* 排课Modal */}
      <MagicFormModal {...ArrangeCourseModalProps} />
      {adjustLevelModalVisible && (
        <AdjustLevelModal {...adjustLevelModalProps} />
      )}
      <DownLoadModal {...downLoadProps} />
      <BatchDownloadModal {...batchDownloadModalProps} />
      {videoVisible && currentProgramId && <ShowItem {...showItemProps} />}
      <AssessmentQrCode {...qrCodeProps} />
      {/* 结束计划弹窗确认 */}
      <CheckEndPlanModal {...checkEndPlanProps} />
    </div>
  );
};

export default connect(
  ({
    subjectAssessment,
    subjectOfflinePlan,
    subjectDetail,
    user,
    loading,
    musicplayer,
  }) => ({
    subjectAssessment,
    subjectOfflinePlan,
    subjectDetail,
    currentUser: user.currentUser,
    loading,
    musicPlanInfo: musicplayer.planInfo,
    musicIsOpen: musicplayer.isOpen,
  }),
)(StepFive);

import React from 'react';
import { useIntl } from 'umi';
import ShowDataAnalysis from 'components/ShowDataAnalysis';
import CardHead from 'components/CardHead';

const contractKeyIndicators = (intl) => [
  {
    title: intl.formatMessage({ id: 'Number of Contracts' }),
    data: 0,
    field: 'contractCount',
  },
  {
    title: intl.formatMessage({ id: 'Total Contract Sessions' }),
    data: 0,
    field: 'totalClass',
  },
  {
    title: intl.formatMessage({ id: 'Paid Sessions' }),
    data: 0,
    field: 'paidClass',
  },
  {
    title: intl.formatMessage({ id: 'Used Sessions' }),
    data: 0,
    field: 'usedClass',
  },
  {
    title: intl.formatMessage({ id: 'User Remaining Sessions' }),
    data: 0,
    field: 'remainClass',
  },
];

const KeyIndicators = ({ keyIndicatorsData = {} }) => {
  const intl = useIntl();

  const showContractKeyIndicators = contractKeyIndicators(intl).map((item) => {
    const { field } = item || {};
    return {
      ...item,
      data:
        field == 'remainClass' && keyIndicatorsData[field] <= 3 ? (
          <span style={{ color: 'red' }}>{keyIndicatorsData[field]}</span>
        ) : (
          keyIndicatorsData[field]
        ),
    };
  });

  return (
    <div>
      <CardHead title={intl.formatMessage({ id: 'keyIndicators' })} />
      <div style={{ marginLeft: '20px' }}>
        <ShowDataAnalysis keyIndicators={showContractKeyIndicators} />
      </div>
    </div>
  );
};

export default KeyIndicators;

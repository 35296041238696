import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import moment from 'moment';

import CardHead from 'components/CardHead';
import ShowDataAnalysis from 'components/ShowDataAnalysis';
import AnalysisChoiceDate from '../AnalysisComponent/AnalysisChoiceDate';
import CommonAnalysisLineChart from 'cognitiveleap-core-us/components/Charts/Common/CommonAnalysisLineChart';
import {
  GetOneToOneTrainingStatistics,
  GetOneToOneTrainingAnalsis,
} from 'services/rocketService/Analysis';
import { showErrorMsg } from 'cognitiveleap-core-us/utils/utils';
import { useIntl } from 'umi';
import { oneToOneKeyIndicators, oneToOneIlsLegend } from '../config';
import styles from './index.less';

const StatisticalData = ({ currentTenantId }) => {
  const intl = useIntl();

  const [statisticsData, setStatisticsData] = useState({});
  const [analsis, setAnalsis] = useState({});
  const [currentTag, setCurrentTag] = useState('Month');
  const [statisticsLoading, setStatisticsLoading] = useState(false);
  const [analsisLoading, setAnalsisLoading] = useState(false);
  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [dateMonthPickerData, setDateMonthPickerData] = useState(moment());
  const [dateYearPickerData, setDateYearPickerData] = useState(moment());

  const _oneToOneKeyIndicators = oneToOneKeyIndicators(intl)

  const initData = async () => {
    setStatisticsLoading(true);
    const res = await GetOneToOneTrainingStatistics({
      TenantId: currentTenantId,
    });

    if (res.response.ok) {
      setStatisticsData(res.data);
    } else {
      showErrorMsg(res, intl);
    }
    setStatisticsLoading(false);
  };

  const initAnalysisData = async () => {
    setAnalsisLoading(true);
    const res = await GetOneToOneTrainingAnalsis({
      Type: currentTag,
      Year: parseInt(moment(dateYearPickerData).format('YYYY')),
      Month: parseInt(moment(dateMonthPickerData).format('MM')),
      TenantId: currentTenantId,
    });

    if (res.response.ok) {
      const { trend } = res.data;
      setAnalsis(trend);
    } else {
      showErrorMsg(res, intl);
    }
    setAnalsisLoading(false);
  };

  useEffect(() => {
    initData();
  }, [currentTenantId]);

  useEffect(() => {
    initAnalysisData();
  }, [dateMonthPickerData, dateYearPickerData, currentTenantId, currentTag]);

  if (
    JSON.stringify(statisticsData) == '{}' ||
    JSON.stringify(analsis) == '{}'
  ) {
    return <Spin />;
  }

  _oneToOneKeyIndicators.forEach((item) => {
    item.data = statisticsData[item.field];
  });

  const analysisChoiceDateProps = {
    currentTag,
    dateMonthPickerData,
    dateYearPickerData,
    datePickerIsOpen,
    changeTag: (tag) => {
      const { key } = tag;
      setCurrentTag(key);
    },
    handleOpenChange: () => {
      setDatePickerIsOpen(!datePickerIsOpen);
    },
    handlePanelChange: (time) => {
      setDateMonthPickerData(
        {
          Year: dateMonthPickerData,
          Month: moment(time),
        }[currentTag],
      );
      setDateYearPickerData(
        {
          Year: moment(time),
          Month: dateYearPickerData,
        }[currentTag],
      );

      setDatePickerIsOpen(!datePickerIsOpen);
    },
  };

  const chartProps = {
    legendData: oneToOneIlsLegend(intl),
    currentTag,
    dateMonthPickerData,
    dateYearPickerData,
    dataSource: analsis,
  };

  return (
    <div className={styles.vratAnalysis}>
      <div className={styles.keyIndicators}>
        <CardHead title={intl.formatMessage({ id: 'keyIndicators' })} />
        <Spin spinning={statisticsLoading}>
          <ShowDataAnalysis keyIndicators={_oneToOneKeyIndicators} />
        </Spin>
      </div>
      <div className={styles.trendAnalysis}>
        <CardHead title={intl.formatMessage({ id: 'trendAnalysis' })} />
        <AnalysisChoiceDate {...analysisChoiceDateProps} />
        <div className={styles.trendAnalysisContent}>
          <Spin spinning={analsisLoading}>
            <CommonAnalysisLineChart {...chartProps} />
          </Spin>++
        </div>
      </div>
    </div>
  );
};

export default StatisticalData;

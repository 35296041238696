import React, { useState, useEffect } from 'react';

import { Form, Modal, Checkbox, Row, Col, message, Spin, Button } from 'antd';
import { useIntl } from 'umi';

import {
  GetTrialPlanBeforeStart,
  PostTrialPlanStart,
  PutTrainingType,
  PutComplaint,
} from 'services/trialPlan';

import { showAttributeDOM, formatSubmitValue } from 'utils/feedback';

const StartTrialModal = ({
  userId,
  option = 'add', // editMainComplaint, editTrainingType
  trialData,
  visible,
  onCloseModal,
  onReload,
}) => {
  const intl = useIntl();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState({
    initLoading: false,
    submitLoading: false,
  });
  const [feedbackData, setFeedbackData] = useState({});
  const [formData, setFormData] = useState({});
  const [step, setStep] = useState(option === 'editMainComplaint' ? 2 : 1);

  // 编辑时数据处理
  const { items = [], complaint, id: trialPlanId } = trialData || {};
  const { value = '', id: complaintId } = complaint || {};

  const { categories = [], feedbackProperties = {} } = feedbackData || {};
  const {
    extraProperties = {},
    displayName,
    type,
    id,
  } = feedbackProperties || {};

  const initData = async () => {
    setLoading({
      ...loading,
      initLoading: true,
    });
    const res = await GetTrialPlanBeforeStart();

    if (res.response.ok) {
      setFeedbackData(res?.data || {});
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      initLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, []);

  const handleOk = () => {
    // if (option === 'add') {
    //   if (step === 1) {
    //     setFormData(form.getFieldsValue(true));
    //     setStep(2);
    //   } else {
    //     form.submit();
    //   }
    // } else {
    //   form.submit();
    // }
    form.submit();
  };

  const handleCancel = () => {
    // if (option === 'add') {
    //   if (step === 2) {
    //     setStep(1);
    //   } else {
    //     onCloseModal();
    //   }
    // } else {
    //   onCloseModal();
    // }
    onCloseModal();
  };

  const onFinish = async (values) => {
    setLoading({
      ...loading,
      submitLoading: true,
    });

    let res;

    const { categories, complaint } = { ...formData, ...values };

    if (option === 'add') {
      const addParams = {
        userId,
        categories,
        // complaint: {
        //   value: formatSubmitValue(type, complaint),
        //   programFeedbackPropertyId: id,
        // },
      };
      res = await PostTrialPlanStart(addParams);
    } else if (option === 'editTrainingType') {
      res = await PutTrainingType({
        trialPlanId,
        ...values,
      });
    } else if (option === 'editMainComplaint') {
      res = await PutComplaint({
        value: formatSubmitValue(type, complaint),
        programFeedbackPropertyId: id,
        id: complaintId,
      });
    }

    if (res.response.ok) {
      onCloseModal();
      onReload();
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      submitLoading: false,
    });
  };

  return (
    <Modal
      title={
        step == 2
          ? displayName
          : intl.formatMessage({ id: 'Select Training Type' })
      }
      open={visible}
      onCancel={handleCancel}
      width={600}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {/* {intl.formatMessage({
            id:
              option === 'add'
                ? step === 1
                  ? 'cancel'
                  : 'previous'
                : 'cancel',
          })} */}
          {intl.formatMessage({
            id: 'cancel',
          })}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading.submitLoading}
          onClick={handleOk}
        >
          {/* {intl.formatMessage({
            id:
              option === 'add'
                ? step === 1
                  ? 'next'
                  : 'submitOther'
                : 'submitOther',
          })} */}
          {intl.formatMessage({
            id: 'submitOther',
          })}
        </Button>,
      ]}
    >
      <Spin spinning={loading.initLoading}>
        <h3>
          {step === 1
            ? intl.formatMessage({ id: 'Select the type' })
            : displayName}
        </h3>
        <p />
        <Form form={form} onFinish={onFinish} layout="vertical">
          {step === 1 ? (
            <Form.Item
              name="categories"
              initialValue={items.map((item) => item.category.id)}
            >
              <Checkbox.Group style={{ marginLeft: '40px' }}>
                <Row>
                  {categories.map((item) => {
                    const { displayName, id } = item || {};
                    const findData =
                      items.find((i) => i.category.id === id) || {};
                    const { trialPlanItems = [] } = findData || {};

                    const isDisabled =
                      option === 'editTrainingType' &&
                      trialPlanItems.some((item) => item.doneTime);

                    return (
                      <Col key={id} span={24}>
                        <Checkbox
                          value={id}
                          style={{
                            lineHeight: '32px',
                          }}
                          disabled={isDisabled}
                        >
                          {displayName}
                          {isDisabled && (
                            <span
                              style={{
                                color: 'rgba(0, 0, 0, 0.25)',
                                marginLeft: '10px',
                              }}
                            >
                              {intl.formatMessage({
                                id: 'Feedback has been given',
                              })}
                            </span>
                          )}
                        </Checkbox>
                      </Col>
                    );
                  })}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          ) : (
            <Form.Item name="complaint" initialValue={value.split(',') || []}>
              {showAttributeDOM(type, extraProperties, {
                style: { marginLeft: '40px' },
              })}
            </Form.Item>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export default StartTrialModal;

import { matchDynamicForm, addOptionsToProperties } from 'cognitiveleap-core-us/utils/utils'
import {
  PostPrepareData,
  GetPrepareList,
  GetPrepareEdit,
  PutPrepareData,
  DeletePrepareData,
} from 'services/rocketService/Prepare'

import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
import config from 'utils/config'
import { message } from 'antd'
const { baseURL, uploadCategoryPic } = config

const listToOptions = (list, showAttributeArr) => {
  return list.map(item => {
    const Value = showAttributeArr.map(attribute => `${item[attribute]} `)
    return { Value, Id: item.id.toString() }
  })
}

let contentMenu = {
  Properties: [
    {
      EditorType: 'Input',
      ShowTitle: '名称',
      FormKey: 'name',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'displayName',
      FormKey: 'displayName',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: 'code',
      FormKey: 'code',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
    {
      EditorType: 'Upload',
      Value: null,
      Setting: {
        UploadSetting: {
          uploadType: 'image',
        },
        Action: baseURL + uploadCategoryPic,
        Header: getAuthHeader(),
      },
      ShowTitle: 'images',
      FormKey: 'images',
    },
    {
      EditorType: 'EditableList',
      ShowTitle: '反馈（训练项目）',
      FormKey: 'feedbackProperties',
      AdditionalData: null,
      Value: null,
      Setting: {
        ListElement: {
          Properties: [
            {
              EditorType: 'SingleWithSearch',
              Value: null,
              Setting: {
                DropdownOptions: [],
                Required: false,
              },
              ShowTitle: '反馈属性ID',
              FormKey: 'feedbackPropertyId',
              Description: null,
            },
            {
              EditorType: 'Bool',
              ShowTitle: '是否必填',
              FormKey: 'isRequired',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: null,
            },
            {
              EditorType: 'Input',
              ShowTitle: '补充备注',
              FormKey: 'extra',
              AdditionalData: null,
              Value: null,
              Setting: {},
              Description: '',
            },
          ],
        },
      },
      Description: null,
    },
    {
      EditorType: 'NumberInput',
      ShowTitle: 'level',
      FormKey: 'level',
      AdditionalData: null,
      Value: null,
      Setting: {
        NumberSetting: {
          min: 0,
          step: 1,
          precision: 0,
        },
      },
      Description: null,
    },
    {
      EditorType: 'Input',
      ShowTitle: '类别的类型(type)',
      FormKey: 'categoryType',
      AdditionalData: null,
      Value: '',
      Setting: {
        Required: false,
      },
      Description: null,
    },
  ],
}

const listSupport = {
  name: {
    showText: 'name',
    showType: 'Text',
  },
  displayName: {
    showText: 'displayName',
    showType: 'Text',
  },
  level: {
    showText: 'level',
    showType: 'Text',
  },
  code: {
    showText: 'code',
    showType: 'Text',
  },
  categoryType: {
    showText: '类别的类型(type)',
    showType: 'Text',
  },
}

export default {
  namespace: 'category',
  state: {
    list: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
    listSupport: {},
    modalVisible: false,
    content: null,
    modalType: 'edit',
    selectItem: null,
  },
  reducers: {
    updateState(state, { payload }) {
      return {
        ...state,
        ...payload,
      }
    },
  },
  effects: {
    *query(_, { take, put, call, select }) {
      const { pagination } = yield select(state => state.category)
      let query = { skipCount: 0, maxResultCount: 10 }

      const currentPageIndex = pagination.current
      const currentPageSize = pagination.pageSize
      query = {
        ...query,
        skipCount: (currentPageIndex - 1) * currentPageSize,
        maxResultCount: currentPageSize,
      }

      const res = yield call(GetPrepareList, query, 'category')
      if (res.response.ok) {
        const { items, totalCount } = res.data
        const newPagination = {
          current: currentPageIndex,
          pageSize: currentPageSize,
          total: totalCount,
        }
        yield put({
          type: 'updateState',
          payload: { pagination: newPagination, list: items, listSupport },
        })
      }
    },

    *changeTable({ payload: data }, { call, put, select }) {
      const { pagination } = data
      yield put({ type: 'updateState', payload: { pagination } })
      yield put({ type: 'query' })
    },

    *onAddOrEdit({ payload: id }, { call, put, select }) {
      const isAdd = id === null || id === undefined

      let emptyContent = contentMenu,
        insertValue = null

      const feedbackRes = yield call(GetPrepareList, {
        skipCount: 0,
        maxResultCount: 999,
      }, 'feedback')

      if (feedbackRes.response.ok) {
        const { items = [] } = feedbackRes.data

        const propertyOptions = listToOptions(items, ['displayName', 'name', 'type'])

        emptyContent = addOptionsToProperties(
          emptyContent,
          ['feedbackProperties', 'feedbackPropertyId'],
          propertyOptions
        )

        let showForm = emptyContent

        if (!isAdd) {
          const res = yield call(GetPrepareEdit, id, 'category')

          if (res.response.ok) {
            const { feedbackProperties = [] } = res.data || {}

            const feedbacks = feedbackProperties.map(item => {
              const { extraProperties, id } = item
              const extra = JSON.stringify(extraProperties)
              return {
                ...item,
                feedbackPropertyId: id,
                extra,
              }
            })

            insertValue = {
              ...res.data,
              feedbackProperties: feedbacks,
            }

            showForm = matchDynamicForm(emptyContent, insertValue)

            yield put({
              type: 'updateState',
              payload: {
                selectItem: insertValue,
              },
            })
          }
        }

        yield put({
          type: 'updateState',
          payload: {
            content: showForm,
            modalType: isAdd ? 'add' : 'edit',
            modalVisible: true,
          },
        })
      }
    },

    *onDelete({ payload: id }, { call, put, select }) {
      const res = yield call(DeletePrepareData, id, 'category')
      if (res.response.ok) {
        yield put({ type: 'query' })
      }
    },

    // *onAdd(_, { call, put, select }) {
    //   let emptyContent = null
    //   emptyContent = contentMenu
    //   yield put({
    //     type: 'updateState',
    //     payload: { modalType: 'add', content: emptyContent, modalVisible: true },
    //   })
    // },

    *onSubmitModal({ payload: data }, { take, put, call, select }) {
      const { modalType, selectItem } = yield select(state => state.category)
      let res
      if (modalType === 'edit') {
        res = yield call(PutPrepareData, { ...selectItem, ...data }, selectItem.id, 'category')
      } else {
        res = yield call(PostPrepareData, data, 'category')
      }
      if (res.response.ok) {
        yield put({ type: 'query' })
        yield put({ type: 'updateState', payload: { modalVisible: false } })
      } else {
        const { error } = res.data
        message.error(error.message)
      }
    },
  },
  subscriptions: {
    // setup({ dispatch, history }) {
    //   return history.listen(location => {
    //     if (location.pathname === '/system/manage/content/project/programPieces/category') {
    //       dispatch({ type: 'query' })
    //     }
    //   })
    // },
  },
}

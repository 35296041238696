import React, { useEffect } from 'react'
import Question from 'components/Question'
import ShowSurvey from 'cognitiveleap-core-us/components/ShowSurvey'
import ShowPdf from 'components/ShowPdf'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Progress, Collapse, Spin, message, Divider, Empty, Space, Modal, Drawer, Image, Timeline } from 'antd'
import { convertDuration, getActiveContentTime } from 'utils/utils'
import { connect, history, useIntl, getLocale } from 'umi'
import MyVideo from 'components/MyVideo'
import styles from './index.less'
import lockImgUrl from 'assets/icon_locked.png'
import iconTimeImgUrl from 'assets/icon_time.png'
import iconBookImgUrl from 'assets/icon_book.png'
import iconPassGrayImgUrl from 'assets/icon_pass_gray.png'
import iconPdfImgUrl from 'assets/icon_pdf.png'
import iconViewImgUrl from 'assets/icon_View.png'
import passImgUrl from 'assets/pass.png'
import unpassImgUrl from 'assets/unpass.png'
import passEnImgUrl from 'assets/passEn.png'
import unpassEnImgUrl from 'assets/unpassEn.png'
import iconCheckCircle from 'assets/icon_Check-Circle.png'
import iconWarning from 'assets/icon_Warning.png'
import fullScreen from 'assets/fullscreen.png'
import iconTest from 'assets/icon_test.png'

const { Panel } = Collapse;

const TrainingDetail = ({ loading, dispatch, trainingDetail, location }) => {

    const intl = useIntl()
    const isZh = getLocale() === 'zh-CN' ? true : false;

    const { courseId, moduleId } = location.query || {}

    useEffect(() => {
        dispatch({ type: 'trainingDetail/updateState', payload: { preCourseId: courseId, preModuleId: moduleId, activeCollapseKey: [] } })
        dispatch({ type: 'trainingDetail/pageLoading', payload: { ...location.query, isReset: true, openNew: true } })
        dispatch({ type: 'trainingDetail/getNextCourseData', payload: { ...location.query } })
    }, [courseId])

    // 预计学习时长
    // let studyHour = 0
    // let studyMin = 0

    const {
        courseDetail,
        activeContent,
        activeCollapseKey,
        visible,
        questionnaireDetail,
        submitData,
        currentAssmessmentIsFinshed,
        showSurveyVisible,
        correntRateAndResult,
        isStudyingContent,
        currentPdfInfo,
        preCourseId,
        preModuleId,
        nextCourseData
    } = trainingDetail
    const {
        userCourseDetail,
        id: activeContentId,
        name,
        resourceList,
        contentType,
        questionnaires,
        timeConsuming: activeContentTimeConsuming,
        detail,
        attachments
    } = activeContent || {}
    const { isFinish, userQuestionnaireId, isPass, times, correctRate } = userCourseDetail || {}
    // const { trainingModule } = courseDetail || {}
    // const { courses } = trainingModule || {}
    // const currentCourseDetail = courses && courses.length > 0 ? courses.find(item => item.courseId === courseId) : null
    // const { course } = currentCourseDetail || {}
    const { questionnaireAndAnswer } = questionnaireDetail || {}
    const { questionnaire, answer } = questionnaireAndAnswer || {}
    const { jsonString, displayName } = questionnaire || {}
    const { questionArr, correntRate, isPass: correntRateAndResultIspass, correntCount } = correntRateAndResult || {}

    // const { stepIndex = [], contents = [], title, length, passCount } = course || {}
    const { stepIndex = [], contents = [], title, length, passCount } = courseDetail || {}


    // 累加计算学习时长
    // let timeConsuming = 0
    // contents.forEach(item => {
    //     timeConsuming += item.timeConsuming
    // })

    const timeConsuming = contents.reduce((pre, current) => {
        return parseInt(pre) + parseInt(current.timeConsuming)
    }, 0)

    const courseStepIndex = stepIndex.map(item => {
        const ids = item.contentId
        const content = []
        ids.forEach(id => {
            const findContent = contents.find(item => item.id === id)
            if (findContent) content.push(findContent)
        })
        return {
            ...item,
            content,
        }
    })

    // 给stepIndex添加真正得content
    // stepIndex.forEach((item, index) => {
    //     item.content = []
    //     item.contentId.forEach(id => {
    //         const content = contents.find(item => item.id === id)
    //         if (content) item.content.push(content)
    //     })
    // })

    const changeContent = (item) => {
        const { id, isLocked } = item
        if (isLocked) {
            message.warning('请先学完并通过当前课')
            return
        }

        if (activeContentId === id) {
            return
        }
        dispatch({ type: 'trainingDetail/changeContent', payload: item })
    }

    const renderItemCatalog = (item, isContent) => {
        const { name, timeConsuming, userCourseDetail, id, isLocked, questionnaires } = item || {}
        const { isFinish, correctRate, isPass, times, userQuestionnaireId } = userCourseDetail || {}
        return (
            <div
                className={
                    `${styles.trainingDetailContentRightItem} 
                     ${isContent && styles.trainingDetailContentRightItemBorder} 
                     ${activeContentId === id && styles.trainingDetailContentRightActive}`
                }
                key={id}
                onClick={() => changeContent(item)}
            >
                <div className={styles.title}>
                    {name}
                    {
                        isFinish ?
                            userQuestionnaireId &&
                            <img src={iconTest} />
                            :
                            questionnaires && questionnaires.length > 0 &&
                            <img src={iconTest} />
                    }
                </div>
                {
                    isLocked ?
                        <React.Fragment>
                            <div className={styles.item}>
                                <div className={styles.itemIcon}><img src={iconTimeImgUrl} />
                                    <span>{intl.formatMessage({ id: 'expectedLearningTime' })} {getActiveContentTime(timeConsuming, intl)}</span>
                                </div>
                            </div>
                            <div className={styles.trainingDetailContentRightItemLocked}><img src={lockImgUrl} /></div>
                        </React.Fragment>
                        :
                        <div>
                            <div className={styles.item}>
                                <div className={styles.itemIcon}>
                                    <img src={iconTimeImgUrl} />
                                    <span>{intl.formatMessage({ id: 'expectedLearningTime' })} {getActiveContentTime(timeConsuming, intl)}</span>
                                </div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.itemIcon}><img src={iconBookImgUrl} /><span>{intl.formatMessage({ id: isFinish ? 'studyCompleted' : 'unfinished' })}</span></div>
                            </div>
                            {
                                correctRate !== -1 && userCourseDetail &&
                                <div className={styles.item}>
                                    <div className={styles.itemIcon}>
                                        <img src={iconPassGrayImgUrl} />
                                        <span>{intl.formatMessage({ id: isPass ? 'pass' : 'failed' })}</span>
                                        <Divider type="vertical" />
                                        <span>{intl.formatMessage({ id: 'accuracyRate' })} {correctRate && `${correctRate}%` || '0%'}</span>
                                        <Divider type="vertical" />
                                        <span>{intl.formatMessage({ id: 'allTimesWereSubmitted' }, { num: times || 0 })}</span>
                                    </div>
                                </div>
                            }
                            <div className={styles.renderContentIcon}>
                                {
                                    isFinish && isPass ?
                                        <img src={iconCheckCircle} />
                                        :
                                        isStudyingContent && isStudyingContent.id === id ?
                                            <img src={iconWarning} />
                                            :
                                            <span></span>
                                }
                            </div>
                        </div>
                }
            </div>
        )
    }

    const onVideoended = () => {
        if (!isFinish) {
            Modal.confirm({
                title: <b>{intl.formatMessage({ id: 'whetherMarkFinished' })}?</b>,
                okText: intl.formatMessage({ id: 'submit' }),
                cancelText: intl.formatMessage({ id: 'cancel' }),
                onOk() {
                    dispatch({ type: 'trainingDetail/asignStudyed', payload: { intl } })
                },
                onCancel() { }
            })
        }
    }

    const getCourseDetailContent = (item, type, isShowIcon) => {
        const { url, fileName, id, coverUrl } = item || {}
        switch (type) {
            case "Doc":
                return (
                    <div className={styles.doc}>
                        {isShowIcon && <img src={iconPdfImgUrl} />}
                        <a
                            onClick={() => {
                                localStorage.setItem('reviewfileUrl', item.url)
                                window.open('/system/reviewfile', '_blank')
                            }}
                        >{fileName}</a>
                    </div>
                )
                break
            case "Video":
                const videoProps = {
                    id,
                    url,
                    coverUrl,
                    width: '100%',
                    height: '700px',
                    onended: onVideoended
                }
                return <div style={{ width: 'calc(100% - 200px)' }}><MyVideo {...videoProps} ></MyVideo></div>
            case "Audio":
                return <audio controls src={item.url}></audio>
            case "Richtext":
                return (
                    <div>
                        <div className={styles.richtextImg}>
                            <Image src={item.url} width={250} />
                        </div>
                        <Divider style={{ width: '100%' }} />
                    </div>
                )
            default:
                return <div></div>
        }
    }

    const getDocDom = () => {
        const isPdf = /.pdf$/.test(currentPdfInfo && currentPdfInfo.url)
        const showPdfProps = {
            url: currentPdfInfo && currentPdfInfo.url,
            scale: 0.8,
            isShowLeftRight: true,
            isPdf
        }
        return (
            <div className={styles.trainingDetailContentDoc}>
                <div className={styles.trainingDetailContentDocTop}>
                    {
                        resourceList && resourceList.length > 0 ?
                            resourceList.length === 1 ?
                                <span></span>
                                // <a className={styles.trainingDetailContentDocTopOne}>{resourceList[0] && resourceList[0].fileName}</a>
                                :
                                <Timeline>
                                    {
                                        resourceList.map(item => {
                                            const { url, fileName, id } = item || {}
                                            const color = currentPdfInfo && currentPdfInfo.id === id ? '#1890FF' : 'gray'
                                            const changeTimelineItem = () => {
                                                if (currentPdfInfo && currentPdfInfo.id !== id) {
                                                    dispatch({ type: 'trainingDetail/getCurrentPdfInfo', payload: { resourceList, id } })
                                                }
                                            }
                                            return (
                                                <Timeline.Item key={id} color={color} onClick={changeTimelineItem}>
                                                    <a style={{ color }}>{fileName}</a>
                                                </Timeline.Item>
                                            )
                                        })
                                    }
                                </Timeline>
                            :
                            <span></span>
                    }
                </div>
                <div className={styles.trainingDetailContentDocBottom}>
                    {
                        currentPdfInfo &&
                        <React.Fragment>
                            <ShowPdf {...showPdfProps} />
                            <img
                                src={fullScreen}
                                className={styles.fullScreen}
                                onClick={() => {
                                    if (isPdf) {
                                        localStorage.setItem('reviewfileUrl', currentPdfInfo && currentPdfInfo.url)
                                        window.open('/system/reviewfile', '_blank')
                                    }
                                }}
                            />
                        </React.Fragment>
                    }
                </div>
            </div>
        )
    }

    const asignStudyed = () => {
        dispatch({ type: 'trainingDetail/asignStudyed', payload: { intl } })
        // Modal.confirm({
        //     title: <span style={{ color: 'red', fontSize: '17px' }}>{intl.formatMessage({ id: "markDes" })}</span>,
        //     icon: <ExclamationCircleOutlined />,
        //     okText: intl.formatMessage({ id: 'submit' }),
        //     cancelText: intl.formatMessage({ id: 'cancel' }),
        //     onOk() {
        //         dispatch({ type: 'trainingDetail/asignStudyed', payload: { intl } })
        //     },
        //     onCancel() {
        //     },
        // });
    }

    const onComplete = ({ answerContent, isComplete, finishedPage }) => {
        if (isComplete) {
            dispatch({ type: 'trainingDetail/onComplete', payload: { answerContent, isComplete, finishedPage, intl } })
            // Modal.confirm({
            //     title: <span>确定提交吗?</span>,
            //     okText: '确定',
            //     cancelText: '取消',
            //     onOk() {
            //         dispatch({ type: 'trainingDetail/onComplete', payload: { answerContent, isComplete, finishedPage } })
            //     },
            //     onCancel() { }
            // })
        } else {
            dispatch({ type: 'trainingDetail/onComplete', payload: { answerContent, isComplete, finishedPage, intl } })
        }
    }

    const onValueChange = ({ answerContent, isComplete, finishedPage, needSubmit }) => {
        dispatch({ type: 'trainingDetail/updateState', payload: { submitData: { answerContent, isComplete, finishedPage } } })
        if (needSubmit) onComplete({ answerContent, isComplete, finishedPage })
    }

    const questionProps = {
        content: jsonString || '',
        answerContent: submitData && submitData.answerContent,
        answer: '',
        onComplete: onComplete,
        onValueChange: onValueChange,
        time: 10
    }

    const showSurveyProps = {
        contents: questionArr,
        choice: answer && answer.answerContent ? answer.answerContent : null
    }

    const goback = () => {
        history.goBack()
    }

    return (
        <Spin spinning={loading.models.trainingDetail}>
            <div className={styles.trainingDetail}>
                <div className={styles.trainingDetailHead}>
                    <div className={styles.trainingDetailHeadLeft}>
                        <Button onClick={goback}>{intl.formatMessage({ id: 'back' })}</Button>
                        <div className={styles.trainingDetailHeadLeftTitle}>
                            <div className={styles.trainingDetailHeadLeftTitleTop}>{title}</div>
                            {
                                activeContent &&
                                <div className={styles.trainingDetailHeadLeftTitleBottom}>{getActiveContentTime(timeConsuming, intl)}</div>
                            }
                        </div>
                    </div>
                    {
                        activeContent &&
                        <div className={styles.trainingDetailHeadRight}>
                            <Progress
                                percent={length > 0 ? (passCount / length) * 100 : 0}
                                format={(percent) => `${passCount}/${length}`}
                                strokeColor={length > 0 && passCount === length ? '#3C84FF' : '#F96B4B'}
                            />
                        </div>
                    }
                </div>
                {
                    activeContent ?
                        <div className={styles.trainingDetailContent}>
                            <div className={styles.trainingDetailContentLeft}>
                                <div className={styles.trainingDetailContentLeftTitle}>{name}</div>
                                <div className={styles.trainingDetailContentLeftTime}>{getActiveContentTime(activeContentTimeConsuming, intl)}</div>
                                <div className={styles.trainingDetailContentLeftContent}>
                                    <div className={styles.trainingDetailContentLeftContentFile}>
                                        {
                                            contentType === 'Doc' ?
                                                <div>{getDocDom()}</div>
                                                :
                                                resourceList &&
                                                resourceList.map(item => {
                                                    return <div key={item && item.id}>{getCourseDetailContent(item, contentType)}</div>
                                                })
                                        }
                                    </div>
                                    {
                                        detail &&
                                        <div className={styles.trainingDetailContentLeftContentDesc}>
                                            <div className={styles.trainingDetailContentLeftContentDescTitle}>
                                                <Divider type='vertical' style={{ width: '2px', height: '16px', backgroundColor: '#3C84FF', marginLeft: '0' }} />
                                                <span>{intl.formatMessage({ id: 'introduction' })}</span>
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: detail }} />
                                        </div>
                                    }
                                    {
                                        attachments && attachments.length > 0 &&
                                        <div className={styles.trainingDetailContentLeftContentAttachments}>
                                            <div className={styles.trainingDetailContentLeftContentAttachmentsTitle}>
                                                <Divider type='vertical' style={{ width: '2px', height: '16px', backgroundColor: '#3C84FF', marginLeft: '0' }} />
                                                <span>{intl.formatMessage({ id: 'attachments' })}</span>
                                            </div>
                                            {
                                                attachments.map(item => {
                                                    return <div key={item && item.id}>{getCourseDetailContent(item, 'Doc', true)}</div>
                                                })
                                            }
                                        </div>
                                    }
                                </div>
                                <div className={styles.trainingDetailContentLeftBtn}>
                                    <Button
                                        className={`${styles.trainingDetailContentLeftBtn1} ${isFinish && styles.trainingDetailContentLeftDisabledBtn1}`}
                                        onClick={() => asignStudyed()}
                                        disabled={isFinish}
                                    >
                                        {intl.formatMessage({ id: isFinish ? 'studyCompleted' : 'markAsComplete' })}
                                    </Button>
                                    <div className={styles.trainingDetailContentLeftBtnRight}>
                                        {
                                            isFinish ?
                                                (
                                                    userQuestionnaireId &&
                                                    <React.Fragment>
                                                        <div className={styles.trainingDetailContentLeftBtnRightTotal}>
                                                            <Button
                                                                className={styles.trainingDetailContentLeftBtn2}
                                                                style={{ backgroundColor: isPass ? '#FDCBC0' : '#F96B4B', color: '#fff' }}
                                                                // disabled={isPass}
                                                                disabled={false}
                                                                onClick={() => dispatch({ type: 'trainingDetail/createQuestionnaire', payload: { type: 'create' } })}
                                                            >
                                                                {intl.formatMessage({ id: isPass ? 'pass' : 'test' })}
                                                            </Button>
                                                            {times > 0 && <img src={iconViewImgUrl} onClick={() => dispatch({ type: 'trainingDetail/createQuestionnaire', payload: { type: 'show' } })} />}
                                                        </div>
                                                        <div className={styles.trainingDetailContentLeftBtnRightText}>
                                                            {
                                                                isPass ?
                                                                    <div className={styles.trainingDetailContentLeftBtnRightTextPass}>
                                                                        <Space split={<Divider type="vertical" />}>
                                                                            <span>{intl.formatMessage({ id: 'pass' })}</span>
                                                                            <span>{`${intl.formatMessage({ id: 'accuracyRate' })} ${correctRate || 0}%`}</span>
                                                                            <span>{intl.formatMessage({ id: 'allTimesWereSubmitted' }, { num: times || 0 })}</span>
                                                                        </Space>
                                                                    </div>
                                                                    :
                                                                    <div className={styles.trainingDetailContentLeftBtnRightTextUnpass}>
                                                                        {
                                                                            Number(times) === 0 ?
                                                                                intl.formatMessage({ id: 'notStart' }) :
                                                                                <Space split={<Divider type="vertical" />}>
                                                                                    <span>{intl.formatMessage({ id: 'failed' })}</span>
                                                                                    <span>{`${intl.formatMessage({ id: 'accuracyRate' })} ${correctRate || 0}%`}</span>
                                                                                    <span>{intl.formatMessage({ id: 'allTimesWereSubmitted' }, { num: times || 0 })}</span>
                                                                                </Space>
                                                                        }
                                                                    </div>
                                                            }
                                                        </div>
                                                    </React.Fragment>
                                                )
                                                :
                                                (
                                                    questionnaires && questionnaires.length > 0 &&
                                                    <React.Fragment>
                                                        <Button
                                                            className={styles.trainingDetailContentLeftBtn2}
                                                            style={{ backgroundColor: '#F5F5F5' }}
                                                            disabled={true}
                                                        >
                                                            {intl.formatMessage({ id: 'test' })}
                                                        </Button>
                                                        <div className={styles.trainingDetailContentLeftBtnRightText}>{intl.formatMessage({ id: 'untilFinishedStudying' })}</div>
                                                    </React.Fragment>
                                                )
                                        }
                                        {/* {
                                            questionnaires && questionnaires.length > 0 ?
                                                (
                                                    isFinish ?
                                                        <React.Fragment>
                                                            <div className={styles.trainingDetailContentLeftBtnRightTotal}>
                                                                <Button
                                                                    className={styles.trainingDetailContentLeftBtn2}
                                                                    style={{ backgroundColor: isPass ? '#FDCBC0' : '#F96B4B', color: '#fff' }}
                                                                    // disabled={isPass}
                                                                    disabled={false}
                                                                    onClick={() => dispatch({ type: 'trainingDetail/createQuestionnaire', payload: { type: 'create' } })}
                                                                >
                                                                    {isPass ? '测验通过' : '测验'}
                                                                </Button>
                                                                {times > 0 && <img src={iconViewImgUrl} onClick={() => dispatch({ type: 'trainingDetail/createQuestionnaire', payload: { type: 'show' } })} />}
                                                            </div>
                                                            <div className={styles.trainingDetailContentLeftBtnRightText}>
                                                                {
                                                                    isPass ?
                                                                        <div className={styles.trainingDetailContentLeftBtnRightTextPass}>
                                                                            <Space split={<Divider type="vertical" />}>
                                                                                <span>已通过</span>
                                                                                <span>{`正确率 ${correctRate || 0}%`}</span>
                                                                                <span>{`共提交${times || 0}次`}</span>
                                                                            </Space>
                                                                        </div>
                                                                        :
                                                                        <div className={styles.trainingDetailContentLeftBtnRightTextUnpass}>
                                                                            {
                                                                                Number(times) === 0 ?
                                                                                    '未开始' :
                                                                                    <Space split={<Divider type="vertical" />}>
                                                                                        <span>未通过</span>
                                                                                        <span>{`正确率 ${correctRate || 0}%`}</span>
                                                                                        <span>{`共提交${times || 0}次`}</span>
                                                                                    </Space>
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <Button
                                                                className={styles.trainingDetailContentLeftBtn2}
                                                                style={{ backgroundColor: '#F5F5F5' }}
                                                                disabled={true}
                                                            >
                                                                测验
                                                            </Button>
                                                            <div className={styles.trainingDetailContentLeftBtnRightText}>已学完之后才可以开始测验</div>
                                                        </React.Fragment>
                                                )
                                                :
                                                <span></span>
                                        } */}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.trainingDetailContentRight}>
                                <div className={styles.trainingDetailContentRightBack}>
                                    <Space>
                                        <Button
                                            onClick={() => dispatch({ type: 'trainingDetail/pageLoading', payload: { isReset: true, openNew: true, courseId: preCourseId, moduleId: preModuleId } })}
                                            disabled={(isStudyingContent && isStudyingContent.id) === (activeContent && activeContent.id)}
                                        >
                                            {intl.formatMessage({ id: 'backToThePresent' })}
                                        </Button>
                                        {
                                            Object.keys(nextCourseData || {}).length > 0 && (
                                                <Button
                                                    type="primary"
                                                    onClick={() => {
                                                        const { courseId, trainerTrainingId } = nextCourseData || {};
                                                        history.replace({
                                                            pathname: '/system/center/detail/trainingDetail',
                                                            query: {
                                                            moduleId: trainerTrainingId,
                                                            courseId,
                                                            },
                                                        });
                                                    }}
                                                >
                                                    {intl.formatMessage({ id: 'nextCourse' })}
                                                </Button>
                                            )
                                        }
                                    </Space>
                                </div>
                                {
                                    courseStepIndex.length > 0 ?
                                        <Collapse
                                            activeKey={activeCollapseKey}
                                            onChange={(value) => dispatch({ type: 'trainingDetail/changeActiveCollapseKey', payload: value })}
                                        >
                                            {
                                                courseStepIndex.map((item) => {
                                                    const { title, content, index } = item || {}
                                                    return (
                                                        <Panel header={title} key={title}>
                                                            {
                                                                content && content.map((subItem, subIndex) => {
                                                                    return renderItemCatalog(subItem)
                                                                })
                                                            }
                                                        </Panel>
                                                    )
                                                })
                                            }
                                        </Collapse>
                                        :
                                        contents.map(item => {
                                            return renderItemCatalog(item, true)
                                        })
                                }
                            </div>
                        </div>
                        :
                        <div className={styles.empty}>
                            <div><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
                        </div>
                }
            </div>
            <Drawer
                title={
                    <div className={styles.drawerTitle}>
                        {visible && <b style={{ fontSize: '20px' }}>{displayName}</b>}
                        {
                            showSurveyVisible &&
                            <React.Fragment>
                                <div><b style={{ fontSize: '20px' }}>{displayName}</b></div>
                                <Space split={<Divider type="vertical" />}>
                                    <span>{intl.formatMessage({ id: 'totalQuestions' }, { num: questionArr && questionArr.length || 0 })}</span>
                                    <span>{intl.formatMessage({ id: 'correctQuestions' }, { num:  correntCount || 0})}</span>
                                    <span>{intl.formatMessage({ id: 'worryQuestions' }, { num: (questionArr && questionArr.length - correntCount) || 0 })}</span>
                                    <span>{intl.formatMessage({ id: 'allTimesWereSubmitted' }, { num: times || 0 })}</span>
                                </Space>
                                <div className={isZh ? styles.ispassImg : styles.ispassEnImg}>
                                    {
                                        correntRateAndResultIspass ?
                                            <React.Fragment>
                                                <img src={isZh ? passImgUrl : passEnImgUrl} />
                                                <div className={styles.passRote}>{`${intl.formatMessage({ id: 'accuracyRate' })}${correctRate}%`}</div>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <img src={isZh ? unpassImgUrl : unpassEnImgUrl} />
                                                <div className={styles.unpassRote}>{`${intl.formatMessage({ id: 'accuracyRate' })}${correctRate}%`}</div>
                                            </React.Fragment>
                                    }
                                </div>
                            </React.Fragment>
                        }
                    </div>
                }
                open={visible || showSurveyVisible}
                width={700}
                onClose={() => dispatch({ type: 'trainingDetail/updateState', payload: { visible: false, showSurveyVisible: false } })}
            >
                {
                    visible &&
                    <Question {...questionProps} />
                }
                {
                    showSurveyVisible &&
                    <ShowSurvey {...showSurveyProps} />
                }
            </Drawer>
        </Spin>
    )
}

export default connect(({ loading, trainingDetail }) => ({ loading, trainingDetail }))(TrainingDetail)
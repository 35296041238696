import React, { useEffect } from 'react'
import { connect, history } from 'umi'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import { checkFeedbackProperty } from 'utils/utils'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import { Button, Modal, message, Input, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons';
import config from 'utils/config'
import { getAuthHeader } from 'cognitiveleap-core-us/utils/auth'
const { baseURL, uploadExcelUrl } = config

const confirm = Modal.confirm
const Search = Input.Search

const upLoadExcel = baseURL + uploadExcelUrl

const TrainingProgram = ({ dispatch, trainingProgram }) => {

  useEffect(() => {
    dispatch({ type: 'trainingProgram/query' })
  }, [])

  const {
    list,
    listSupport,
    pagination,
    modalType,
    content,
    modalVisible,
    availableTypes,
    avaliableFeedbackProperties,
    searchInput,
    updateVidContent,
    updateVidVisible,
    isUploading
  } = trainingProgram

  const stringToList = str => {
    const parts = str.split(':')
    if (parts.length === 2) {
      return parts
    } else return []
  }

  const checkProperty = (type, extraProperties) => {
    const currentType = availableTypes.find(item => {
      return item.name === type
    })
    if (currentType === undefined) return false
    const { constraints } = currentType || {}
    return Object.keys(extraProperties).every(item => {
      return constraints.some(constraintItem => {
        return constraintItem.name === item
      })
    })
  }

  const modalTypeToTitle = type => {
    switch (type) {
      case 'add':
        return '新建训练项目'
      case 'edit':
        return '编辑训练项目'
      default:
        return ''
    }
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'trainingProgram/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      const { feedbackProperties, ageStart, ageEnd, suggestedTimeLength } = value
      const allReady = feedbackProperties.every(item => {
        const { extra, feedbackPropertyId } = item
        const feedbackProperty = avaliableFeedbackProperties.find(propertyItem => {
          return (propertyItem.id === feedbackPropertyId)
        })
        const { type, extraProperties } = feedbackProperty
        return checkFeedbackProperty(type, extra, extraProperties)
      })
      if (allReady) {
        const feedbacks = feedbackProperties.map(item => {
          const { extra, } = item
          const extraProperties = JSON.parse(extra)
          return {
            ...item,
            extraProperties,
          }
        })
        dispatch({
          type: 'trainingProgram/onSubmitModal',
          payload: {
            ...value,
            feedbackProperties: feedbacks,
            agePeriod: { start: ageStart, end: ageEnd },
            suggestedTimeLength: suggestedTimeLength * 60
          },
        })
      }
    },
    content,
  }

  const updateVidProps = {
    title: '更新VID',
    visible: updateVidVisible,
    onCancel() {
      dispatch({ type: 'trainingProgram/updateState', payload: { updateVidVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'trainingProgram/onsubmitVid', payload: value })
    },
    content: updateVidContent
  }

  const tableProps = {
    listData: list.map(item => {
      const { primaryTarget, videos } = item || {}
      const video = videos ? videos[0] : null
      const videoFileName = video ? video.fileName : ''
      return {
        ...item,
        primaryTargetDisplayName: primaryTarget && primaryTarget.displayName,
        fileName: videoFileName,
        coverImg: video
      }
    }),
    listSupport,
    pagination,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      // if (Object.keys(filt).length !== 0) data = { ...data, filters: filt }
      dispatch({ type: 'trainingProgram/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '更新Vid',
        onClick(record) {
          dispatch({ type: 'trainingProgram/updateVid', payload: record })
        },
      },
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'trainingProgram/onAddOrEdit', payload: { id: record.id } })
        },
      },
      {
        showText: '删除',
        onClick(record) {
          confirm({
            title: '你确定要删除该条数据?',
            content: '删除后将无法恢复！',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
              dispatch({ type: 'trainingProgram/onDelete', payload: record.id })
            },
          })
        },
      },
      {
        showText: '建议',
        onClick(record) {
          const { id, title } = record || {}
          history.push({
            pathname: '/system/manage/suggestionDetail',
            query: {
              id,
              title
            },
          })
        },
      },
    ],
  }

  const onAddBtnClick = () => {
    dispatch({ type: 'trainingProgram/onAddOrEdit', payload: null })
  }

  const uploadProps = {
    name: 'file',
    accept: ".xls, .xlsx",
    action: upLoadExcel,
    headers: getAuthHeader(),
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <h1>训练项目</h1>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Button onClick={onAddBtnClick}>新建训练项目</Button>
          <Search
            placeholder="训练项目名称"
            onSearch={value =>
              dispatch({ type: 'trainingProgram/changeTable', payload: { searchInput: value } })
            }
            onChange={e =>
              dispatch({
                type: 'trainingProgram/updateState',
                payload: { searchInput: e.target.value },
              })
            }
            style={{ width: 200 }}
            value={searchInput}
          />
          <Button
            onClick={() =>
              dispatch({ type: 'trainingProgram/clearAllFilterAndSorter' })
            }
          >
            Clear
          </Button>
        </div>
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      </div>
      <br />
      <br />
      <MagicFormModal {...modalProps} />
      <MagicFormModal {...updateVidProps} />
      <MagicTable {...tableProps} />
    </div>
  )
}
export default connect(({ trainingProgram }) => ({ trainingProgram }))(TrainingProgram)

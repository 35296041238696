import { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { message, Modal, Input } from 'antd';
import { useIntl, history } from 'umi';

import {
  PostGamificationList,
  PutTrainingProgramSuggestion,
  PostGamificationListExcel,
} from 'services/suggestion';

import moment from 'moment';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import download from 'cognitiveleap-core-us/utils/download';
import ApplyModal from './ApplyModal';

const dateFormat = 'MM/DD/YYYY HH:mm';

const { confirm } = Modal;
const { TextArea } = Input;

const listSupport = (intl, isAdmin) => {
  return {
    creationTime: {
      showText: intl.formatMessage({ id: 'submissionTime' }),
      showType: 'Time',
      addtional: {
        format: dateFormat,
      },
    },
    code: {
      showText: intl.formatMessage({ id: 'Code' }),
      showType: 'Text',
    },
    trainingProgramTitle: {
      showText: intl.formatMessage({ id: 'Activity Name' }),
      showType: 'Text',
      render: (text, record) => {
        const { trainingProgramId } = record || {};
        return isAdmin ? (
          <a
            onClick={() => {
              history.push({
                pathname: '/system/manage/suggestionDetail',
                query: {
                  id: trainingProgramId,
                  title: text,
                },
              });
            }}
          >
            {text}
          </a>
        ) : (
          text
        );
      },
    },
    content: {
      showText: intl.formatMessage({ id: 'Submitted Content' }),
      showType: 'Remark',
      addtional: {
        showLength: 10,
      },
    },
    creator: {
      showText: intl.formatMessage({ id: 'Submitter' }),
      showType: 'Text',
    },
    passed: {
      showText: intl.formatMessage({ id: 'Review' }),
      showType: 'Text',
      render: (text) => {
        if (text == null) {
          return intl.formatMessage({ id: 'unprocessed' });
        }
        return intl.formatMessage({ id: text ? 'approved' : 'rejected' });
      },
      filter: {
        hasFilter: true,
        filterOptions: [
          { text: intl.formatMessage({ id: 'unprocessed' }), value: 'UnReviewed' },
          { text: intl.formatMessage({ id: 'approved' }), value: 'Passed' },
          { text: intl.formatMessage({ id: 'rejected' }), value: 'NotPassed' },
        ],
      }
    },
    reviewer: {
      showText: intl.formatMessage({ id: 'Reviewer' }),
      showType: 'Text',
    },
    valid: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (text, record) => {
        return intl.formatMessage({ id: text ? 'valid' : 'invalid' });
      },
    },
    likes: {
      showText: 'Likes',
      showType: 'Text',
      render: (text) => text.length,
    },
  };
};

const Gamification = ({ isAdmin, setExportLoading, onRef }) => {
  const intl = useIntl();

  const editContent = useRef('');

  const [listData, setListData] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [visible, setVisible] = useState({
    applyModalVisible: false,
  });
  const [filters, setFilters] = useState([])
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
    modalLoading: false,
    gamificationLoading: false,
  });

  useImperativeHandle(onRef, () => {
    return {
      exportData: async () => {
        setExportLoading(true);

        const { passed } = filters || { };
        const res = await PostGamificationListExcel({
          auditResult: passed || [],
          SkipCount: (pagination.current - 1) * pagination.pageSize,
          MaxResultCount: pagination.pageSize,
        });

        if (res.response.ok) {
          download(
            res.data,
            `${intl.formatMessage({ id: 'gameGamification' })}+${moment().format(
              'YYYY_MM_DD HH_mm',
            )}.xlsx`,
          );
        }

        setExportLoading(false);
      },
    };
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const { passed } = filters || { };
    const res = await PostGamificationList({
      auditResult: passed || [],
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { totalCount, items } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current, filters]);

  const onView = (record) => {
    const { content, updateTime, updater } = record || {};

    confirm({
      title: intl.formatMessage({ id: 'gameGamification' }),
      width: 600,
      icon: <></>,
      content: (
        <>
          <div style={{ margin: '12px 0' }}>{content}</div>
          {updater && (
            <div style={{ color: 'grey' }}>
              {updater} {intl.formatMessage({ id: 'Edited' })}{' '}
              {moment(updateTime).format(dateFormat)}
            </div>
          )}
        </>
      ),
      okText: intl.formatMessage({ id: 'edit' }),
      onOk() {
        onEdit(record);
      },
      onCancel() {},
    });
  };

  const onEdit = (record) => {
    const { content, trainingProgram } = record || {};
    const { title } = trainingProgram || {};

    confirm({
      title: intl.formatMessage(
        { id: 'Gamification Suggestions title' },
        { title },
      ),
      width: 600,
      icon: <></>,
      content: (
        <TextArea
          rows={4}
          defaultValue={content}
          placeholder={intl.formatMessage({
            id: 'Suggestions for training activity',
          })}
          onChange={(e) => {
            editContent.current = e.target.value;
          }}
        />
      ),
      okText: intl.formatMessage({ id: 'submitOther' }),
      confirmLoading: loading.gamificationLoading,
      onOk: () => onEditSubmit(record),
      onCancel() {},
    });
  };

  const onEditSubmit = async (record) => {
    const { id, content } = record || {};
    setLoading({
      ...loading,
      gamificationLoading: true,
    });

    const res = await PutTrainingProgramSuggestion({
      content: editContent.current || content,
      id,
    });

    if (res.response.ok) {
      initData();
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      gamificationLoading: false,
    });
  };

  const tableProps = {
    listData: listData.map((item) => {
      const { trainingProgram } = item || {};
      const { code, title: trainingProgramTitle } = trainingProgram || {};
      return {
        code,
        trainingProgramTitle,
        ...item,
      };
    }),
    listSupport: listSupport(intl, isAdmin),
    loading: loading.tableLoading,
    filters,
    pagination,
    onTableChange(page, filt = {}) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }

      if (Object.keys(filt).length !== 0) {
        setFilters(filt);
        setPagination({
          ...pagination,
          current: 1,
        });
      }
    },
    Actions: isAdmin
      ? [
          {
            showText: intl.formatMessage({ id: 'see' }),
            itemRender(record) {
              return (
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    onView(record);
                  }}
                >
                  {intl.formatMessage({ id: 'see' })}
                </a>
              );
            },
          },
          {
            showText: intl.formatMessage({ id: 'Review' }),
            itemRender(record) {
              return (
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    setCurrentItem(record);
                    setVisible({
                      ...visible,
                      applyModalVisible: true,
                    });
                  }}
                >
                  {intl.formatMessage({ id: 'Review' })}
                </a>
              );
            },
          },
        ]
      : [],
  };

  const applyModalProps = {
    visible: visible.applyModalVisible,
    currentItem,
    reloadPage: initData,
    onCloseModal: () => {
      setVisible({
        ...visible,
        applyModalVisible: false,
      });
    },
  };

  return (
    <div>
      <MagicTable {...tableProps} />
      {visible.applyModalVisible && <ApplyModal {...applyModalProps} />}
    </div>
  );
};

export default Gamification;

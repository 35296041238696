import React from 'react';
import { Space } from 'antd';
import { useIntl } from 'umi';

import veryHappySelect from 'assets/veryHappySelect.png';
import happySelect from 'assets/happySelect.png';
import badSelect from 'assets/badSelect.png';

import { resolveSurveyData } from 'cognitiveleap-core-us/utils/utils';

import styles from './index.less';

import moment from 'moment';

const showStandardList = {
  Below: badSelect,
  Meeting: happySelect,
  Exceeding: veryHappySelect,
};

const CategoryPerformance = ({
  trialData,
  assessmentData,
  categoryData = [],
}) => {
  const intl = useIntl();

  let physicalAbilityData = {};

  const { sessionFeedbacks = [] } = trialData || {};
  const { questionnaireAnswer = {} } = assessmentData || {};

  const { answer, questionnaire } = questionnaireAnswer || {};

  const { answerContent } = answer || {};
  const { jsonString } = questionnaire || {};

  const feedbackData = resolveSurveyData(
    JSON.parse(answerContent || '{}'),
    JSON.parse(jsonString || '{}'),
    'allData',
  );

  if (feedbackData && feedbackData.length > 0) {
    const filterData = feedbackData.filter(
      (item) => item.name === 'physicalAbility',
    );
    if (filterData && filterData.length > 0) {
      physicalAbilityData = filterData[0];
    }
  }

  const { value: physicalAbilityValue = [] } = physicalAbilityData || {};

  const getPhysicalAbilityValue = (name) => {
    const findData =
      physicalAbilityValue.find((item) => item.key === name) || {};

    return Object.keys(findData).length > 0 ? findData.columnText : '';
  };

  // 总体反馈数据
  const showData = (type, key = 'value') => {
    const findData =
      sessionFeedbacks.find(
        (item) => item.programFeedbackProperty.name === type,
      ) || {};

    const { value, programFeedbackProperty } = findData || {};

    const { displayName } = programFeedbackProperty || {};

    switch (type) {
      case 'courseTime':
        return value ? moment(value).format('MM/DD/YYYY HH:mm') : '';
      case 'overallBehaviour':
        return key === 'value' ? value : displayName;
      default:
        return value;
    }
  };

  return (
    <div className={styles.categoryPerformanceContent}>
      <table>
        <tbody>
          <tr>
            <th style={{ width: '140px' }}></th>
            <th style={{ width: '130px' }}>
              {intl.formatMessage({ id: 'Parent Assessment' })}
            </th>
            <th style={{ width: '218px' }}>
              {intl.formatMessage({ id: 'Assessment Activities' })}
            </th>
            <th style={{ width: '110px' }}>
              {intl.formatMessage({ id: 'Category Assessment' })}
            </th>
            <th style={{ width: '156px' }}>
              {intl.formatMessage({ id: 'Recommended Starting Level' })}
            </th>
          </tr>
          {categoryData.map((item) => {
            let firstItem;
            const { category, trialPlanItems = [], difficulty } = item || {};
            const { displayName, id, name } = category || {};

            if (trialPlanItems.length > 0) {
              firstItem = trialPlanItems[0];
            }

            const { standard: firstStandard, trainingProgram } =
              firstItem || {};
            const {
              title: firstStandardTitle,
              difficulty2: firstStandardDifficulty,
            } = trainingProgram || {};

            return (
              <>
                <tr key={id}>
                  <th scope="row" rowSpan={trialPlanItems.length || 1}>
                    {displayName}
                  </th>
                  <td scope="row" rowSpan={trialPlanItems.length || 1}>
                    {getPhysicalAbilityValue(name)}
                  </td>
                  {firstItem ? (
                    <>
                      <td style={{ fontWeight: '400' }}>
                        {firstStandardTitle} - {firstStandardDifficulty}
                      </td>
                      <td align="center">
                        {showStandardList[firstStandard] && (
                          <img src={showStandardList[firstStandard]} />
                        )}
                      </td>
                    </>
                  ) : (
                    <>
                      <td style={{ fontWeight: '400' }}>----</td>
                      <td align="center">----</td>
                    </>
                  )}
                  <td scope="row" rowSpan={trialPlanItems.length || 1}>
                    {difficulty?.replace(/[^0-9]/g, '')}
                  </td>
                </tr>
                {trialPlanItems.length > 1 &&
                  trialPlanItems.slice(1).map((planItem) => {
                    const { trainingProgram, id, standard } = planItem || {};
                    const { title, difficulty2 } = trainingProgram || {};
                    return (
                      <tr key={id}>
                        <td style={{ fontWeight: '400' }}>
                          {title} - {difficulty2}
                        </td>
                        <td align="center">
                          {showStandardList[standard] && (
                            <img src={showStandardList[standard]} />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </>
            );
          })}
        </tbody>
      </table>
      <div className={styles.note}>
        <span className={styles.noteText}>
          {intl.formatMessage({ id: 'Note' })}:
        </span>
        <Space size="large" style={{ fontWeight: '400' }}>
          <Space>
            <img src={badSelect} />
            <div>{intl.formatMessage({ id: 'Below Standard' })}</div>
          </Space>
          <Space>
            <img src={happySelect} />
            <div>{intl.formatMessage({ id: 'Meeting Standard' })}</div>
          </Space>
          <Space>
            <img src={veryHappySelect} />
            <div>{intl.formatMessage({ id: 'Exceeding Standard' })}</div>
          </Space>
        </Space>
      </div>
      <div className={styles.overallBehavior}>
        <span>
          {intl.formatMessage({ id: 'Overall Performance' })}:{' '}
          {showData('overallBehaviour')}
        </span>
      </div>
    </div>
  );
};

export default CategoryPerformance;

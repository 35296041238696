import React, { useState, useEffect } from 'react';
import { Button, Form, InputNumber, Modal, Space, Row, Col } from 'antd';
import { useIntl, getLocale } from 'umi';
import { getAdolescentRecommendProject } from 'cognitiveleap-core-us/report/utils';
import { GetLastCustomPlan } from 'services/rocketService/Assessment';

import styles from '../detail.less';

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es-ES',
};

const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};

const regex = /\([^)]*?(?:课时|sessions)[^)]*?\)/g; // 匹配括号内包含课时或sessions的内容

const StartPlanModal = ({ activePlan, onStartPlan, btnLoading }) => {
  const intl = useIntl();

  const { id, assessmentStatus = [] } = activePlan || {};

  const [form] = Form.useForm();

  const language = LangOptions[getLocale()];

  const [assessmentData, setAssessmentData] = useState({});
  const [startPlanFormData, setStartPlanFormData] = useState({});
  const [categoriesFormData, setCategoriesFormData] = useState({});
  const [visible, setVisible] = useState(false);

  const initData = async () => {
    const res = await GetLastCustomPlan({
      userOfflineTrainingPlanId: id,
      fromDatabase: Boolean(assessmentStatus.length),
    });

    if (res.response.ok) {
      setAssessmentData(res.data);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const { arrangeableClass, contractRemainClass, recommendMusicCourse, recommendUserAssessment } =
    assessmentData || {};
  const { categories = [] } = recommendUserAssessment || {};

  useEffect(() => {
    if (categories && categories.length > 0) {
      setCategoriesFormData(
        categories.map((item) => {
          const { categoryId, recommendDifficulty } = item || {};
          return {
            categoryId,
            startDifficulty: recommendDifficulty,
          };
        }),
      );
    }
  }, [categories]);

  const recommendProject =
    getAdolescentRecommendProject(
      `(${recommendMusicCourse?.toUpperCase()})`,
      language,
    ) || recommendMusicCourse?.toUpperCase();

  if (!Object.keys(assessmentData).length) return <></>;

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    form.submit();
  };

  const onCategoriesFinish = () => {
    const params = {
      startDifficulty: categoriesFormData,
      ...startPlanFormData,
    };

    onStartPlan(params);
  };

  const onFinish = (values) => {
    setVisible(true);
    setStartPlanFormData(values);
  };

  const getTotalNum = () => {
    if (arrangeableClass <= 0 || contractRemainClass <= 0) {
      return null;
    }
    if (arrangeableClass >= contractRemainClass) {
      return contractRemainClass;
    } else {
      return arrangeableClass;
    }
  }

  return (
    <div className={styles.startPlanModal}>
      <Form name="startPlan" {...formItemLayout} onFinish={onFinish} initialValues={{ totalNum: getTotalNum() }}>
        <Form.Item label={intl.formatMessage({ id: 'Recommend Music Module' })}>
          <span>{recommendProject?.replace(regex, '').trim()}</span>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'Total number of hours' })}>
          <span>{arrangeableClass}</span>
        </Form.Item>
        <Form.Item
          name="totalNum"
          label={intl.formatMessage({ id: 'CurrSessions' })}
          rules={[{ required: true }]}
        >
          <InputNumber min={1} max={arrangeableClass} />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 12,
            offset: 9,
          }}
        >
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: 'next' })}
          </Button>
        </Form.Item>
      </Form>

      <Modal
        title={intl.formatMessage({ id: 'Starting Difficulty Confirmation' })}
        open={visible}
        onCancel={handleCancel}
        width={600}
        footer={[
          <Button key="back" onClick={handleCancel}>
            {intl.formatMessage({ id: 'cancel' })}
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={btnLoading}
            onClick={handleOk}
          >
            {intl.formatMessage({ id: 'startPlan2' })}
          </Button>,
        ]}
      >
        <Form form={form} onFinish={onCategoriesFinish} layout="vertical">
          <Form.Item name="startDifficulty">
            <Row gutter={[0, 12]}>
              {categories.map((item) => {
                const { categoryId, recommendDifficulty, category } =
                  item || {};
                const { displayName } = category || {};

                return (
                  <Col span={24} key={categoryId}>
                    <Space>
                      <div style={{ width: '160px', textAlign: 'right' }}>
                        {displayName}:{' '}
                      </div>
                      <InputNumber
                        defaultValue={recommendDifficulty}
                        min={1}
                        max={15}
                        onChange={(value) => {
                          const index = categoriesFormData.findIndex(
                            (categoriesItem) =>
                              categoriesItem.categoryId === categoryId,
                          );
                          categoriesFormData[index].startDifficulty = value;
                          setCategoriesFormData([...categoriesFormData]);
                        }}
                      />
                    </Space>
                  </Col>
                );
              })}
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default StartPlanModal;

import moment from 'moment'
import { message, Modal } from "antd"
import { GetSubjectSimpleList, PostUserList } from 'services/userManager'
import { PostArrangeCourse, GetArrangeCourse, PutCalendar, GetPlanDetailInfo, DeleteCalendar, GetTeanntCourseCoseList, GetCoachCourseCostList } from 'services/arrangeCourse'
import { changeTenant, changeUserTenant } from 'cognitiveleap-core-us/utils/auth'
import { deepCopy } from 'cognitiveleap-core-us/utils/utils'
import { getFormatDate } from 'utils/utils'
import { getDvaApp } from 'umi'
import { IntlManager } from 'utils/helper'

const arrangeCourseContent = (formatMessage) => {
    return {
        add: [
            {
                Value: formatMessage({ id: 'never' }),
                Id: '0',
            },
            // {
            //     Value: '每天',
            //     Id: '1',
            // },
            {
                Value: formatMessage({ id: 'oneWeek' }),
                Id: '2',
            },
            {
                Value: formatMessage({ id: 'twoWeeks' }),
                Id: '3',
            },
            {
                Value: formatMessage({ id: 'oneMonth' }),
                Id: '4',
            },
        ],
        eidt: [
            // {
            //     Value: '每天',
            //     Id: '1',
            // },
            {
                Value: formatMessage({ id: 'oneWeek' }),
                Id: '2',
            },
            {
                Value: formatMessage({ id: 'twoWeeks' }),
                Id: '3',
            },
            {
                Value: formatMessage({ id: 'oneMonth' }),
                Id: '4',
            },
        ]
    }
}

const getTimeSpan = (interval) => {
    switch (interval) {
        case '0':
            return null;
        case '1':
            return {
                periodIntervalUnit: 'Day',
                periodIntervalNumber: 1
            }
        case '2':
            return {
                periodIntervalUnit: 'Day',
                periodIntervalNumber: 7
            }
        case '3':
            return {
                periodIntervalUnit: 'Day',
                periodIntervalNumber: 14
            }
        case '4':
            return {
                periodIntervalUnit: 'Month',
                periodIntervalNumber: 1
            }
    }
}

// 获取重复排课
const getRepetitiveStyle = (periodIntervalNumber, periodIntervalUnit) => {
    if (periodIntervalNumber === 1 && periodIntervalUnit === "Day") {
        return "1"
    }
    if (periodIntervalNumber === 7 && periodIntervalUnit === "Day") {
        return "2"
    }
    if (periodIntervalNumber === 14 && periodIntervalUnit === "Day") {
        return "3"
    }
    if (periodIntervalNumber === 1 && periodIntervalUnit === "Month") {
        return "4"
    }
    return '0'
}

// 个人创建排课显示的字段
const oneselfCreateKey = ["startTime", "tenantId", "subjectId", "courseType", "interval", "courseCount", "SMSReminder"]
// 个人编辑单次排课显示字段
const oneselfNonRepetitiveCreateKey = ["startTime", "tenantId", "subjectId", "courseType", "SMSReminder"]
// 个人编辑重复排课显示字段
const oneselfRepetitiveCreateKey = ["startTime", "tenantId", "subjectId", "courseType", "editTakeEffect", "interval", "SMSReminder"]
// 中心创建排课显示的字段
const centerCreateKey = ["startTime", "trainer", "subjectId", "courseType", "interval", "courseCount", "SMSReminder"]
// 中心编辑单次排课显示字段
const centerNonRepetitiveCreateKey = ["startTime", "trainer", "subjectId", "courseType", "SMSReminder"]
// 中心编辑重复排课显示字段
const centerRepetitiveCreateKey = ["startTime", "trainer", "subjectId", "courseType", "editTakeEffect", "interval", "SMSReminder"]

let contentMenu = formatMessage => {
    return {
        Properties: [
            {
                EditorType: 'DatePicker',
                ShowTitle: formatMessage({ id: 'startTimefrom' }),
                FormKey: 'startTime',
                AdditionalData: null,
                Value: null,
                Setting: {
                    format: 'MM/DD/YYYY HH:mm',
                    minuteStep: 5,
                    Required: true,
                    DateType: 'time',
                    disabledHours: [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23]
                },
                Description: formatMessage({ id: 'durationHour' }),
            },
            {
                EditorType: 'SingleWithSearch',
                Value: null,
                Setting: {
                    DropdownOptions: [],
                    Required: true,
                    Props: {
                        onSelect: (e, option) => {
                            getDvaApp()._store.dispatch({
                                type: 'classCard/ChangeTenant',
                                payload: e
                            })
                        }
                    }
                },
                ShowTitle: formatMessage({ id: 'onlyCenter' }),
                FormKey: 'tenantId',
                Description: null,
            },
            {
                EditorType: 'SingleWithSearch',
                Value: null,
                Setting: {
                    DropdownOptions: [],
                    Required: true,
                    Props: {
                        onSelect: (e, option) => {
                            getDvaApp()._store.dispatch({
                                type: 'classCard/ChangeTrainer',
                                payload: e
                            })
                        }
                    }
                },
                ShowTitle: formatMessage({ id: 'trainer' }),
                FormKey: 'trainer',
                Description: null,
            },
            {
                EditorType: 'SingleWithSearch',
                Value: null,
                Setting: {
                    DropdownOptions: [],
                    Required: true,
                    // Required: false,
                    // Props: {
                    //     onSearch: (value) => {
                    //         const { type } = getDvaApp()._store.getState().classCard
                    //         if (type === "oneself") {
                    //             getDvaApp()._store.dispatch({
                    //                 type: 'classCard/SearchSubjectListAndUpdateForm',
                    //                 payload: {
                    //                     Filter: value
                    //                 }
                    //             })
                    //         } else {
                    //             getDvaApp()._store.dispatch({
                    //                 type: 'classCard/SearchSubjectListAndUpdateFormByTrainer',
                    //                 payload: {
                    //                     Filter: value
                    //                 }
                    //             })
                    //         }
                    //     },
                    //     filterOption: false,
                    // }
                },
                ShowTitle: formatMessage({ id: 'subject' }),
                FormKey: 'subjectId',
                Description: null,
            },
            {
                EditorType: 'Dropdown',
                Value: null,
                Setting: {
                    DropdownOptions: [
                        {
                            Value: formatMessage({ id: 'offline' }),
                            Id: 'Offline',
                        },
                        {
                            Value: formatMessage({ id: 'online' }),
                            Id: 'Online',
                        },
                    ],
                    Required: true,
                },
                ShowTitle: formatMessage({ id: 'type' }),
                FormKey: 'courseType',
                Description: null,
            },
            {
                EditorType: 'Dropdown',
                Value: '1',
                Setting: {
                    IsVisible: true,
                    DropdownOptions: [
                        {
                            Value: formatMessage({ id: 'oneTimeClass' }),
                            Id: '0',
                        },
                        {
                            Value: formatMessage({ id: 'followUpClass' }),
                            Id: '1',
                        }
                    ],
                    onchange(value) {
                        /**
                         * 如果设置为0
                         *      重复排课 消失
                         *      重复次数 消失
                         */

                        if (value == 0) {
                            const data = getDvaApp()._store.getState().classCard
                            const { content: stateContent } = data
                            const content = deepCopy(stateContent)
                            content.Properties[6].Setting.IsVisible = false
                            content.Properties[7].Setting.IsVisible = false

                            getDvaApp()._store.dispatch({
                                type: 'classCard/updateState',
                                payload: {
                                    content
                                }
                            })
                        } else {
                            const data = getDvaApp()._store.getState().classCard
                            const { modalType, content: stateContent } = data
                            const content = deepCopy(stateContent)
                            content.Properties[6].Setting.IsVisible = true
                            if (modalType === "add") {
                                content.Properties[7].Setting.IsVisible = true
                            }
                            getDvaApp()._store.dispatch({
                                type: 'classCard/updateState',
                                payload: {
                                    content
                                }
                            })
                        }
                    },
                    Required: true,
                },
                ShowTitle: formatMessage({ id: 'scheduleEditingRange' }),
                FormKey: 'editTakeEffect',
                Description: null,
            },
            {
                EditorType: 'Dropdown',
                Value: null,
                Setting: {
                    IsVisible: true,
                    DropdownOptions: [
                        {
                            Value: formatMessage({ id: 'never' }),
                            Id: '0',
                        },
                        {
                            Value: formatMessage({ id: 'everyday' }),
                            Id: '1',
                        },
                        {
                            Value: formatMessage({ id: 'oneWeek' }),
                            Id: '2',
                        },
                        {
                            Value: formatMessage({ id: 'twoWeeks' }),
                            Id: '3',
                        },
                        {
                            Value: formatMessage({ id: 'oneMonth' }),
                            Id: '4',
                        },
                    ],
                    onchange(value) {
                        /* 如果value为0设置
                                重复次数为0并且disabled
                        */
                        if (value == 0) {
                            const data = getDvaApp()._store.getState().classCard
                            const { content: stateContent } = data
                            const content = deepCopy(stateContent)
                            content.Properties[7].Setting.IsVisible = false
                            getDvaApp()._store.dispatch({
                                type: 'classCard/updateState',
                                payload: {
                                    content
                                }
                            })
                        } else {
                            // contentMenu.Properties[7].Setting.Disabled = false
                            // contentMenu.Properties[7].Setting.NumberSetting.min = 1
                            // window.myFormModalRef.current.setFieldsValue({
                            //     courseCount: 1
                            // })
                            const data = getDvaApp()._store.getState().classCard
                            const { modalType, content: stateContent } = data
                            const content = deepCopy(stateContent)
                            if (modalType === "add") {
                                content.Properties[7].Setting.IsVisible = true
                            }
                            getDvaApp()._store.dispatch({
                                type: 'classCard/updateState',
                                payload: {
                                    content
                                }
                            })
                        }
                    },
                    Required: true,
                },
                ShowTitle: formatMessage({ id: 'repeat' }),
                FormKey: 'interval',
                Description: null,
            },
            {
                EditorType: 'NumberInput',
                ShowTitle: formatMessage({ id: 'numberOfRepetitions' }),
                FormKey: 'courseCount',
                AdditionalData: null,
                Value: 1,
                Setting: {
                    IsVisible: true,
                    NumberSetting: {
                        min: 1,
                        step: 1,
                    },
                    Required: true,
                },
                Description: formatMessage({ id: 'enterAnIntegerGreater' }),
            },
            {
                EditorType: 'CheckboxSelector',
                Value: ['parentSMS', 'trainerSMS'],
                Setting: {
                    DropdownOptions: [
                        {
                            Value: formatMessage({ id: 'trainer' }),
                            Id: 'trainerSMS'
                        },
                        {
                            Value: formatMessage({ id: 'guardian' }),
                            Id: 'parentSMS'
                        }
                    ],
                    Required: false,
                },
                ShowTitle: formatMessage({ id: 'Schedule Reminder' }),
                FormKey: 'SMSReminder',
                Description: formatMessage({ id: 'reminderDes' }),
            },
            {
                EditorType: 'Bool',
                Value: true,
                Setting: {},
                ShowTitle: formatMessage({ id: 'Guardians Punch Reminder' }),
                FormKey: 'parentAppNotice',
                Description: '',
            }
        ]
    }
}


// 删除重复安排的弹框
const deleteCourseContentMenu = (formatMessage) => {
    return {
        Properties: [
            {
                EditorType: 'Dropdown',
                Value: null,
                Setting: {
                    DropdownOptions: [
                        {
                            Value: formatMessage({ id: 'thisSchedule' }),
                            Id: 'current',
                        },
                        {
                            Value: formatMessage({ id: 'subsequentSchedules' }),
                            Id: 'currentAndAfter',
                        },
                        {
                            Value: formatMessage({ id: 'allSchedules' }),
                            Id: 'all'
                        }
                    ],
                    Required: true,
                },
                ShowTitle: formatMessage({ id: 'selectDeletionType' }),
                FormKey: 'deleteType',
                Description: null,
            }
        ]
    }
}


// 根据需要展示的字段显示不同的Form
const getCopycontentByKey = (contentMenu, key) => {
    contentMenu.Properties.forEach(item => {
        if (key.includes(item.FormKey)) {
            item.Setting.IsVisible = true
        } else {
            item.Setting.IsVisible = false
        }
    })
    return contentMenu
}

export default {
    namespace: 'classCard',

    state: {
        currentDate: moment(),
        // type为本人时的开始时间与结束时间
        startDate: moment().add(-1, 'days'),
        endDate: moment().add(5, 'days'),
        // type为本人时的选择日期
        selfChangeDate: moment(),
        // type为本中心时的选择日期,
        centerChangeDate: moment(),
        content: null,
        editArrangeVisible: false,
        isShowHoverInfo: false,
        currentSelectTenantId: null,
        currentSelectTrainerId: null,
        hoverDataInfo: null,
        top: 0,
        left: 0,
        showDrawer: false,
        type: "oneself",
        // 当前类型被安排的课程数据
        courseData: null,
        // 当前弹框类型
        modalType: '',
        // 训练师列表
        trainerList: [],
        // 当前数据
        data: [],
        // 当前选择的日历
        selectCalendar: null,
        // 删除的弹框内容
        deleteContent: null,
        // 本人好课数
        oneselfCourseNumData: null,
        // 中心耗课数
        centerCourseNumData: null,
        // 切换训练师的下标
        changeTrainerIndex: 0,
        // 当前训练师数组
        currentTrainerArr: [],
        // 原始数据
        preData: [],
        // 是否显示过去可是
        isShowPreData: false
    },

    reducers: {
        updateState(state, { payload }) {
            return {
                ...state,
                ...payload,
            }
        },
    },

    effects: {
        *query({ payload }, { call, put, select }) {
            yield put({
                type: 'updateState',
                payload: {
                    deleteContent: deleteCourseContentMenu(IntlManager.intl.formatMessage)
                }
            })

            let params = {}
            let queryRes = null
            const { currentUser } = yield select(state => state.user)
            const { userAllTenant, changedTenant } = currentUser || {}
            yield put({ type: 'updateState', payload: { isShowHoverInfo: false } })
            const { type, startDate, endDate } = yield select(state => state.classCard);
            const tableHeadDOM = document.getElementsByClassName("ant-table-thead")[0]
            if (type === "oneself") {
                tableHeadDOM && (tableHeadDOM.style.top = "0")
                queryRes = yield call(GetArrangeCourse, { StartTime: getFormatDate(startDate, true), EndTime: getFormatDate(endDate, false) })

                // 获取本人耗课数
                yield put({
                    type: 'getTenantCourseList'
                })

            } else {
                tableHeadDOM && (tableHeadDOM.style.top = "64px")
                // 获取当前中心训练师
                yield put({
                    type: "GetTrainerList",
                })
            }
            if (queryRes && queryRes.response.ok) {
                yield put({
                    type: 'updateStateData',
                    payload: queryRes.data
                })
                yield put({
                    type: 'updateState',
                    payload: {
                        preData: queryRes.data
                    }
                })
            }
        },

        *getTenantCourseList({ payload }, { call, put, select }) {
            const { currentUser } = yield select(state => state.user)
            const { userAllTenant, changedTenant } = currentUser || {}
            const tenantIds = userAllTenant.filter(item => ["CenterAdmin", "Coach", "SeniorCoach", "Senior Coach"].includes(item.roles && item.roles[0] && item.roles[0].name)).map(item => item.id)
            const getTenantListRes = yield call(GetTeanntCourseCoseList,)
            if (getTenantListRes.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: {
                        oneselfCourseNumData: getTenantListRes.data
                    }
                })
            }
        },

        // 添加课程
        *addCourse({ payload }, { call, put, select }) {

            const _arrangeCourseContent = arrangeCourseContent(IntlManager.intl.formatMessage)

            yield put({ type: 'updateState', payload: { isShowHoverInfo: false } })
            const { currentUser } = yield select(state => state.user)
            const { userAllTenant, changedTenant } = currentUser || {}
            const { type, centerChangeDate, trainerList } = yield select(state => state.classCard)
            const newUserAllTenant = userAllTenant.filter(item => ["CenterAdmin", "Coach", "IndependentCoach", "SeniorCoach", "Senior Coach"].includes(item.roles && item.roles[0] && item.roles[0].name))
            const { col, time, intl } = payload || {}
            // 本人
            if (type === "oneself") {
                const startTime = `${col} ${time}`

                // 添加判断：如果结束时间小于当前课时，则不给添加
                if (moment(startTime).isSameOrBefore(moment())) return
                // 根据需要展示的字段重新生成content
                const copycontentMenu = getCopycontentByKey(contentMenu(IntlManager.intl.formatMessage), oneselfCreateKey)
                // 开始上课时间
                copycontentMenu.Properties[0].Value = payload ? startTime : moment().add(1, 'hours').format('YYYY/MM/DD HH:00')

                // 获取中心下拉框并且默认当前中心
                copycontentMenu.Properties[1].Setting.DropdownOptions = newUserAllTenant.map((item, index) => {
                    return {
                        Value: item.displayName,
                        Id: item.id
                    }
                })
                copycontentMenu.Properties[1].Value = changedTenant.id

                // 清除儿童信息
                copycontentMenu.Properties[3].Value = ""
                copycontentMenu.Properties[3].Setting.Disabled = false

                // 清空方式
                copycontentMenu.Properties[4].Value = ""

                // 重复排课内容
                copycontentMenu.Properties[6].Setting.DropdownOptions = _arrangeCourseContent.add
                copycontentMenu.Properties[6].Value = null

                // 重复次数
                copycontentMenu.Properties[7].Setting.IsVisible = true
                copycontentMenu.Properties[7].Value = 1

                // 短信提醒
                copycontentMenu.Properties[8].Value = ['parentSMS', 'trainerSMS']

                yield put({
                    type: 'updateState',
                    payload: {
                        currentSelectTenantId: changedTenant.id,
                        content: copycontentMenu,
                        modalType: 'add',
                        editArrangeVisible: true
                    }
                })

                if (newUserAllTenant && newUserAllTenant.length > 0) {
                    yield put({
                        type: 'SearchSubjectListAndUpdateForm'
                    })
                }
            } else {
                const startTime = `${moment(centerChangeDate).format("YYYY/MM/DD")} ${time}`

                // 添加判断：如果结束时间小于当前课时，则不给添加
                if (moment(startTime).isSameOrBefore(moment())) return

                const copycontentMenu = getCopycontentByKey(contentMenu(IntlManager.intl.formatMessage), centerCreateKey)

                // 开始上课时间
                copycontentMenu.Properties[0].Value = payload ? startTime : moment().add(1, 'hours').format('YYYY/MM/DD HH:00')
                copycontentMenu.Properties[2].Setting.DropdownOptions = trainerList.map(item => {
                    return {
                        Value: item.name,
                        Id: item.id
                    }
                })

                // 获取训练师下拉框并且默认第一个
                if (trainerList && trainerList.length > 0) copycontentMenu.Properties[2].Value = col

                // 清除儿童信息
                copycontentMenu.Properties[3].Value = ""
                copycontentMenu.Properties[3].Setting.Disabled = false

                // 清空方式
                copycontentMenu.Properties[4].Value = ""

                // 重复排课内容
                copycontentMenu.Properties[6].Setting.DropdownOptions = _arrangeCourseContent.add
                copycontentMenu.Properties[6].Value = null

                // 重复次数
                copycontentMenu.Properties[7].Setting.IsVisible = true
                copycontentMenu.Properties[7].Value = 1

                // 短信提醒
                copycontentMenu.Properties[8].Value = ['parentSMS', 'trainerSMS']

                yield put({
                    type: "updateState",
                    payload: {
                        currentSelectTrainerId: col,
                        content: copycontentMenu,
                        modalType: 'add',
                        editArrangeVisible: true,
                    }
                })

                if (trainerList && trainerList.length > 0) {
                    yield put({
                        type: "SearchSubjectListAndUpdateFormByTrainer"
                    })
                }
            }
        },

        // 获取当前中心训练师
        *GetTrainerList({ payload }, { call, put, select }) {
            const res = yield call(
                PostUserList,
                {
                    IsActive: true,
                    State: 'Effective',
                    Roles: ['Coach', 'CenterAdmin', 'SeniorCoach', 'Senior Coach'],
                    MaxResultCount: 1000
                }
            )

            if (res.response.ok) {
                const data = res.data.items
                yield put({
                    type: 'updateState',
                    payload: {
                        trainerList: data,
                        data: []
                    }
                })
                if (data && data.length > 0) {
                    // 根据训练师获取本中心课程表内容
                    yield put({
                        type: 'getCourseByCoachId',
                        // payload: data
                    })
                    // 根据训练师获取耗课数
                    yield put({
                        type: 'getCoachCourseCostList',
                        payload: data
                    })
                }
            }
        },

        *getCoachCourseCostList({ payload }, { call, put, select }) {
            const coachIds = payload.map(item => item.id)
            const getCoachCourseCostListRes = yield call(GetCoachCourseCostList, { coachIds })
            if (getCoachCourseCostListRes.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: {
                        centerCourseNumData: getCoachCourseCostListRes.data
                    }
                })
            }
        },

        *getCourseByCoachId({ payload }, { call, put, select }) {
            const { centerChangeDate, trainerList, changeTrainerIndex } = yield select(state => state.classCard)
            const currentTrainerArr = trainerList && trainerList.slice(changeTrainerIndex * 7, (changeTrainerIndex + 1) * 7)
            const coachIds = currentTrainerArr.map(item => item.id)
            const { currentUser } = yield select(state => state.user)
            const { changedTenant } = currentUser || {}
            const { id } = changedTenant || {}
            const params = {
                StartTime: getFormatDate(centerChangeDate, true),
                EndTime: getFormatDate(centerChangeDate, false),
                coachIds,
                tenantId: id
            }
            const getCourseRes = yield call(GetArrangeCourse, params)
            if (getCourseRes.response.ok) {
                yield put({
                    type: 'updateState',
                    payload: {
                        currentTrainerArr,
                        preData: getCourseRes.data
                    }
                })
                yield put({
                    type: "updateStateData",
                    payload: getCourseRes.data
                })
            }
        },

        *changeTrain({ payload }, { call, put, select }) {
            const { changeTrainerIndex } = yield select(state => state.classCard)
            const { type } = payload || {}
            yield put({
                type: 'updateState',
                payload: {
                    changeTrainerIndex: type === "left" ? changeTrainerIndex - 1 : changeTrainerIndex + 1
                }
            })
            yield put({
                type: 'getCourseByCoachId'
            })
        },

        *ChangeTenant({ payload }, { call, put, select }) {
            const { content, currentSelectTenantId } = yield select(state => state.classCard)
            if (payload !== currentSelectTenantId) {
                window.myFormModalRef.current.setFieldsValue({
                    subjectId: ''
                })
                yield put({
                    type: 'updateState',
                    payload: {
                        currentSelectTenantId: payload,
                        content
                    }
                })
                yield put({
                    type: 'SearchSubjectListAndUpdateForm'
                })
            }
        },

        *ChangeTrainer({ payload }, { call, put, select }) {
            const { currentSelectTrainerId } = yield select(state => state.classCard)
            if (payload !== currentSelectTrainerId) {
                window.myFormModalRef.current.setFieldsValue({
                    subjectId: ''
                })
                yield put({
                    type: 'updateState',
                    payload: {
                        currentSelectTrainerId: payload
                    }
                })
                yield put({
                    type: 'SearchSubjectListAndUpdateFormByTrainer'
                })
            }
        },

        // 修改中心联动儿童
        *SearchSubjectListAndUpdateForm({ payload }, { call, put, select }) {
            const myIntl = IntlManager.MyIntl()

            const { currentSelectTenantId } = yield select(state => state.classCard)
            const params = {
                TenantId: currentSelectTenantId,
                MaxResultCount: 1000,
                ...payload
            }
            const res = yield call(GetSubjectSimpleList, params, currentSelectTenantId && { 't-select': currentSelectTenantId })
            if (res.response.ok) {
                let { content: stateContent } = yield select(state => state.classCard)
                // let content = JSON.parse(JSON.stringify(stateContent))
                // let content = { ...stateContent }
                let content = deepCopy(stateContent)

                const subjectList = res.data.items
                content.Properties[3].Setting.DropdownOptions = subjectList.map(item => {
                    return {
                        Value: item.name,
                        Id: item.id
                    }
                })

                yield put({
                    type: 'updateState',
                    payload: { content }
                })
            }
            else {
                message.error(myIntl("getFailedChildInfo"))
            }
        },

        // 修改训练师联动儿童
        *SearchSubjectListAndUpdateFormByTrainer({ payload }, { call, put, select }) {
            const myIntl = IntlManager.MyIntl()

            const { currentSelectTrainerId } = yield select(state => state.classCard)
            if (!currentSelectTrainerId) return
            const params = {
                UserId: currentSelectTrainerId,
                MaxResultCount: 1000,
                ...payload
            }
            const res = yield call(GetSubjectSimpleList, params)
            if (res.response.ok) {
                const { content: stateContent } = yield select(state => state.classCard)
                // let content = { ...stateContent }
                let content = deepCopy(stateContent)
                const subjectList = res.data.items
                content.Properties[3].Setting.DropdownOptions = subjectList.map(item => {
                    return {
                        Value: item.name || "",
                        Id: item.id
                    }
                })
                yield put({
                    type: 'updateState',
                    payload: { content }
                })
            }
            else {
                message.error(myIntl("getFailedChildInfo"))
            }
        },

        *editCourse({ payload: { intl } }, { call, put, select }) {
            const _arrangeCourseContent = arrangeCourseContent(IntlManager.intl.formatMessage)

            const { hoverDataInfo, type, centerChangeDate, trainerList, selectCalendar } = yield select(state => state.classCard)
            const { currentUser } = yield select(state => state.user)
            const { userAllTenant, changedTenant } = currentUser || {}
            const {
                calendarCount,
                calendarCourseType,
                startTime,
                tenantInfo,
                periodIntervalNumber,
                periodIntervalUnit,
                students,
                currentItemStartTime,
                currentItemEndTime,
                parentSMS,
                trainerSMS,
                parentBeforeHours,
                executorBeforeHours,
                userTrainingPlanId,
                parentAppNotice
            } = hoverDataInfo || {}
            const { currentIndex, totalCount, executor } = selectCalendar || {}
            // 弹框类型
            let modalType = ""
            // 弹框展示的内容
            let copycontentMenu = null
            // 当前需要展示字段的key
            let contentKey = ''
            // 当前课程类型[重复还是不重复]
            let courseType = calendarCount > 0 ? "repetitive" : "NonRepetitive"

            if (type === "oneself") {
                if (courseType === "NonRepetitive") {
                    modalType = 'nonRepetitive'
                    contentKey = oneselfNonRepetitiveCreateKey
                } else {
                    modalType = 'repetitive'
                    contentKey = oneselfRepetitiveCreateKey
                }
                copycontentMenu = getCopycontentByKey(contentMenu(IntlManager.intl.formatMessage), contentKey)
                // 设置开始上课时间
                copycontentMenu.Properties[0].Value = currentItemStartTime
                // 设置中心数据与默认值
                copycontentMenu.Properties[1].Setting.DropdownOptions = userAllTenant.map((item, index) => {
                    return {
                        Value: item.displayName,
                        Id: item.id
                    }
                })
                copycontentMenu.Properties[1].Value = tenantInfo && tenantInfo.id

                // 获取儿童
                copycontentMenu.Properties[3].Value = students && students[0] && students[0].id
                if (userTrainingPlanId) {
                    copycontentMenu.Properties[2].Setting.Disabled = true
                    copycontentMenu.Properties[3].Setting.Disabled = true
                } else {
                    copycontentMenu.Properties[3].Setting.Disabled = false
                }
                // 设置线上线下
                copycontentMenu.Properties[4].Setting.Disabled = false
                copycontentMenu.Properties[4].Value = calendarCourseType

                // 设置重复排课
                if (courseType === "NonRepetitive") {
                    copycontentMenu.Properties[6].Value = '0'
                } else {
                    // 设置重复排课
                    copycontentMenu.Properties[6].Value = getRepetitiveStyle(periodIntervalNumber, periodIntervalUnit)
                }

                // 设置重复次数
                copycontentMenu.Properties[7].Value = calendarCount

                copycontentMenu.Properties[9].Value = parentAppNotice

                yield put({
                    type: "updateState",
                    payload: {
                        currentSelectTenantId: tenantInfo && tenantInfo.id
                    }
                })

                yield put({
                    type: 'SearchSubjectListAndUpdateForm'
                })
            } else {
                if (courseType === "NonRepetitive") {
                    modalType = 'nonRepetitive'
                    contentKey = centerNonRepetitiveCreateKey
                } else {
                    modalType = 'repetitive'
                    contentKey = centerRepetitiveCreateKey
                }
                copycontentMenu = getCopycontentByKey(contentMenu(IntlManager.intl.formatMessage), contentKey)
                // 设置开始上课时间
                copycontentMenu.Properties[0].Value = currentItemStartTime
                // 设置训练师数据
                copycontentMenu.Properties[2].Setting.DropdownOptions = trainerList.map(item => {
                    return {
                        Value: item.name,
                        Id: item.id
                    }
                })
                copycontentMenu.Properties[2].Value = executor && executor.id
                // 设置儿童数据
                copycontentMenu.Properties[3].Value = students && students[0] && students[0].id
                if (userTrainingPlanId) {
                    copycontentMenu.Properties[2].Setting.Disabled = true
                    copycontentMenu.Properties[3].Setting.Disabled = true
                } else {
                    copycontentMenu.Properties[3].Setting.Disabled = false
                }
                // 设置线上线下
                copycontentMenu.Properties[4].Setting.Disabled = false
                copycontentMenu.Properties[4].Value = calendarCourseType

                // 设置重复排课
                if (courseType === "NonRepetitive") {
                    copycontentMenu.Properties[6].Value = '0'
                } else {
                    // 设置重复排课
                    copycontentMenu.Properties[6].Value = getRepetitiveStyle(periodIntervalNumber, periodIntervalUnit)
                }

                yield put({
                    type: "updateState",
                    payload: {
                        currentSelectTrainerId: executor && executor.id
                    }
                })

                yield put({
                    type: 'SearchSubjectListAndUpdateFormByTrainer'
                })
            }

            // 如果编辑的是不重复或者是最后一个课时，就把 编辑生效 重复排课 重复次数 隐藏
            if (calendarCount === 0 || (currentIndex + 1 === totalCount)) {
                copycontentMenu.Properties[5].Setting.IsVisible = true
                copycontentMenu.Properties[5].Value = '0'
                copycontentMenu.Properties[5].Setting.DropdownOptions = [
                    {
                        Value: intl.formatMessage({ id: 'oneTimeClass' }),
                        Id: '0',
                    },
                    {
                        Value: intl.formatMessage({ id: 'followUpClass' }),
                        Id: '1',
                        Disabled: true
                    }
                ]
                copycontentMenu.Properties[6].Setting.IsVisible = false
            } else {
                copycontentMenu.Properties[5].Setting.IsVisible = true
                copycontentMenu.Properties[5].Setting.DropdownOptions = [
                    {
                        Value: intl.formatMessage({ id: 'oneTimeClass' }),
                        Id: '0',
                    },
                    {
                        Value: intl.formatMessage({ id: 'followUpClass' }),
                        Id: '1',
                        Disabled: false
                    }
                ]
                copycontentMenu.Properties[6].Setting.IsVisible = true
            }

            // 重复排课内容
            copycontentMenu.Properties[6].Setting.DropdownOptions = _arrangeCourseContent.eidt

            // 短信提醒
            copycontentMenu.Properties[8].Value = [parentBeforeHours !== null ? 'parentSMS' : null, executorBeforeHours !== null ? 'trainerSMS' : null]

            yield put({
                type: "updateState",
                payload: {
                    content: copycontentMenu,
                    editArrangeVisible: true,
                    modalType,
                    isShowHoverInfo: false
                }
            })
        },

        *deleteCourse({ payload: { deleteCourseParams, intl } }, { call, put, select }) {
            const { selectCalendar } = yield select(state => state.classCard)
            const { tenantInfo } = selectCalendar || {}
            const { id: tenantId } = tenantInfo || {}
            const deleteRes = yield call(DeleteCalendar, deleteCourseParams, { 't-select': tenantId })
            if (deleteRes.response.ok) {
                message.success(intl.formatMessage({ id: 'deletedSuccess' }))
                yield put({
                    type: "updateState",
                    payload: {
                        isShowHoverInfo: false,
                        deleteModalVisible: false
                    }
                })
                yield put({ type: "query" })
            }
        },

        *onDelete({ payload: { intl } }, { call, put, select }) {
            const myIntl = IntlManager.MyIntl()
            const { hoverDataInfo } = yield select(state => state.classCard)
            const { id, currentItemIndex, calendarCount } = hoverDataInfo || {}
            let deleteCourseParams = {}
            if (calendarCount > 0) {
                yield put({
                    type: 'updateState',
                    payload: {
                        deleteModalVisible: true
                    }
                })
                return
            } else {
                deleteCourseParams = { id }
            }
            Modal.confirm({
                title: <span style={{ color: 'red' }}>{myIntl("sureDataCleared")}</span>,
                okText: myIntl("submit"),
                cancelText: myIntl("cancel"),
                onOk() {
                    getDvaApp()._store.dispatch({
                        type: 'classCard/deleteCourse',
                        payload: {
                            deleteCourseParams,
                            intl
                        }
                    })
                }
            })
        },

        *onDeleteModal({ payload: { value, intl } }, { call, put, select }) {
            const myIntl = IntlManager.MyIntl()
            const { hoverDataInfo } = yield select(state => state.classCard)
            const { id, currentItemIndex, calendarCount } = hoverDataInfo || {}
            const { deleteType } = value || {}
            let deleteCourseParams = {}
            switch (deleteType) {
                case "current":
                    deleteCourseParams = { id, Index: currentItemIndex, IsDeleteCurrent: true }
                    break
                case "currentAndAfter":
                    deleteCourseParams = { id, Index: currentItemIndex, IsDeleteCurrent: false }
                    break
                case "all":
                    deleteCourseParams = { id }
                    break
            }
            Modal.confirm({
                title: <span style={{ color: 'red' }}>{myIntl("sureDataCleared")}</span>,
                okText: myIntl("submit"),
                cancelText: myIntl("cancel"),
                onOk() {
                    getDvaApp()._store.dispatch({
                        type: 'classCard/deleteCourse',
                        payload: {
                            deleteCourseParams,
                            intl
                        }
                    })
                }
            })
        },

        *onClosed({ payload }, { call, put, select }) {
            yield put({
                type: "updateState",
                payload: {
                    isShowHoverInfo: false
                }
            })
        },

        *showCourses({ payload }, { call, put, select }) {
            yield put({ type: "updateState", payload: { showDrawer: true } })
        },

        *onchangeTab({ payload }, { call, put, select }) {
            yield put({ type: "updateState", payload: { type: payload } })
            yield put({ type: "query" })
        },

        *changeDate({ payload }, { call, put, select }) {
            let { type, currentDate, startDate, endDate } = yield select(state => state.classCard)
            if (type === "oneself") {
                if (moment(payload).isSameOrAfter(startDate) && moment(payload).isSameOrBefore(endDate)) {
                    yield put({
                        type: 'updateState',
                        payload: {
                            selfChangeDate: payload
                        }
                    })
                    return;
                } else {
                    startDate = moment(payload).add(-1, 'days');
                    endDate = moment(payload).add(5, 'days');
                    yield put({
                        type: 'updateState',
                        payload: {
                            startDate,
                            endDate,
                            selfChangeDate: payload
                        }
                    })
                    yield put({
                        type: 'query'
                    })
                }
            } else {
                yield put({
                    type: 'updateState',
                    payload: {
                        centerChangeDate: payload
                    }
                })
                yield put({
                    type: 'query'
                })
            }
        },

        *transDate({ payload }, { call, put, select }) {
            let { type, startDate, endDate, selfChangeDate, centerChangeDate } = yield select(state => state.classCard)
            if (type === "oneself") {
                if (payload === "add") {
                    startDate = moment(endDate).add(1, 'days');
                    endDate = moment(startDate).add(6, 'days');
                } else {
                    endDate = moment(startDate).add(-1, 'days');
                    startDate = moment(endDate).add(-6, 'days');
                }
                selfChangeDate = moment(startDate).add(1, 'days');
                yield put({
                    type: "updateState",
                    payload: {
                        startDate,
                        endDate,
                        selfChangeDate,
                    }
                })
                yield put({
                    type: "query"
                })
            } else {
                centerChangeDate = moment(centerChangeDate)[payload]('days', 1);
                yield put({
                    type: "updateState",
                    payload: {
                        centerChangeDate
                    }
                })
                yield put({
                    type: 'query'
                })
            }
        },

        *changeDateToToday({ payload }, { call, put, select }) {
            const { type } = yield select(state => state.classCard)
            if (type === "oneself") {
                yield put({
                    type: 'updateState',
                    payload: {
                        startDate: moment().add(-1, 'days'),
                        endDate: moment().add(5, 'days'),
                        selfChangeDate: moment()
                    }
                })
                yield put({
                    type: 'query'
                })
            } else {
                yield put({
                    type: 'updateState',
                    payload: {
                        centerChangeDate: moment()
                    }
                })
                yield put({
                    type: 'query'
                })
            }
        },

        *submitCourse({ payload: { value, intl } }, { call, put, select }) {
            const { interval, SMSReminder, ...data } = value
            const { startTime } = data || {}
            const { periodIntervalUnit, periodIntervalNumber } = getTimeSpan(interval) || {}
            const calendarEventType = 'Training'
            const { currentSelectTenantId, modalType, selectCalendar, type } = yield select(state => state.classCard)
            const { currentUser } = yield select(state => state.user)
            const { changedTenant } = currentUser || {}
            const { id } = changedTenant || {}
            const endTime = moment(startTime).add(1, 'hours').format()
            if (moment(startTime).isSameOrBefore(moment())) {
                message.error(intl.formatMessage({ id: 'startMoreEnd' }))
                return
            }
            if (modalType === "add") {
                const t_select = type === "oneself" ? currentSelectTenantId : id
                const postData = {
                    periodIntervalUnit,
                    periodIntervalNumber,
                    calendarEventType,
                    endTime,
                    executorId: data.trainer,
                    parentBeforeHours: SMSReminder.includes("parentSMS") ? 24 : null,
                    executorBeforeHours: SMSReminder.includes("trainerSMS") ? 24 : null,
                    parentSMS: SMSReminder.includes("parentSMS"),
                    trainerSMS: SMSReminder.includes("trainerSMS"),
                    ...data
                }
                const submitCourseRes = yield call(PostArrangeCourse, postData, { 't-select': t_select })
                if (submitCourseRes.response.ok) {
                    yield put({
                        type: "query"
                    })
                    yield put({
                        type: "updateState",
                        payload: {
                            editArrangeVisible: false
                        }
                    })
                    message.success(intl.formatMessage({ id: 'success' }))
                } else {
                    const data = submitCourseRes.data
                    const { error } = data || {}
                    error && message.error(error.message)
                }
            } else {
                const postData = {
                    id: selectCalendar.userPlanId,
                    periodIntervalUnit,
                    periodIntervalNumber,
                    calendarEventType,
                    endTime,
                    currentIndex: selectCalendar.currentIndex,
                    parentBeforeHours: SMSReminder.includes("parentSMS") ? 24 : null,
                    executorBeforeHours: SMSReminder.includes("trainerSMS") ? 24 : null,
                    parentSMS: SMSReminder.includes("parentSMS"),
                    trainerSMS: SMSReminder.includes("trainerSMS"),
                    executorId: data.trainer,
                    // ...selectCalendar,
                    ...data,
                }
                const putCourseRes = yield call(PutCalendar, postData, currentSelectTenantId && { 't-select': currentSelectTenantId })
                if (putCourseRes.response.ok) {
                    yield put({
                        type: "query"
                    })
                    yield put({
                        type: "updateState",
                        payload: {
                            editArrangeVisible: false
                        }
                    })
                    message.success(intl.formatMessage({ id: 'success' }))
                } else {
                    const data = putCourseRes.data
                    const { error } = data || {}
                    error && message.error(error.message)
                }
            }
        },

        *getPlanDetailInfo({ payload }, { call, put, select }) {
            const { top, left, dataInfo, selectCalendar } = payload
            const { tenantInfo } = selectCalendar || {}
            const { id: tenantId } = tenantInfo || {}
            const { item } = dataInfo || {}
            const { userPlanId, currentIndex, isRealItem } = item || {}
            if (isRealItem) {
                yield put({
                    type: 'updateState',
                    payload: {
                        hoverDataInfo: item,
                        isShowHoverInfo: true,
                        top,
                        left,
                        selectCalendar
                    }
                })
            } else {
                const getPlanDetailInfoRes = yield call(GetPlanDetailInfo, { id: userPlanId, index: currentIndex }, { 't-select': tenantId })
                if (getPlanDetailInfoRes.response.ok) {
                    yield put({
                        type: 'updateState',
                        payload: {
                            hoverDataInfo: getPlanDetailInfoRes.data,
                            isShowHoverInfo: true,
                            top,
                            left,
                            selectCalendar
                        }
                    })
                } else {
                    const data = getPlanDetailInfoRes.data
                    const { error } = data || {}
                    error && message.error(error.message)
                }
            }
        },

        *updateStateData({ payload }, { call, put, select }) {
            const { data, preData, isShowPreData } = yield select(state => state.classCard)
            const filterData = payload || data
            const newData = filterData.filter(item => {
                const { isRealItem, startTime, endTime } = item || {}
                if (isRealItem) return true
                else {
                    let _endTime;
                    if (moment(startTime).isSame(moment(endTime))) {
                        _endTime = moment(startTime).add(1, 'hours')
                    } else {
                        _endTime = moment(endTime)
                    }
                    if (_endTime.isBefore(moment())) return false
                    return true
                }
            })
            let resData = isShowPreData ? preData : newData

            // 判断是否是整点
            resData = resData.map(item => {
                const { startTime, endTime, backendStartTime, backendEndTime } = item || {}
                const minutes = moment(startTime).get('minutes')
                if (minutes % 5 !== 0) {
                    let _startTime;
                    let _endTime;
                    if (moment(startTime).isSame(moment(endTime))) {
                        _endTime = moment(startTime).add(1, 'hours')
                    } else {
                        _endTime = moment(endTime)
                    }
                    _startTime = moment(startTime).set('minutes', minutes - minutes % 5).format()
                    return {
                        ...item,
                        startTime: _startTime,
                        endTime: _endTime,
                        backendStartTime: moment(backendStartTime || startTime).local().format(),
				        backendEndTime: moment(backendEndTime || endTime).local().format(),
                    }
                }
                return {
                    ...item,
                    backendStartTime: moment(backendStartTime || startTime).local().format(),
				    backendEndTime: moment(backendEndTime || endTime).local().format(),
                }
            })

            yield put({
                type: 'updateState',
                payload: {
                    currentDate: moment(),
                    data: resData
                }
            })
        },

        *changeRoute({ payload }, { call, put, select }) {
            const { selectCalendar } = yield select(state => state.classCard)
            const { tenantInfo } = selectCalendar || {}
            const { id } = tenantInfo || {}
            const { url } = payload || {}
            changeTenant("")
            changeUserTenant(id)
            yield put({
                type: 'user/updateState',
                payload: {
                    currentUser: null,
                },
            })
            window.location.href = url
            window.location.reload()
        },

        *showPreData({ payload }, { call, put, select }) {
            yield put({ type: 'updateState', payload: { isShowPreData: payload } })
            yield put({ type: 'updateStateData' })
        }
    },

    subscriptions: {
        // setup({ dispatch, history }) {
        //     return history.listen(async ({ pathname, query }) => {
        //         if (pathname === '/system/center/detail/classCardDetail') {
        //             const user = getDvaApp()._store.getState().user
        //             const { currentUser } = user || {}
        //             const { auth } = currentUser || {}
        //             const { grantedPolicies } = auth || {}
        //             const hasReadOneselfPermission = grantedPolicies["Business.Calendar.Read"]
        //             const hasReadOtherPermission = grantedPolicies["Business.Calendar.ReadOther"]
        //             if (hasReadOneselfPermission || hasReadOtherPermission) {
        //                 if (hasReadOneselfPermission) {
        //                     await dispatch({ type: 'updateState', payload: { content: null, type: 'oneself' } })
        //                 } else if (hasReadOtherPermission) {
        //                     await dispatch({ type: 'updateState', payload: { content: null, type: 'center' } })
        //                 }
        //                 dispatch({ type: "query" })
        //             }
        //         }
        //     })
        // },
    },
}

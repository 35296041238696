import React, { useEffect } from 'react'
import { connect, history, useIntl } from 'umi'
import { CaretLeftOutlined } from '@ant-design/icons'
import { Spin, Form, Row, Col, Card, Button } from 'antd'
import TabList from './TabList'
import SubjectInfo from '../../../Center/Detail/SubjectDetail/SubjectInfo'
import { SHOWTYPE } from './models/subjectDetail'
import AssessmentInfo from './Assessment'
import TrialSession from './TrialSession'
import ExerciseInfo from './Exercise'
import OfflinePlanInfo from './OfflinePlan'
import SleepInfo from './SubjectSleep'
import NutritionInfo from './SubjectNutrition'
import styles from './index.less'
import VratInfo from './Vrat'

const SubjectDetail = ({ adminSubjectDetail, loading, dispatch, currentUser, location }) => {
  const intl = useIntl();
  const { adminRoles: [{name} = {}] = [] } = currentUser || {}
  useEffect(() => {
    dispatch({ type: 'adminSubjectDetail/initPage', payload: location.query })
  }, [])

  const { subjectInfo, currentTab, subjectResource } = adminSubjectDetail
  if (subjectInfo === null) return <Spin />

  const tabMenuList = [SHOWTYPE.RocketAssessment, SHOWTYPE.TrialSession, SHOWTYPE.VratTest, SHOWTYPE.Exercise, SHOWTYPE.Sleep, SHOWTYPE.Nutrition, SHOWTYPE.Ils]
  const agentMenuList = [SHOWTYPE.VratTest]
  const tabListProps = {
    currentTab,
    tabList: name === 'agent' ? agentMenuList : tabMenuList,
    onChangeTab(e) {
      dispatch({ type: 'adminSubjectDetail/changeTab', payload: e.key })
    },
  }

  const subjectInfoProps = {
    subjectInfo,
    subjectResource,
    hasReadUserQuestionnairePermission: true,
    isShowArchives: true,
    from: 'admin',
    currentUser,
    reloadPage: () => {
      dispatch({ type: 'adminSubjectDetail/initPage', payload: location.query })
    }
  }
  const showContent = currentTab => {
    switch (currentTab) {
      case SHOWTYPE.RocketAssessment:
        return <AssessmentInfo />
      case SHOWTYPE.TrialSession:
        return <TrialSession />
      case SHOWTYPE.VratTest:
        return <VratInfo />
      case SHOWTYPE.Ils:
        return <OfflinePlanInfo />
      case SHOWTYPE.Exercise:
        return <ExerciseInfo />
      case SHOWTYPE.Nutrition:
        return <NutritionInfo />
      case SHOWTYPE.Sleep:
        return <SleepInfo />
      default:
        return null
    }
  }

  const goBack = () => {
    history.goBack();
    dispatch({
      type: 'adminClockInRecord/updateState',
      payload: {
        isShowIlsRecord: false,
        isShowExerciseRecord: false
      }
    })
  }
  
  return (
    <div className={styles.subjectDetail}>
      <Row>
        <Col span={4}>
          <Button icon={<CaretLeftOutlined />} onClick={() => goBack()}>
            {intl.formatMessage({ id: 'back'})}
          </Button>
        </Col>
        <Col span={6}>
          <h1>{intl.formatMessage({ id: 'childDetail' })}</h1>
        </Col>
      </Row>
      <div className={styles.subjectInfo}>
        <SubjectInfo {...subjectInfoProps} />
      </div>
      <TabList {...tabListProps} />
      {showContent(currentTab)}
    </div>
  )
}

export default connect(({ adminSubjectDetail, loading, user}) => ({ adminSubjectDetail, loading,  currentUser: user.currentUser, }))(SubjectDetail)

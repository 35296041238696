import React from 'react';

const getCountImg = (count, showImg) => {
  return Array(count)
    .fill(0)
    .map((item, index) => {
      return <img key={index} src={showImg} width="10" />;
    });
};

const getNormalTr = (intl, title, des) =>{
  return (
    <tr>
      <td style={{ width: '10vw' }}>
        <span style={{ fontWeight: 'bold' }}>
          {intl.formatMessage({ id: title })}
        </span>
      </td>
      <td style={{ width: '90vw', textAlign: 'left' }}>
        {intl.formatMessage({ id: des })}
      </td>
    </tr>
  );
}

const getTr = (
  intl,
  title,
  oneDes,
  twoDes,
  threeDes,
  fourDes,
  fiveDes,
  showImg,
) => {
  return (
    <tr>
      <td style={{ width: '10vw' }}>
        <span style={{ fontWeight: 'bold' }}>
          {intl.formatMessage({ id: title })}
        </span>
      </td>
      <td style={{ width: '90vw', textAlign: 'left' }}>
        {getCountImg(1, showImg)}
        {intl.formatMessage({ id: oneDes })}
        {getCountImg(2, showImg)}
        {intl.formatMessage({ id: twoDes })}
        {getCountImg(3, showImg)}
        {intl.formatMessage({ id: threeDes })}
        {getCountImg(4, showImg)}
        {intl.formatMessage({ id: fourDes })}
        {getCountImg(5, showImg)}
        {intl.formatMessage({ id: fiveDes })}
      </td>
    </tr>
  );
};

const Comments = ({
  intl,
  feedrectangleImg,
  feeddiamondImg,
  feedcircularImg,
  feedpentagonalImg,
  feedtriangleImg,
}) => {
  return (
    <table
      border="1"
      style={{
        margin: '0 auto',
        textAlign: 'center',
        borderColor: '#c2c2c2',
        lineHeight: '30px',
      }}
    >
      {/* {
        getNormalTr(intl, 'cofidence', 'cofidenceDes')
      }
      {
        getNormalTr(intl, 'physicalStrength2', 'physicalStrength2Des')
      }
      {
        getNormalTr(intl, 'standardity', 'standardityDes')
      } */}
      {getTr(
        intl,
        'followInstructions',
        'FollowTheInstructionsOneDes',
        'FollowTheInstructionsTwoDes',
        'FollowTheInstructionsThreeDes',
        'FollowTheInstructionsFourDes',
        'FollowTheInstructionsFiveDes',
        feedcircularImg,
      )}
      {getTr(
        intl,
        'attention',
        'AttentionOneDes',
        'AttentionTwoDes',
        'AttentionThreeDes',
        'AttentionFourDes',
        'AttentionFiveDes',
        feedrectangleImg,
      )}
      {getTr(
        intl,
        'hyperactivity_impulsivity',
        'HyperactivityOneDes',
        'HyperactivityTwoDes',
        'HyperactivityThreeDes',
        'HyperactivityFourDes',
        'HyperactivityFiveDes',
        feeddiamondImg,
      )}
    </table>
  );
};

export default Comments;

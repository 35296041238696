import React, { useEffect } from 'react'
import { connect, history, useIntl } from 'umi'
import { Spin, Button, Modal, Drawer } from 'antd'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import TabList from './TabList'
import SubjectInfo from './SubjectInfo'
import AssessmentInfo from './AssessmentInfo'
import VratInfo from './VratInfo'
import TrialSession from './TrialSession'
import OfflinePlanInfo from './OfflinePlanInfo'
import Exercise from './Exercise'
import Sleep from './Sleep'
import Nutrition from './Nutrition'
import CBT from './CBT'
import ExercisePdt from './ExercisePdt'
import SleepPdt from './SleepPdt'
import NutritionPdt from './NutritionPdt'
import Emotion from './Emotion'
import ParentLearning from './ParentLearning'
import ParentEmotion from './ParentEmotion'
import { SHOWTYPE, ENTRY } from './models/subjectDetail'
import Question from 'components/Question'
import styles from '../detail.less'

const SubjectDetail = ({ subjectDetail, loading, dispatch, isShowGrowthText = true, isOpen, currentUser, location }) => {
  const { auth, changedTenant: { institutionType, medicalPublicWithName = false } } = currentUser
  const { grantedPolicies } = auth
  const intl = useIntl()
  const hasReadUserQuestionnairePermission = grantedPolicies['RocketSystem.UserQuestionnaire'] === true
  const hasWriteUserQuestionnairePermission = grantedPolicies["RocketSystem.UserQuestionnaire.Write"]

  const reloadPage = () => {
    if (isShowGrowthText) {
      const ENTRY = {
        Path: 'path',
        Drawer: 'drawer',
      }
      const payload = { ...location.query, entry: ENTRY.Path }
      dispatch({ type: 'subjectDetail/loadPage', payload: payload })
    }
  }

  useEffect(() => {
    reloadPage()
  }, [isShowGrowthText])

  const {
    subjectInfo,
    classAndMusicInfo,
    currentTab,
    list,
    pagination,
    entry,
    tabList,
    from,
    groupMusicIsOpen,
    editShow,
    content,
    parentInterviewDrawerVisible,
    parentInterviewQuestion,
    parentInterviewSubmitData,
    parentInterviewIsFininshed,
    reviewData,
    addContractModalVisible
  } = subjectDetail || {}

  const { questionnaireAndAnswer } = parentInterviewQuestion || {}
  const { questionnaire, answer } = questionnaireAndAnswer || {}
  const { jsonString } = questionnaire || {}

  const { answerContent } = parentInterviewSubmitData || {}

  const subjectInfoProps = {
    subjectInfo,
    currentUser,
    reviewData,
    classAndMusicInfo,
    addContractModalVisible,
    hasReadUserQuestionnairePermission,
    hasWriteUserQuestionnairePermission,
    from: 'center',
    institutionType,
    parentInterview() {
      dispatch({
        type: 'subjectDetail/showParentInterview'
      })
    },
    editNum() {
      dispatch({
        type: 'subjectDetail/editNum',
        payload: {
          subjectInfo
        }
      })
    },
    reloadPage,
    getFASReport: () => {
      dispatch({ type: 'subjectDetail/getFASReport' })
    },
    changeModalVisible: (type, visible) => {
      dispatch({ type: 'subjectDetail/updateState', payload: { [type]: visible } })
    }
  }

  const tabListProps = {
    currentTab,
    tabList: tabList,
    onChangeTab(e) {
      dispatch({ type: 'subjectDetail/changeTab', payload: e.key })
    },
  }

  const showContent = currentTab => {
    switch (currentTab) {
      case SHOWTYPE.RocketAssessment:
        return <AssessmentInfo />
      case SHOWTYPE.TrialSession:
        return <TrialSession />;
      case SHOWTYPE.VratTest:
        return <VratInfo />
      case SHOWTYPE.OfflinePlan:
        return <OfflinePlanInfo />
      case SHOWTYPE.Sleep:
        return <Sleep />
      case SHOWTYPE.Nutrition:
        return <Nutrition />
      case SHOWTYPE.Exercise:
        return <Exercise />
      case SHOWTYPE.Cbt:
        return <CBT />
      case SHOWTYPE.ExercisePdt:
        return <ExercisePdt />
      case SHOWTYPE.SleepPdt:
        return <SleepPdt />
      case SHOWTYPE.NutritionPDT:
        return <NutritionPdt />
      case SHOWTYPE.Emotion:
        return <Emotion />
      case SHOWTYPE.ParentLearning:
        return <ParentLearning />
      case SHOWTYPE.ParentEmotion:
        return <ParentEmotion />
      default:
        return null
    }
  }

  const onBack = () => {
    if (isOpen && !groupMusicIsOpen) {
      Modal.confirm({
        title: intl.formatMessage({ id: 'musicPlayingProceed' }),
        okText: intl.formatMessage({ id: 'leave' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: async () => {
          await dispatch({
            type: 'musicplayer/closeGlobalMusicPlayer',
          });
          history.goBack();
        },
        onCancel() {
        },
      });
    } else {
      history.goBack()
    }
  }

  const showBackBtn = isShowGrowthText => {
    return isShowGrowthText && from != 'notification' ? (
      <Button onClick={() => onBack()}>{intl.formatMessage({ id: 'back' })}</Button>
    ) : (
      <span></span>
    )
  }

  const onComplete = ({ answerContent, isComplete, finishedPage }) => {
    if (isComplete) {
      Modal.confirm({
        title: <div style={{ color: 'red' }}>{intl.formatMessage({ id: 'submitDes' })}</div>,
        okText: intl.formatMessage({ id: 'submit' }),
        cancelText: intl.formatMessage({ id: 'cancel' }),
        onOk: () => {
          dispatch({ type: 'subjectDetail/updateState', payload: { parentInterviewIsFininshed: true } })
          dispatch({ type: 'subjectDetail/submitQuestion', payload: { answerContent, isComplete, finishedPage, intl } })
        }
      })
      return
    } else {
      dispatch({ type: 'subjectDetail/submitQuestion', payload: { answerContent, isComplete, finishedPage, intl } })
    }
  }

  const onClose = () => {
    if (!parentInterviewIsFininshed && parentInterviewSubmitData) {
      onComplete(parentInterviewSubmitData)
    }
    dispatch({ type: 'subjectDetail/updateState', payload: { parentInterviewDrawerVisible: false } })
  }

  const onValueChange = ({ answerContent, isComplete, finishedPage, needSubmit }) => {
    dispatch({ type: 'subjectDetail/updateState', payload: { parentInterviewSubmitData: { answerContent, isComplete, finishedPage } } })
    if (needSubmit) {
      onComplete({ answerContent, isComplete, finishedPage })
    }
  }

  const modalProps = {
    title: intl.formatMessage({ id: 'editClinicNumber' }),
    visible: editShow,
    onCancel() {
      dispatch({ type: 'subjectDetail/updateState', payload: { editShow: false } })
    },
    onOk(value) {
      dispatch({ type: 'subjectDetail/editSubmit', payload: { ...value, subjectInfo } })
    },
    content
  }

  const questionProps = {
    content: jsonString ? jsonString : '',
    answerContent: answerContent || '',
    answer: answer,
    onComplete: onComplete,
    onValueChange: onValueChange
  }

  // if (subjectInfo === null)
  //   return (
  //     <div style={{ position: 'relative' }} className={styles.spinWrap}>
  //       <Spin
  //         size="large"
  //         style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, marginTop: 200 }}
  //       />
  //     </div>
  //   )

  const getGrowthTrackTitle = () => {
    const { outpatientNumber, name } = subjectInfo || {};
    let text;
    if (medicalPublicWithName) {
      if (outpatientNumber) {
        text = outpatientNumber;
      } else {
        text = intl.formatMessage({ id: 'MissingPatientID' });
      }
    } else {
      if (name) {
        text = name;
      } else {
        text = intl.formatMessage({ id: 'MissingName' });
      }
    }
    return text + intl.formatMessage({ id: 'growthTrack' });
  }

  return (
    subjectInfo === null ?
      <div style={{ position: 'relative' }} className={styles.spinWrap}>
        <Spin
          size="large"
          style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, marginTop: 200 }}
        />
      </div> :
      // <DocumentTitle title="儿童成长轨迹">
      <React.Fragment>
        <div className={styles.top} style={{ marginBottom: 14 }}>
          {showBackBtn(isShowGrowthText)}
          {isShowGrowthText ? (
            <span className={styles.topTitle}>{getGrowthTrackTitle()}</span>
          ) : (
            <span></span>
          )}
        </div>
        <div className={styles.main}>
          <div className={styles.mainInside}>
            <TabList {...tabListProps} />
            {showContent(currentTab)}
          </div>
          <div className={styles.mainInsideMargin}>
            <SubjectInfo {...subjectInfoProps} />
          </div>
        </div>

        <MagicFormModal {...modalProps} />

        {/* 家长访谈 */}
        <Drawer
          title={<b style={{ fontSize: '18px' }}>{intl.formatMessage({ id: 'parentInterviews' })}</b>}
          open={parentInterviewDrawerVisible}
          width={700}
          onClose={() => onClose()}
        >
          {
            parentInterviewDrawerVisible &&
            <Question {...questionProps} />
          }
        </Drawer>
      </React.Fragment>
    // </DocumentTitle>
  )
}

export default connect(({
  subjectDetail,
  loading,
  musicplayer,
  user,
}) => ({
  subjectDetail,
  loading,
  isOpen: musicplayer.isOpen,
  currentUser: user.currentUser,
}))(SubjectDetail)

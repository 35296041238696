import { useState, useEffect, useRef, useImperativeHandle } from 'react';
import { message, Modal, Input } from 'antd';
import { useIntl } from 'umi';
import moment from 'moment';

import {
  GetCommentsList,
  PostTrainingProgramSuggestionReply,
  GetCommentsListExcel,
} from 'services/suggestion';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import download from 'cognitiveleap-core-us/utils/download';

const { confirm } = Modal;
const { TextArea } = Input;

const dateFormat = 'MM/DD/YYYY HH:mm';

const listSupport = (intl) => {
  return {
    creationTime: {
      showText: intl.formatMessage({ id: 'submissionTime' }),
      showType: 'Time',
      addtional: {
        format: dateFormat,
      },
    },
    code: {
      showText: intl.formatMessage({ id: 'Code' }),
      showType: 'Text',
    },
    trainingProgramTitle: {
      showText: intl.formatMessage({ id: 'Activity Name' }),
      showType: 'Text',
    },
    content: {
      showText: intl.formatMessage({ id: 'Submitted Content' }),
      showType: 'Remark',
      addtional: {
        showLength: 10,
      },
    },
    creator: {
      showText: intl.formatMessage({ id: 'Submitter' }),
      showType: 'Text',
    },
    replyContent: {
      showText: intl.formatMessage({ id: 'Reply Content' }),
      showType: 'Remark',
      addtional: {
        showLength: 10,
      },
    },
    replyTime: {
      showText: intl.formatMessage({ id: 'Reply Time' }),
      showType: 'Time',
      addtional: {
        format: dateFormat,
      },
    },
    status: {
      showText: intl.formatMessage({ id: 'status' }),
      showType: 'Text',
      render: (_, record) => {
        const { replyContent } = record || {};
        return intl.formatMessage({
          id: replyContent ? 'Replied' : 'Not Replied',
        });
      },
    },
  };
};

const Comments = ({ isAdmin, setExportLoading, onRef }) => {
  const intl = useIntl();

  const editContent = useRef('');

  const [listData, setListData] = useState([]);
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
    modalLoading: false,
    confirmLoading: false,
  });

  useImperativeHandle(onRef, () => {
    return {
      exportData: async () => {
        setExportLoading(true);

        const res = await GetCommentsListExcel({
          SkipCount: (pagination.current - 1) * pagination.pageSize,
          MaxResultCount: pagination.pageSize,
        });

        if (res.response.ok) {
          download(
            res.data,
            `${intl.formatMessage({ id: 'gameComments' })}+${moment().format(
              'YYYY_MM_DD HH_mm',
            )}.xlsx`,
          );
        }

        setExportLoading(false);
      },
    };
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const res = await GetCommentsList({
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
    });

    if (res.response.ok) {
      const { totalCount, items } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current]);

  const onView = (record) => {
    const { content, replyContent, replyTime } = record || {};
    if (isAdmin) {
      confirm({
        title: intl.formatMessage({ id: 'gameComments' }),
        width: 600,
        icon: <></>,
        content: content,
        okText: intl.formatMessage({ id: 'Reply' }),
        onOk() {
          onReply(record);
        },
        onCancel() {},
      });
    } else {
      Modal.info({
        title: intl.formatMessage({ id: 'Responses Received' }),
        icon: <></>,
        width: 600,
        okText: intl.formatMessage({ id: 'iknow' }),
        content: (
          <>
            <div style={{ margin: '10px 0' }}>{replyContent}</div>
            <div style={{ color: 'gray' }}>
              {moment(replyTime).format(dateFormat)}
            </div>
          </>
        ),
      });
    }
  };

  const onReplySubmit = async (record) => {
    const { id } = record || {};

    setLoading({
      ...loading,
      confirmLoading: true,
    });

    const res = await PostTrainingProgramSuggestionReply({
      id,
      content: editContent.current,
    });

    if (res.response.ok) {
      initData();
    }

    setLoading({
      ...loading,
      confirmLoading: false,
    });
  };

  const onReply = (record) => {
    const { replyContent } = record || {};
    confirm({
      title: intl.formatMessage({ id: 'Responses' }),
      width: 600,
      icon: <></>,
      content: (
        <TextArea
          rows={4}
          defaultValue={replyContent}
          onChange={(e) => {
            editContent.current = e.target.value;
          }}
        />
      ),
      okText: intl.formatMessage({ id: 'submitOther' }),
      confirmLoading: loading.confirmLoading,
      onOk: () => onReplySubmit(record),
      onCancel() {},
    });
  };

  const tableProps = {
    listData: listData.map((item) => {
      const { trainingProgram } = item || {};
      const { code, title: trainingProgramTitle } = trainingProgram || {};
      return {
        code,
        trainingProgramTitle,
        ...item,
      };
    }),
    listSupport: listSupport(intl),
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: isAdmin
      ? [
          {
            showText: intl.formatMessage({ id: 'see' }),
            itemRender(record) {
              return (
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    onView(record);
                  }}
                >
                  {intl.formatMessage({ id: 'see' })}
                </a>
              );
            },
          },
          {
            showText: intl.formatMessage({ id: 'Reply' }),
            itemRender(record) {
              return (
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                    onReply(record);
                  }}
                >
                  {intl.formatMessage({ id: 'Reply' })}
                </a>
              );
            },
          },
        ]
      : [
          {
            showText: intl.formatMessage({ id: 'View Responses' }),
            itemRender(record) {
              const { replyContent } = record || {};
              return (
                replyContent && (
                  <a
                    onClick={(e) => {
                      e.stopPropagation();
                      onView(record);
                    }}
                  >
                    {intl.formatMessage({ id: 'View Responses' })}
                  </a>
                )
              );
            },
          },
        ],
  };

  return (
    <div>
      <MagicTable {...tableProps} />
    </div>
  );
};

export default Comments;

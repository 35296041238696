import { useState, useEffect, useRef } from 'react';
import { PrefixZero } from 'utils/utils'
import moment from 'moment'

export default function useTimer(isCurrentClass, startClassTime, lastStartClassTime, endClassTime, duration) {

    const timerID = useRef()

    const [currentTime, setTime] = useState('00 : 00 : 00')

    const temp = moment.duration(duration, 'seconds').as('seconds') + moment().diff(lastStartClassTime, 'seconds')
    const tempTime = moment().subtract(temp, 'seconds').format('YYYY-MM-DD HH:mm:ss');

    const getTime = (durationTime) => {
        return `${PrefixZero(durationTime.hours(), 2)} : ${PrefixZero(durationTime.minutes(), 2)} : ${PrefixZero(durationTime.seconds(), 2)}`
    }

    useEffect(() => {
        setTime(() => '00 : 00 : 00')
        if (isCurrentClass && startClassTime && !endClassTime) {
            // console.log('开始/继续');
            timerID.current = setInterval(() => {
                const durationTime = moment.duration(moment().diff(tempTime, 'seconds'), 'seconds')
                const arriveTime = getTime(durationTime)
                setTime(() => arriveTime)
            }, 2000)
        } else {
            // console.log("暂停");
            if (timerID.current) clearInterval(timerID.current)
        }
    }, [endClassTime, startClassTime, isCurrentClass])

    useEffect(() => {
        return () => {
            clearInterval(timerID.current)
        }
    }, [])

    return currentTime;
}
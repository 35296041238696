import React, { useEffect } from 'react'
import { connect } from 'umi'
import { Button, Popover } from 'antd'
import { checkFeedbackProperty } from 'utils/utils'
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'


const modalTitleMenu = {
  edit: '编辑训练计划库',
  add: '训练计划库',
  remark: '编辑备注'
}

const listSupport = {
  name: {
    showText: '名字',
    showType: 'Text',
  },
  displayName: {
    showText: '训练计划名称',
    showType: 'Text',
  },
  // ageStage: {
  //   showText: '年龄段',
  //   showType: 'Text',
  //   render: (text, record) => {
  //     if (text === 'PreSchool') {
  //       return '学前'
  //     } else if (text === 'GradeOrTeenSchool') {
  //       return '学龄/青少年'
  //     }
  //     return '黄金时代'
  //   }
  // },
  coverPicture: {
    showText: '封面图',
    showType: 'Picture',
  },
  qualificationMedalList: {
    showText: '关联证书',
    showType: 'Text',
    render: (text, record) => {
      if (text.length === 0) {
        return '';
      } else if (text.length === 1) {
        return text[0].displayName;
      } else {
        return (
          <Popover
            title="关联证书"
            key={record.id}
            content={text.map((item) => {
              return item ? (
                <div key={item.id} style={{
                  height: '30px',
                  lineHeight: '30px'
                }}>
                  {item.displayName}
                </div>
              ) : (
                <div style={{
                  height: '30px',
                  lineHeight: '30px'
                }}>-</div>
              );
            })}
          >
            <span style={{ cursor: 'pointer' }}>
              {text[0].displayName + ' - (' + text.length + ')'}
            </span>
          </Popover>
        );
      }
    },
  },
  programCount: {
    showText: 'programCount',
    showType: 'Text',
    render: (text, record) => {
      return text || 0
    }
  },
  videoCount: {
    showText: '音乐数',
    showType: 'Text',
    render: (text, record) => {
      return text || 0
    }
  },
  offlinePrice: {
    showText: '线下单价(课时)',
    showType: 'Text',
  },
  onlinePrice: {
    showText: '线上单价(课时)',
    showType: 'Text',
  },
  description: {
    showText: '备注',
    showType: 'Remark',
  }
}

const newOfflinePlan = ({ loading, adminNewOfflinePlan, dispatch }) => {
  const tableLoading = loading.effects['adminNewOfflinePlan/query']

  useEffect(() => {
    dispatch({ type: 'adminNewOfflinePlan/query' })
  }, [])

  const {
    modalType,
    content,
    modalVisible,
    list,
    pagination,
    avaliableFeedbackProperties
  } = adminNewOfflinePlan

  const modalTypeToTitle = modalType => {
    return modalTitleMenu[modalType]
  }

  const modalProps = {
    title: modalTypeToTitle(modalType),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'adminNewOfflinePlan/updateState', payload: { modalVisible: false } })
    },
    onOk(value) {
      if (modalType === 'remark') {
        dispatch({
          type: 'adminNewOfflinePlan/onSubmitModal',
          payload: value,
        })
      } else {
        const { feedbackProperties, courseFeedbackProperties, planUserFeedbackProperties, categories } = value
        let allReady = true
  
        allReady = allReady && feedbackProperties.every(item => {
          const { extra, feedbackPropertyId } = item
          const feedbackProperty = avaliableFeedbackProperties.find(propertyItem => {
            return (propertyItem.id === feedbackPropertyId)
          })
          const { type, extraProperties } = feedbackProperty
          return checkFeedbackProperty(type, extra, extraProperties)
        })
  
        if (!allReady) return
  
        allReady = allReady && courseFeedbackProperties.every(item => {
          const { extra, feedbackPropertyId } = item
          const feedbackProperty = avaliableFeedbackProperties.find(propertyItem => {
            return (propertyItem.id === feedbackPropertyId)
          })
          const { type, extraProperties } = feedbackProperty
          return checkFeedbackProperty(type, extra, extraProperties)
        })
  
        allReady = allReady && planUserFeedbackProperties.every(item => {
          const { extra, feedbackPropertyId } = item
          const feedbackProperty = avaliableFeedbackProperties.find(propertyItem => {
            return (propertyItem.id === feedbackPropertyId)
          })
          const { type, extraProperties } = feedbackProperty
          return checkFeedbackProperty(type, extra, extraProperties)
        })
  
        if (allReady) {
          const feedbacks = feedbackProperties.map(item => {
            const { extra } = item
            const extraProperties = JSON.parse(extra)
            return {
              ...item,
              extraProperties,
            }
          })
          const courseFeedbacks = courseFeedbackProperties.map(item => {
            const { extra } = item
            const extraProperties = JSON.parse(extra)
            return {
              ...item,
              extraProperties,
            }
          })
          const planUserFeedbacks = planUserFeedbackProperties.map(item => {
            const { extra } = item
            const extraProperties = JSON.parse(extra)
            return {
              ...item,
              extraProperties,
            }
          })
  
          const tempCategories = categories.map((category, index) => {
            return {
              ...category,
              order: index
            }
          })
  
          dispatch({
            type: 'adminNewOfflinePlan/onSubmitModal',
            payload: {
              ...value,
              feedbackProperties: feedbacks,
              courseFeedbackProperties: courseFeedbacks,
              planUserFeedbackProperties: planUserFeedbacks,
              categories: tempCategories
            },
          })
        }
      }
    },
    content,
  }

  const TableProps = {
    listData: list,
    listSupport,
    pagination,
    loading: tableLoading,
    onTableChange(pagi, fil = {}, sort = {}) {
      let data = { pagination }
      if (Object.keys(pagi).length !== 0) {
        data = { ...data, pagination: pagi }
      }
      dispatch({ type: 'adminNewOfflinePlan/changeTable', payload: data })
    },
    Actions: [
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'adminNewOfflinePlan/onAddOrEdit', payload: record.id })
        },
      },
      {
        showText: '编辑备注',
        onClick(record) {
          dispatch({ type: 'adminNewOfflinePlan/onEditRemark', payload: record })
        },
      },
      // {
      //   showText: '中心',
      //   onClick(record) {
      //     history.push({
      //       pathname: '/system/manage/authorCenter',
      //       query: {
      //         id: record.id,
      //         type: 'offlinePlan',
      //         title: record.title
      //       },
      //     })
      //   }
      // },
    ],
  }

  const onAddClick = () => {
    dispatch({ type: 'adminNewOfflinePlan/onAddOrEdit', payload: null })
  }

  return (
    <div>
      <h1>训练计划库</h1>
      <Button onClick={onAddClick} type="primary">新建训练计划</Button>
      <MagicTable {...TableProps} />
      <MagicFormModal {...modalProps} />
    </div>
  )
}
export default connect(({ loading, adminNewOfflinePlan }) => ({ loading, adminNewOfflinePlan }))(newOfflinePlan)

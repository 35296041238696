import React, { useEffect, useState } from 'react';
import { connect, useIntl } from 'umi';
import { Spin, message } from 'antd';

import { GetTrialPlan } from 'services/trialPlan';
import { GetAssessmentDetail } from 'services/rocketService/Assessment';

import TrialSessionContent from './TrialSessionContent';

import styles from './index.less';

const TrialSession = ({ adminSubjectDetail }) => {
  const intl = useIntl();

  const { subjectInfo } = adminSubjectDetail || {};
  const { id: userId } = subjectInfo || {};

  const [trialData, setTrialData] = useState({});
  const [assessmentData, setAssessmentData] = useState({});
  const [loading, setLoading] = useState(false);

  const initData = async () => {
    setLoading(true);

    const res = await GetTrialPlan({ userId });

    if (res.response.ok) {
      setTrialData(res.data || {});

      const { userAssessmentId } = res.data || {};
      if (userAssessmentId) {
        const assessmentRes = await GetAssessmentDetail(userAssessmentId);

        if (assessmentRes.response.ok) {
          setAssessmentData(assessmentRes.data || {});
        } else {
          const {
            data: { error },
          } = assessmentRes;
          message.error(error.message);
        }
      }
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [userId]);

  const EmptyCom = (
    <div className={styles.trialPlan}>
      <div className={styles.trialPlanText}>
        {intl.formatMessage({ id: 'Child has not yet started' })}
      </div>
    </div>
  );

  const contentProps = {
    userId,
    trialData,
    assessmentData,
    subjectInfo,
    onReload: initData,
  };

  if (!Object.keys(trialData).length && loading) {
    return (
      <div
        style={{
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div className={styles.tabContent}>
      {Object.keys(trialData).length > 0 ? (
        <TrialSessionContent {...contentProps} />
      ) : (
        EmptyCom
      )}
    </div>
  );
};

export default connect(({ adminSubjectDetail }) => ({
  adminSubjectDetail,
}))(TrialSession);

import React, { useState, useEffect, useRef, useImperativeHandle } from 'react';

import {
  Space,
  Collapse,
  message,
  Spin,
  Menu,
  Dropdown,
  Divider,
  Modal,
  Tooltip,
} from 'antd';
import { getLocale, useIntl, connect } from 'umi';
import {
  CaretRightOutlined,
  MinusCircleOutlined,
  CheckCircleFilled,
  PlusOutlined,
  FormOutlined,
  DownOutlined,
  AlignLeftOutlined,
  RightOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

import StartTrialModal from './StartTrialModal';
import AddProgramModal from './AddProgramModal';
import SessionFeedbackModal from './SessionFeedbackModal';
import ShowItem from 'components/ShowVideoItem';

import { resolveSurveyData } from 'cognitiveleap-core-us/utils/utils';

import { PostTrialPlanDone } from 'services/trialPlan';
import { GetDownloadPdf } from 'services/puppeteer';

import veryHappy from 'assets/veryHappy.png';
import veryHappySelect from 'assets/veryHappySelect.png';
import happy from 'assets/happy.png';
import happySelect from 'assets/happySelect.png';
import bad from 'assets/bad.png';
import badSelect from 'assets/badSelect.png';
import brainLogo from 'assets/brainLogo.png';

const { Panel } = Collapse;

import moment from 'moment';

import styles from '../detail.less';

const findNextCategoryId = (currentId, items) => {
  const { category } =
    items
      .filter((item) => item.category.id !== currentId)
      .find((item) => item.trialPlanItems.length == 0) || {};

  return category ? category.id : null;
};

const TrialSessionContent = ({
  subjectInfo,
  trialData,
  assessmentData,
  onReload,
  dispatch,
  musicPlanInfo,
  musicIsPlaying,
  musicIsOpen,
  onRef,
}) => {
  const intl = useIntl();

  const {
    userId,
    creatorId,
    percent,
    items = [],
    sessionFeedbacks = [],
  } = trialData || {};

  const { questionnaireAnswer = {}, finishTime: answerFinishTime } =
    assessmentData || {};

  let mainComplaintData = {};
  const { answer, questionnaire } = questionnaireAnswer || {};

  const { answerContent } = answer || {};
  const { jsonString } = questionnaire || {};

  const feedbackData = resolveSurveyData(
    JSON.parse(answerContent || '{}'),
    JSON.parse(jsonString || '{}'),
    'allData',
  );

  if (feedbackData && feedbackData.length > 0) {
    const filterData = feedbackData.filter(
      (item) => item.name === 'mainComplaint',
    );
    if (filterData && filterData.length > 0) {
      mainComplaintData = filterData[0];
    }
  }

  const { value: mainComplaintValue = [] } = mainComplaintData || {}; // 主诉

  const isFinishSessionFeedback = sessionFeedbacks.every((item) => item.value);

  const { name: userName } = subjectInfo || {};
  const firstUpload = useRef(true); // 记录是否是首次加载页面

  const [visible, setVisible] = useState({
    addProgramModalVisible: false,
    showItemVisible: false,
    startTrialModalVisible: false,
    sessionFeedbackVisible: false,
  });
  const [currentItem, setCurrentItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState('');
  const [activeKey, setActiveKey] = useState('');
  // const [selectTrialList, setSelectTrialList] = useState([]);

  useEffect(() => {
    if (firstUpload.current) {
      firstUpload.current = false;
      return; // 首次加载页面不执行
    }
    onReload();
  }, [musicIsOpen]);

  // useEffect(() => {
  //   const unFinishData = items.find((item) => {
  //     const { trialPlanItems = [] } = item || {};
  //     return (
  //       trialPlanItems.length > 0 &&
  //       trialPlanItems.some((trialItem) => trialItem.doneTime == null)
  //     );
  //   }) || {};
  //   const { category } = unFinishData || {};
  //   const { name } = category || {};
  //   setActiveKey(name);

  //   return () => {
  //     setActiveKey('');
  //   }
  // }, [items])

  const showStandardList = {
    Exceeding: intl.formatMessage({ id: 'Exceeding Standard' }),
    Meeting: intl.formatMessage({ id: 'Meeting Standard' }),
    Below: intl.formatMessage({ id: 'Below Standard' }),
  };

  const onAddPrograms = (item) => {
    setCurrentItem(item);
    setVisible({
      ...visible,
      addProgramModalVisible: true,
    });
  };

  useImperativeHandle(onRef, () => {
    return {
      addProgramsModal: (data) => {
        const { items = [] } = data || {};
        const findData =
          items.find((item) => item.trialPlanItems.length === 0) || {};

        if (Object.keys(findData || {}).length) {
          setCurrentItem(findData || {});
          setVisible({
            ...visible,
            startTrialModalVisible: false,
            addProgramModalVisible: true,
          });
        }
      },
    };
  });

  const programProps = {
    visible: visible.addProgramModalVisible,
    userId,
    currentItem,
    trialData,
    onCloseModal: () =>
      setVisible({
        ...visible,
        addProgramModalVisible: false,
      }),
    onReload: () => {
      // 提交完成自动展开项目
      const { category } = currentItem || {};
      const { name } = category || {};
      setActiveKey(name);
      onReload();
    },
    findNextCategoryId,
  };

  const showItemProps = {
    visible: visible.showItemVisible,
    programId: currentItem?.trainingProgramId,
    from: 'subjectPlanDetail',
    userOfflineTrainingPlan: {
      ilsVersion: 'V4',
    },
    onClose() {
      setVisible({
        ...visible,
        showItemVisible: false,
      });
    },
  };

  const startTrialModalProps = {
    userId,
    option,
    trialData,
    visible: visible.startTrialModalVisible,
    onReload: () => {
      onReload((data) => {
        const { items = [] } = data || {};
        const findData =
          items.find((item) => item.trialPlanItems.length === 0) || {};

        if (Object.keys(findData || {}).length) {
          setCurrentItem(findData || {});
          setVisible({
            ...visible,
            startTrialModalVisible: false,
            addProgramModalVisible: true,
          });
        }
      });
    },
    onCloseModal: () =>
      setVisible({
        ...visible,
        startTrialModalVisible: false,
      }),
  };

  const sessionFeedbackProps = {
    visible: visible.sessionFeedbackVisible,
    trialData,
    onReload,
    onCloseModal: () =>
      setVisible({
        ...visible,
        sessionFeedbackVisible: false,
      }),
  };

  const onSelectStandard = async (type, data) => {
    // 深拷贝，不修改原数据
    setLoading(true);

    const _data = structuredClone(data);

    const { id, standard } = _data || {};

    // 前端直接呈现方案，无需等后端返回
    // const index = selectTrialList.findIndex(
    //   (item) => item.id === id && item.standard === type,
    // );

    // if (index > -1) {
    //   selectTrialList.splice(index, 1);
    //   setSelectTrialList([...selectTrialList]);
    // } else {
    //   const _index = selectTrialList.findIndex((item) => item.id === id);
    //   if (_index > -1) {
    //     // 已存在不拼接，直接修改
    //     selectTrialList[_index].standard = type;
    //     setSelectTrialList([...selectTrialList]);
    //   } else {
    //     _data.standard = type;
    //     setSelectTrialList(selectTrialList.concat(_data));
    //   }
    // }

    const params = standard === type ? { id } : { id, standard: type };

    const res = await PostTrialPlanDone(params);

    if (res.response.ok) {
      onReload(() => setLoading(false));
    } else {
      const { error } = res.data;
      message.error(error.message);
      setLoading(false);
    }
  };

  const onPreviewRecord = (e) => {
    e.stopPropagation();
    const pathName = '/trialSessionRecord';
    const url =
      pathName +
      `?subjectId=${userId}&trainerId=${creatorId}&type=preview&culture=${getLocale()}`;
    window.open(url, '_blank');
  };

  const onDownloadRecord = async (e) => {
    e.stopPropagation();

    const hide = message.loading(intl.formatMessage({ id: 'downloading' }), 0);
    const origin = window.location.origin;

    const CognitiveleapUser = localStorage.getItem('CognitiveleapUser');
    const userTenantId = localStorage.getItem('userTenantId');
    const tenantId = localStorage.getItem('tenantId');

    const pathName = '/trialSessionRecord';

    // 需要打印的页面完整url
    const url = [
      origin +
        pathName +
        `?subjectId=${userId}&trainerId=${creatorId}&CognitiveleapUser=${CognitiveleapUser}&userTenantId=${userTenantId}&tenantId=${tenantId}&culture=${getLocale()}`,
    ];
    await GetDownloadPdf(
      { urlList: { ...url }, needPadding: true },
      `${userName}`,
    );
    setTimeout(hide, 0);
  };

  const onEditTrialPlan = (type) => {
    setOption(type);
    setVisible({
      ...visible,
      startTrialModalVisible: true,
    });
  };

  const onFeedback = (e) => {
    e.stopPropagation();
    setVisible({
      ...visible,
      sessionFeedbackVisible: true,
    });
  };

  const openMusicDrawer = () => {
    dispatch({
      type: 'musicplayer/openTrialSessionMusicPlayerDrawer',
      payload: {
        planInfo: trialData,
        from: 'trialSessionPlan',
        subjectInfo,
      },
    });
  };

  const onMusicPlayerDrawer = () => {
    if (musicPlanInfo && musicIsPlaying) {
      dispatch({
        type: 'musicplayer/updateState',
        payload: { musicPlayerDrawerVisible: true },
      });
    } else {
      if (musicPlanInfo == null || !musicIsPlaying) {
        openMusicDrawer();
      } else {
        Modal.confirm({
          title: intl.formatMessage({ id: 'musicPlayingProceed' }),
          okText: intl.formatMessage({ id: 'leave' }),
          cancelText: intl.formatMessage({ id: 'cancel' }),
          onOk: () => {
            openMusicDrawer();
          },
          onCancel() {},
        });
      }
    }
  };

  return (
    <div className={styles.trialContent}>
      <Space direction="vertical" className={styles.mainComplaint}>
        <div className={styles.complaintTitle}>
          {intl.formatMessage({ id: 'Main Complaint' })}:
          {/* <a onClick={() => onEditTrialPlan('editMainComplaint')}>
            {intl.formatMessage({ id: 'edit' })}
          </a> */}
        </div>
        <div>
          {mainComplaintValue?.map((item) => {
            const { customProperty, text } = item || {};
            return (
              <div key={text} style={{ lineHeight: '30px' }}>
                <Space size={5}>
                  <span>{text}</span>
                  {customProperty && (
                    <Tooltip placement="right" title={customProperty}>
                      <InfoCircleOutlined styles={{ color: '#fafafa' }} />
                    </Tooltip>
                  )}
                </Space>
              </div>
            );
          })}
        </div>
        {answerFinishTime && (
          <div className={styles.complaintTime}>
            {moment(answerFinishTime).format('MM/DD/YYYY HH:mm')}
          </div>
        )}
      </Space>
      <Space
        size={2}
        className={styles.trialContentAction}
        split={<Divider type="vertical" />}
      >
        <a onClick={() => onEditTrialPlan('editTrainingType')}>
          {intl.formatMessage({ id: 'Edit Training Type' })}
        </a>
        <React.Fragment>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="preview" style={{ textAlign: 'center' }}>
                  <a onClick={(e) => onPreviewRecord(e)}>
                    {intl.formatMessage({ id: 'preview' })}
                  </a>
                </Menu.Item>
                {/* <Menu.Item key="download" style={{ textAlign: 'center' }}>
                  <a onClick={(e) => onDownloadRecord(e)}>
                    {intl.formatMessage({ id: 'download' })}
                  </a>
                </Menu.Item> */}
              </Menu>
            }
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {intl.formatMessage({ id: 'sessionFeedbackForm' })}{' '}
              <DownOutlined />
            </a>
          </Dropdown>
        </React.Fragment>
      </Space>

      <div
        className={styles.iLsMusicCanPlay}
        onClick={(e) => {
          e.stopPropagation();
          onMusicPlayerDrawer();
        }}
      >
        <div>
          <img src={brainLogo} className={styles.brainLogo} />
          <span className={styles.brainTitle}>
            {intl.formatMessage({ id: 'HFSMusic' })}
          </span>
        </div>
        <div className={styles.iLsMusicRight}>
          {musicIsPlaying && (
            <AlignLeftOutlined
              style={{
                transform: 'rotate(-90deg)',
                color: '#55B3B7',
                fontSize: '19px',
                marginRight: '8px',
              }}
            />
          )}
          <Space size={4}>
            <span>
              {intl.formatMessage(
                { id: 'Listening Progress' },
                { percent: Math.round(percent * 100) },
              )}
            </span>
            <a>{intl.formatMessage({ id: 'see' })}</a>
            <RightOutlined className={styles.iLsMusicRightIcon} />
          </Space>
        </div>
      </div>

      <Collapse
        accordion
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        activeKey={activeKey}
        expandIconPosition="end"
        style={{
          backgroundColor: '#ffffff',
        }}
        onChange={(key) => setActiveKey(key)}
      >
        {items.map((item) => {
          const { category, trialPlanItems = [] } = item || {};
          const { displayName, name } = category || {};
          return (
            <Panel
              header={
                <Space align="center">
                  {trialPlanItems.length > 0 &&
                  trialPlanItems.every(
                    (trialItem) => trialItem.doneTime !== null,
                  ) ? (
                    <CheckCircleFilled
                      style={{ fontSize: '18px', marginTop: '4px' }}
                    />
                  ) : (
                    <MinusCircleOutlined
                      style={{ fontSize: '18px', marginTop: '4px' }}
                    />
                  )}
                  <div>{displayName}</div>
                </Space>
              }
              key={name}
              extra={
                <PlusOutlined
                  style={{ marginTop: '5px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAddPrograms(item);
                  }}
                />
              }
              style={{
                marginBottom: '24px',
                overflow: 'hidden',
                background: '#eeeeee',
                border: '0px',
                borderRadius: '2px',
              }}
            >
              <Spin spinning={loading}>
                <div
                  style={{
                    padding: '10px 20px 0px 20px',
                    background: '#ffffff',
                  }}
                >
                  {trialPlanItems.map((trialItem) => {
                    let _standard;
                    const { standard, trainingProgram, id } = trialItem || {};
                    const { title, difficulty2 } = trainingProgram || {};

                    _standard = standard;

                    // const findData =
                    //   selectTrialList.find((item) => item.id === id) || {};

                    // const { standard: findStandard } = findData || {};

                    // if (Object.keys(findData).length > 0) {
                    //   _standard = findStandard;
                    // } else {
                    //   _standard = standard;
                    // }

                    return (
                      <div className={styles.contentItem}>
                        <a
                          onClick={(e) => {
                            e.stopPropagation();
                            setVisible({
                              ...visible,
                              showItemVisible: true,
                            });
                            setCurrentItem(trialItem);
                          }}
                        >
                          {title} - {difficulty2}
                        </a>
                        <Space>
                          <span>{showStandardList[_standard]}</span>
                          <img
                            src={_standard === 'Below' ? badSelect : bad}
                            alt=""
                            onClick={() => onSelectStandard('Below', trialItem)}
                          />
                          <img
                            src={_standard === 'Meeting' ? happySelect : happy}
                            alt=""
                            onClick={() =>
                              onSelectStandard('Meeting', trialItem)
                            }
                          />
                          <img
                            src={
                              _standard === 'Exceeding'
                                ? veryHappySelect
                                : veryHappy
                            }
                            alt=""
                            onClick={() =>
                              onSelectStandard('Exceeding', trialItem)
                            }
                          />
                        </Space>
                      </div>
                    );
                  })}
                </div>
              </Spin>
            </Panel>
          );
        })}
      </Collapse>
      <div className={styles.trainFeedback}>
        <div>{intl.formatMessage({ id: 'Trial Session Feedback' })}</div>
        <Space>
          <div
            style={{ color: isFinishSessionFeedback ? '#8C8C8C' : '#F96B4B' }}
          >
            {intl.formatMessage({
              id: isFinishSessionFeedback ? 'feedbacksubmitted' : 'Incomplete',
            })}
          </div>
          <FormOutlined style={{ cursor: 'pointer' }} onClick={onFeedback} />
        </Space>
      </div>
      {visible.addProgramModalVisible && <AddProgramModal {...programProps} />}
      {visible.showItemVisible && <ShowItem {...showItemProps} />}
      {visible.startTrialModalVisible && (
        <StartTrialModal {...startTrialModalProps} />
      )}
      {visible.sessionFeedbackVisible && (
        <SessionFeedbackModal {...sessionFeedbackProps} />
      )}
    </div>
  );
};

export default connect(({ musicplayer }) => ({
  musicPlanInfo: musicplayer.planInfo,
  musicIsPlaying: musicplayer.musicIsOpen,
  musicIsOpen:
    musicplayer.musicPlayerDrawerVisible || musicplayer.showGlobalMusicplayer,
}))(TrialSessionContent);

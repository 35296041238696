import React, { useState } from 'react';

import { Form, Modal, message } from 'antd';

import { useIntl } from 'umi';

import {
  showAttributeDOM,
  formatSubmitValue,
  getFeedbackValue,
} from 'utils/feedback';

import { PostSessionFeedback } from 'services/trialPlan';

import moment from 'moment';

const FormItem = Form.Item;

const SessionFeedbackModal = ({
  visible,
  trialData,
  onReload,
  onCloseModal,
}) => {
  const intl = useIntl();

  const [form] = Form.useForm();

  const [confirmLoading, setConfirmLoading] = useState(false);

  const { sessionFeedbacks = [], id: trialPlanId } = trialData || {};

  const feedbackProperties = sessionFeedbacks.map(
    (item) => item.programFeedbackProperty,
  );

  const isEdit = sessionFeedbacks.every((item) => item.value);

  const getFeedbackItem = (feedbackItem) => {
    const { id, displayName, isRequired, type, extraProperties } = feedbackItem;
    let params = {};
    const name = `${type}~${id}`;

    const initialValue = sessionFeedbacks.find(
      (item) => item.programFeedbackPropertyId === id,
    )?.value;

    let value = initialValue ? getFeedbackValue(type, initialValue) : null;

    if (type === 'TimePicker') {
      params = {
        format: 'YYYY-MM-DD HH:mm',
        minuteStep: 30,
        placeholder: intl.formatMessage({ id: 'pleaseSelectDateAndTime' }),
        showNow: false,
        defaultValue: moment(
          `${moment().format('YYYY/MM/DD')} ${moment().format('HH')}:00`,
        ),
      };

      if (!value) {
        value = moment(
          `${moment().format('YYYY/MM/DD')} ${moment().format('HH')}:00`,
        );
      }
    }

    return (
      <FormItem
        label={displayName}
        key={id}
        name={name}
        initialValue={value}
        valuePropName={'value'}
        rules={[
          {
            required: isRequired,
            message: `${displayName}${intl.formatMessage({
              id: 'mandatoryField',
            })}`,
          },
        ]}
      >
        {showAttributeDOM(type, extraProperties, params, value)}
      </FormItem>
    );
  };

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    onCloseModal();
  };

  const onFinish = async (values) => {
    setConfirmLoading(true);

    let feedbacks = Object.keys(values).map((keyItem) => {
      let _sessionId;
      const [type, id] = keyItem.split('~');
      const currentData = feedbackProperties.find((item) => item.id === id);

      if (isEdit) {
        const findData = sessionFeedbacks.find((session) => {
          const { programFeedbackPropertyId } = session || {};
          return programFeedbackPropertyId === id;
        });
        const { id: sessionId } = findData || {};
        _sessionId = sessionId;
      }

      const data = {
        programFeedbackPropertyId: id,
        value: formatSubmitValue(type, values[keyItem], currentData),
      };

      return isEdit
        ? {
            ...data,
            id: _sessionId,
          }
        : data;
    });
    feedbacks = feedbacks.filter((item) => item.value != null);

    const params = {
      trialPlanId,
      feedbacks,
    };

    const res = await PostSessionFeedback(params);

    if (res.response.ok) {
      onReload();
      onCloseModal();
    } else {
      const { error } = res.data;
      message.error(error.message);
    }

    setConfirmLoading(false);
  };

  return (
    <Modal
      title={intl.formatMessage({ id: 'Trial Session Feedback' })}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={600}
      confirmLoading={confirmLoading}
    >
      <Form form={form} disabled={true} onFinish={onFinish} layout="vertical">
        {feedbackProperties.map((item) => getFeedbackItem(item))}
      </Form>
    </Modal>
  );
};

export default SessionFeedbackModal;

import React, { useState } from 'react';
import { Drawer, Tabs, Collapse, Empty, Menu, Dropdown } from 'antd';
import { DownCircleOutlined } from '@ant-design/icons';
import { connect, history, useIntl } from 'umi';
import moment from 'moment';
import { getAssessmentStatus } from 'utils/utils';
import {
  getToken,
  getTenantId,
  getUserTenantId,
} from 'cognitiveleap-core-us/utils/auth';

import MusicRecord from 'components/MusicRecord';
import AdminClockInRecord from 'components/ClockInRecord';
import TrainingStatistics from 'cognitiveleap-core-us/components/Charts/ILSChart/TrainingStatistics';
import CourseStatistics from 'cognitiveleap-core-us/components/Charts/ILSChart/CourseStatistics';
import BrainRecord from 'cognitiveleap-core-us/components/Charts/GroupChart/BrainRecord';
import BrainLineChart from 'cognitiveleap-core-us/components/Charts/ILSChart/BrainLineChart';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import BatchDownloadModal from 'components/BatchDownloadModal';

import RecordsTabs from 'components/RecordsTabs';

import Loading from 'components/Loading';

import styles from '../detail.less';

import config from 'utils/config';
const { mobileUrl } = config;

const { Panel } = Collapse;
const { TabPane } = Tabs;

const FinishPlanList = ({
  subjectOfflinePlan,
  loading,
  currentUser,
  dispatch,
  callbackFn,
}) => {
  const intl = useIntl();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [downCurrentNum, setDownCurrentNum] = useState(0);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const {
    pagination,
    allList,
    ilsDrawerVisible,
    ilsActiveKey,
    recordPagination,
    ilsparentRecord,
    ilstrainerRecord,
    ilsappRecord,
    shouldGetData,
    brainRecord,
    currentTablePlan,
    musicRecord,
  } = subjectOfflinePlan;

  const { auth, changedTenant } = currentUser;
  const { grantedPolicies } = auth;
  const hasOfflinePlanReadPermission =
    grantedPolicies['RocketSystem.UserOfflinePlan'];

  const isRecordLoading = loading.effects['subjectOfflinePlan/getRecord'];
  const brainIsLoading = loading.effects['subjectOfflinePlan/getBrainRecord'];
  const musicRecordIsLoading =
    loading.effects['subjectOfflinePlan/getMusicRecord'];

  const switchStatus = (status) => {
    // None, Created, Started, Ended, Feedbacked
    switch (status) {
      case 'None':
        return intl.formatMessage({ id: 'notRealStart' });
      case 'Created':
        return intl.formatMessage({ id: 'created' });
      case 'Started':
        return intl.formatMessage({ id: 'ongoing' });
      case 'Ended':
        return intl.formatMessage({ id: 'finished' });
      case 'Feedbacked':
        return intl.formatMessage({ id: 'doneFeek' });
      default:
        return '';
    }
  };

  const columns = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creatTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD HH:mm',
      },
    },
    displayName: {
      showText: intl.formatMessage({ id: 'trainingPlanName' }),
      showType: 'Text',
    },
    // showStatus: {
    //   showText: intl.formatMessage({ id: 'status' }),
    //   showType: 'Text',
    // },
    currentNum: {
      showText: intl.formatMessage({ id: 'progress' }),
      showType: 'Text',
    },
    totalNum: {
      showText: intl.formatMessage({ id: 'allSession' }),
      showType: 'Text',
    },
    allAssessmentReady: {
      showText: intl.formatMessage({ id: 'preStauts' }),
      showType: 'Text',
    },
    start: {
      showText: intl.formatMessage({ id: 'realStartTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD HH:mm',
      },
    },
    end: {
      showText: intl.formatMessage({ id: 'endTime' }),
      showType: 'Time',
      addtional: {
        format: 'YYYY/MM/DD HH:mm',
      },
    },
  };

  const batchDownloadModalProps = {
    isModalVisible,
    modalInfo,
    totalNum: downCurrentNum,
    onClose: () => {
      setIsModalVisible(false);
    },
  };

  const onClickMenuItem = ({ key }) => {
    const reportUrl = `${mobileUrl}/m/rocketReport?to=${key}&t=${getToken()}&h=${getTenantId()}&ts=${getUserTenantId()}`;
    window.open(reportUrl, '_blank');
  };

  const expandOptions = (record, index, indent, expanded) => {
    const recordsProps = {
      activePlan: record
    }

    return (
      <RecordsTabs {...recordsProps}/>
    );
  };

  const listProps = {
    loading: loading.models.subjectOfflinePlan,
    listData: allList.map((item) => {
      let allAssessmentReady = intl.formatMessage({ id: 'noPreAssessment' });
      const { assessmentStatus = [] } = item;
      if (assessmentStatus.length > 0) {
        const firstData = assessmentStatus.filter(
          (item) => item.assessmentType == 'PreAssessment',
        );
        if (firstData.length > 0) {
          const { userAssessment, isFinished } = firstData[0] || {};
          if (userAssessment) {
            const { status, needPay } = userAssessment || {};
            if (status === 'ReportedReady') {
              allAssessmentReady = intl.formatMessage({ id: 'finishedPre' });
              if (!needPay) {
                allAssessmentReady = intl.formatMessage({
                  id: 'reportedOther',
                });
              }
            }
          } else {
            allAssessmentReady = intl.formatMessage({
              id: isFinished ? 'finishedPre' : 'Incompleted',
            });
          }
        }
      }

      return {
        ...item,
        // showStatus: switchStatus(item.status),
        allAssessmentReady,
      };
    }),
    listSupport: columns,
    pagination,
    expandable() {
      return {
        expandedRowKeys,
        expandedRowRender: expandOptions,
        onExpandedRowsChange: (rows) => {
          setExpandedRowKeys([...rows]);
        },
      };
    },
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination };
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi };
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt };
      dispatch({ type: 'subjectOfflinePlan/changeTable', payload: data });
    },
    Actions: [
      //   hasOfflinePlanWritePermission &&
      //   rocketProjectStatus &&
      //   isBind &&
      //   !isLogout
      //     ? {
      //         showText: intl.formatMessage({ id: 'realStart' }),
      //         itemRender(record) {
      //           const { status, assessmentStatus, id } = record;
      //           const allAssessmentReady = assessmentStatus.every(
      //             (item) => item.isFinished,
      //           );
      //           return status === 'None' || status === 'Created' ? (
      //             <span key="start">
      //               <a
      //                 onClick={(e) =>
      //                   allAssessmentReady
      //                     ? onStartPlan(e, record, 'click')
      //                     : message.warning(
      //                         intl.formatMessage({
      //                           id: 'trainBeforeAllCompelete',
      //                         }),
      //                       )
      //                 }
      //               >
      //                 {intl.formatMessage({ id: 'realStart' })}
      //               </a>
      //             </span>
      //           ) : null;
      //         },
      //       }
      //     : null,
      {
        showText: intl.formatMessage({ id: 'batchDownloadForm' }),
        itemRender(record) {
          const { currentNum } = record;
          return currentNum > 0 ? (
            <a
              key="download"
              onClick={() => {
                setModalInfo(record);
                setIsModalVisible(true);
                setDownCurrentNum(currentNum);
              }}
            >
              {intl.formatMessage({ id: 'batchDownloadForm' })}
            </a>
          ) : null;
        },
      },
      {
        showText: intl.formatMessage({ id: 'chartsAndFeedbackRecords' }),
        itemRender(record) {
          const { status } = record;
          return status !== 'None' && status !== 'Created' ? (
            <a
              key="showCharts"
              onClick={(e) => {
                dispatch({
                  type: 'subjectOfflinePlan/showChartsAndFeedbackRecords',
                  payload: record,
                });
              }}
            >
              {intl.formatMessage({ id: 'chartsAndFeedbackRecords' })}
            </a>
          ) : null;
        },
      },
      {
        showText: intl.formatMessage({ id: 'preAssessmentList' }),
        itemRender(record) {
          const { status, assessmentStatus } = record;
          const menu = (
            <Menu
              onClick={onClickMenuItem}
              className={styles.assessmentContent}
            >
              {assessmentStatus.map((item) => {
                const {
                  userAssessmentId,
                  isFinished,
                  assessment,
                  userAssessment,
                  assessmentType,
                } = item;
                const { title } = assessment;
                const { status, tenantId, needPay } = userAssessment || {};
                const tempStatus =
                  status === 'ReportedReady' && !needPay
                    ? 'hasGetReport'
                    : status;
                return (
                  <Menu.Item
                    key={userAssessmentId}
                    disabled={
                      !isFinished ||
                      tenantId !== (changedTenant && changedTenant.id)
                    }
                  >
                    <span>
                      <a>{title}</a>
                      <span className={styles.showPreAssessmentIsFinish}>
                        {getAssessmentStatus(tempStatus, intl)}
                      </span>
                      {isFinished &&
                        userAssessment &&
                        userAssessment.finishTime &&
                        moment(userAssessment.finishTime)
                          .local()
                          .format('YYYY/MM/DD HH:mm:ss')}
                      <span
                        style={{ marginLeft: '10px', color: 'rgba(0,0,0,0.5)' }}
                      >
                        {
                          {
                            PreAssessment: intl.formatMessage({
                              id: 'pre_assessment',
                            }),
                            MediumAssessment: intl.formatMessage({
                              id: 'mid_assessment',
                            }),
                          }[assessmentType]
                        }
                      </span>
                    </span>
                  </Menu.Item>
                );
              })}
            </Menu>
          );

          return assessmentStatus && assessmentStatus.length > 0 ? (
            <span key="assessmentList">
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <span>{intl.formatMessage({ id: 'assessmentDetails' })}</span>{' '}
                  <DownCircleOutlined />
                </a>
              </Dropdown>
            </span>
          ) : null;
        },
      },
      {
        showText: intl.formatMessage({ id: 'trainingOverview' }),
        itemRender(record) {
          return (
            <a
              key="download"
              onClick={() => {
                const { id, displayName } = record;
                history.push({
                  pathname: '/system/center/detail/userOfflinePlanDetail',
                  query: {
                    id,
                    trainPlanName: encodeURI(displayName),
                    userName: encodeURI(name),
                  },
                });
              }}
            >
              {intl.formatMessage({ id: 'trainingOverview' })}
            </a>
          );
        },
      },
      {
        showText: intl.formatMessage({ id: 'startPlan' }),
        itemRender(record) {
          const { status, id } = record || {};
          return status === 'Ended' ? (
            <a
              key="startPlan"
              onClick={(e) => {
                e.stopPropagation();
                dispatch({ type: 'subjectOfflinePlan/reStart', payload: { intl, id, callbackFn } })
              }}
            >
              {intl.formatMessage({ id: 'startPlan' })}
            </a>
          ) : null;
        },
      },
      //   institutionType !== 'Medical_Public'
      //     ? {
      //         showText: intl.formatMessage({ id: 'scheduleAssessment' }),
      //         itemRender(record) {
      //           const { status } = record;
      //           return status != 'Ended' &&
      //             status != 'Created' &&
      //             !isEnterAdmin() ? (
      //             <a
      //               key="arrangeAssessment"
      //               onClick={() => {
      //                 dispatch({
      //                   type: 'subjectOfflinePlan/arrangeAssessment',
      //                   payload: {
      //                     arrangeAssessmentModalVisible: true,
      //                     currentArrangeAssessmentPlan: record,
      //                   },
      //                 });
      //               }}
      //             >
      //               {intl.formatMessage({ id: 'scheduleAssessment' })}
      //             </a>
      //           ) : null;
      //         },
      //       }
      //     : null,
      //   {
      //     showText: intl.formatMessage({ id: 'scheduleSession' }),
      //     itemRender(record) {
      //       const { status } = record;
      //       return status != 'None' &&
      //         status != 'Created' &&
      //         status != 'Ended' &&
      //         !isEnterAdmin() ? (
      //         <a
      //           key="arrange"
      //           onClick={(e) => {
      //             arrangeCourse(e, { userOfflineTrainingPlan: record });
      //           }}
      //         >
      //           {intl.formatMessage({ id: 'scheduleSession' })}
      //         </a>
      //       ) : null;
      //     },
      //   },
      //   {
      //     showText: intl.formatMessage({ id: 'Add Sessions Class' }),
      //     itemRender(record) {
      //       const {
      //         status,
      //         currentNum,
      //         totalNum,
      //         assessmentStatus = [],
      //       } = record;

      //       return status != 'None' &&
      //         status != 'Created' &&
      //         status != 'Ended' &&
      //         assessmentStatus.length > 0 &&
      //         currentNum === totalNum &&
      //         !isEnterAdmin() ? (
      //         <a
      //           key="extendClass"
      //           onClick={(e) => {
      //             extendClass(e, { userOfflineTrainingPlan: record });
      //           }}
      //         >
      //           {intl.formatMessage({ id: 'Add Sessions Class' })}
      //         </a>
      //       ) : null;
      //     },
      //   },
      //   hasOfflinePlanWritePermission &&
      //   rocketProjectStatus &&
      //   isBind &&
      //   !isLogout
      //     ? {
      //         showText: intl.formatMessage({ id: 'delete' }),
      //         itemRender(record) {
      //           const { status, id } = record;
      //           return status === 'None' || status === 'Created' ? (
      //             <Popconfirm
      //               title={
      //                 <span style={{ color: 'red' }}>
      //                   {intl.formatMessage({ id: 'deleteTrainDes' })}
      //                 </span>
      //               }
      //               onConfirm={() => {
      //                 dispatch({
      //                   type: 'subjectOfflinePlan/deleteTrain',
      //                   payload: { record, intl },
      //                 });
      //               }}
      //               okText={intl.formatMessage({ id: 'delete' })}
      //               cancelText={intl.formatMessage({ id: 'cancel' })}
      //             >
      //               <a>{intl.formatMessage({ id: 'delete' })}</a>
      //             </Popconfirm>
      //           ) : null;
      //         },
      //       }
      //     : null,
      //   {
      //     showText: intl.formatMessage({ id: 'more' }),
      //     itemRender(record) {
      //       const { status } = record;
      //       return status != 'None' &&
      //         status != 'Created' &&
      //         status != 'Ended' &&
      //         !isEnterAdmin() ? (
      //         <Dropdown
      //           overlay={
      //             <Menu>
      //               <Menu.Item key="terminateThePlan">
      //                 <div
      //                   style={{
      //                     width: '80px',
      //                     textAlign: 'center',
      //                     color: '#1890ff',
      //                   }}
      //                   onClick={(e) => {
      //                     e.stopPropagation();
      //                     dispatch({
      //                       type: 'subjectOfflinePlan/updateState',
      //                       payload: {
      //                         endPlanModalVisible: true,
      //                         currentEndPlan: record,
      //                       },
      //                     });
      //                   }}
      //                 >
      //                   {intl.formatMessage({ id: 'terminateThePlan' })}
      //                 </div>
      //               </Menu.Item>
      //             </Menu>
      //           }
      //           placement="bottom"
      //         >
      //           <a>{intl.formatMessage({ id: 'more' })}</a>
      //         </Dropdown>
      //       ) : null;
      //     },
      //   },
    ],
  };

  const lookIlsPlan = () => {
    dispatch({
      type: 'subjectOfflinePlan/updateState',
      payload: {
        ilsDrawerVisible: false,
      },
    });
  };

  const getRecord = (key) => {
    const currentKey = key || ilsActiveKey;
    const { id, onlinePlanId } = currentTablePlan || {};
    if (key != 'chart') {
      const pagination = recordPagination[currentKey];
      dispatch({
        type: 'subjectOfflinePlan/getRecord',
        payload: {
          id: currentKey == 'ilsapp' ? onlinePlanId : id,
          type: currentKey,
          current: pagination.current + 1,
          ilsType: key,
        },
      });
    } else {
      dispatch({
        type: 'subjectOfflinePlan/updateState',
        payload: {
          shouldGetData: true,
        },
      });
    }
  };

  const ilsparentPropsData = {
    dataSource: ilsparentRecord,
    loadData: getRecord,
    isLoading: isRecordLoading,
  };

  const ilstrainerPropsData = {
    dataSource: ilstrainerRecord,
    loadData: getRecord,
    isLoading: isRecordLoading,
  };

  const ilsappPropsData = {
    dataSource: ilsappRecord,
    loadData: getRecord,
    isLoading: isRecordLoading,
  };

  const callback = () => {
    dispatch({
      type: 'subjectOfflinePlan/updateState',
      payload: {
        shouldGetData: false,
      },
    });
  };

  const userOfflineTrainingPlanProps = {
    UserOfflineTrainingPlanId: currentTablePlan && currentTablePlan.id,
    shouldGetData,
    Loading,
    callback,
  };

  const userTrainingPlanProps = {
    UserTrainingPlanId: currentTablePlan && currentTablePlan.onlinePlanId,
    shouldGetData,
    Loading,
  };

  return (
    <div style={{ padding: '25px' }}>
      <Collapse ghost>
        <Panel
          header={intl.formatMessage({ id: 'finishedTrainingPlan' })}
          key="1"
          style={{ fontSize: '20px' }}
        >
          {allList.length ? (
            <MagicTable {...listProps} />
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Panel>
      </Collapse>
      <BatchDownloadModal {...batchDownloadModalProps} />
      <Drawer
        open={ilsDrawerVisible}
        width="700"
        onClose={lookIlsPlan}
        title={
          <b>
            {`${currentTablePlan && currentTablePlan.displayName} ` +
              intl.formatMessage({ id: 'chartsAndFeedbackRecords' })}
          </b>
        }
      >
        <Tabs
          type="card"
          activeKey={ilsActiveKey}
          onChange={(key) => {
            dispatch({
              type: 'subjectOfflinePlan/updateState',
              payload: { ilsActiveKey: key },
            });
            switch (key) {
              case 'brain':
                dispatch({
                  type: 'subjectOfflinePlan/getBrainRecord',
                  payload: { id: currentTablePlan && currentTablePlan.id },
                });
                break;
              case 'music':
                dispatch({
                  type: 'subjectOfflinePlan/getMusicRecord',
                  payload: currentTablePlan,
                });
                break;
              default:
                getRecord(key);
            }
          }}
        >
          <TabPane tab={intl.formatMessage({ id: 'chart' })} key="chart">
            <div style={{ width: '80%', margin: 'auto' }}>
              <TrainingStatistics {...userOfflineTrainingPlanProps} />
              {currentTablePlan &&
                currentTablePlan.audioType == 'BrainAudio' &&
                hasOfflinePlanReadPermission && (
                  <BrainLineChart {...userOfflineTrainingPlanProps} />
                )}
              <CourseStatistics {...userOfflineTrainingPlanProps} />
              {/* <RankingList {...userOfflineTrainingPlanProps} />
              <FMA {...userTrainingPlanProps} /> */}
            </div>
          </TabPane>
          <TabPane
            tab={intl.formatMessage({ id: 'trainerFeedback' })}
            key="ilstrainer"
          >
            <AdminClockInRecord {...ilstrainerPropsData} />
          </TabPane>
          <TabPane
            tab={intl.formatMessage({ id: 'overallFeedback' })}
            key="ilsparent"
          >
            <AdminClockInRecord {...ilsparentPropsData} />
          </TabPane>
          <TabPane
            tab={intl.formatMessage({ id: 'parentFeedback' })}
            key="ilsapp"
          >
            <AdminClockInRecord {...ilsappPropsData} />
          </TabPane>
          {hasOfflinePlanReadPermission &&
            currentTablePlan &&
            currentTablePlan.audioType == 'BrainAudio' && (
              <TabPane
                tab={intl.formatMessage({ id: 'HFSMusicRecords' })}
                key="brain"
              >
                <BrainRecord
                  brainRecord={brainRecord}
                  isLoading={brainIsLoading}
                />
              </TabPane>
            )}
          {currentTablePlan && currentTablePlan.audioType == 'SenseAudio' && (
            <TabPane
              tab={intl.formatMessage({ id: 'musicFeedback' })}
              key="music"
            >
              <MusicRecord
                musicRecord={musicRecord}
                isLoading={musicRecordIsLoading}
              />
            </TabPane>
          )}
        </Tabs>
      </Drawer>
    </div>
  );
};

export default connect(({ subjectOfflinePlan, user, loading }) => ({
  subjectOfflinePlan,
  currentUser: user.currentUser,
  loading,
}))(FinishPlanList);

import React, { useEffect } from 'react';
import { Button, Modal } from 'antd';
import { connect, history } from 'umi';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal';
import SortOrderModal from 'components/SortOrderModal';
import styles from './index.less';

const modalTypeToTitle = {
  add: '新建培训模块',
  edit: '编辑培训模块',
};

const TrainingModule = ({ trainingModule, dispatch, loading }) => {
  useEffect(() => {
    dispatch({ type: 'trainingModule/query' });
  }, []);

  const {
    list,
    pagination,
    modalVisible,
    examVisible,
    modalType,
    content,
    sortOrderModal,
    selectItem,
  } = trainingModule;
  const listSupport = {
    name: {
      showText: 'name',
      showType: 'Text',
    },
    displayName: {
      showText: '模块名称',
      showType: 'Text',
    },
    sortOrder: {
      showText: '排序',
      showType: 'Text',
    },
    trainingCourseCount: {
      showText: '课程数',
      showType: 'Text',
    },
    associatedMedal: {
      showText: '关联勋章',
      showType: 'Text',
    },
    examination: {
      showText: '关联考试',
      showType: 'Text',
      render: (text, record) => {
        if (!text) {
          return '-';
        } else {
          return text.name;
        }
      },
    },
    hasQuestionnaires: {
      showText: '培训反馈问卷',
      showType: 'Text',
    },
    hasOralQuestionnaires: {
      showText: '口试问卷',
      showType: 'Text',
    },
    hasPracticalQuestionnaires: {
      showText: '实操问卷',
      showType: 'Text',
    },
    inUse: {
      showText: '状态',
      showType: 'Text',
      render: (text, record) => {
        return text ? '有效' : '无效';
      },
    },
    // price: {
    //     showText: '自助缴费单价',
    //     showType: 'Text',
    // }
  };

  const tableProps = {
    listData: list.map((item) => {
      const { questionnaires = [], oralQuestionnaireId, practicalQuestionnaireId = [] } =
        item || {};
      return {
        ...item,
        associatedMedal:
          item.qualificationMedals && item.qualificationMedals.length > 0
            ? item.qualificationMedals[0].displayName
            : '',
        trainingCourseCount: item.courses.length,
        hasQuestionnaires: questionnaires.length > 0 ? '有' : '无',
        hasOralQuestionnaires: oralQuestionnaireId ? '有' : '无',
        hasPracticalQuestionnaires:
          practicalQuestionnaireId && practicalQuestionnaireId.length > 0
            ? '有'
            : '无',
      };
    }),
    pagination,
    listSupport,
    loading: loading.models.trainingModule,
    onTableChange(pagi, filt = {}, sorter = {}) {
      let data = { pagination };
      if (Object.keys(pagi).length !== 0) data = { ...data, pagination: pagi };
      if (Object.keys(filt).length !== 0) data = { ...data, filters: filt };
      dispatch({ type: 'trainingModule/changeTable', payload: data });
    },
    Actions: [
      {
        showText: '考试二维码',
        itemRender: (record) => {
          const { examination } = record || {};
          return examination ? (
            <a
              onClick={() => {
                dispatch({
                  type: 'trainingModule/weixinExam',
                  payload: { selectItem: record },
                });
              }}
            >
              考试二维码
            </a>
          ) : null;
        },
      },
      // {
      //     showText: '关联考试',
      //     onClick(record) {
      //         dispatch({ type: 'trainingModule/onRelation', payload: { selectItem: record } })
      //     },
      // },
      {
        showText: '培训模块',
        onClick(record) {
          history.push({
            pathname: '/system/manage/courseCategoryDetail',
            query: {
              id: record.id,
              type: 'trainingModule',
            },
          });
        },
      },
      {
        showText: '编辑',
        onClick(record) {
          dispatch({ type: 'trainingModule/onEdit', payload: record });
        },
      },
      {
        showText: '排序',
        onClick(record) {
          dispatch({
            type: 'trainingModule/updateState',
            payload: { selectItem: record, sortOrderModal: true },
          });
        },
      },
      {
        showText: '切换状态',
        itemRender: (record) => {
          const { inUse } = record || {};
          return (
            <a
              onClick={() => {
                Modal.confirm({
                  title: (
                    <span style={{ color: 'red' }}>{`确定${
                      inUse ? '停用' : '启用'
                    }此培训模块吗？`}</span>
                  ),
                  icon: <ExclamationCircleOutlined />,
                  content: '',
                  okText: inUse ? '停用' : '启用',
                  onOk() {
                    dispatch({
                      type: 'trainingModule/onChangeStatus',
                      payload: record,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              切换状态
            </a>
          );
        },
      },
      // {
      //     showText: '删除',
      //     onClick(record) {
      //         Modal.confirm({
      //             title: <b style={{ color: 'red' }}>确定删除吗？</b>,
      //             onOk() {
      //                 dispatch({ type: 'trainingModule/onDelete', payload: record })
      //             }
      //         })
      //     },
      // }
    ],
  };

  const modalProps = {
    title: modalTypeToTitle[modalType],
    visible: modalVisible,
    content,
    onCancel() {
      dispatch({
        type: 'trainingModule/updateState',
        payload: { modalVisible: false },
      });
    },
    onOk(value) {
      dispatch({ type: 'trainingModule/onSubmitModal', payload: value });
    },
  };

  const relationModalProps = {
    title: '关联考试',
    visible: examVisible,
    content,
    onCancel() {
      dispatch({
        type: 'trainingModule/updateState',
        payload: { examVisible: false },
      });
    },
    onOk(value) {
      const { examination } = value;
      dispatch({
        type: 'trainingModule/onExamSubmitModal',
        payload: examination,
      });
    },
  };

  const sortModalProps = {
    visible: sortOrderModal,
    onCancel() {
      dispatch({
        type: 'trainingModule/updateState',
        payload: { sortOrderModal: false },
      });
    },
    onOk(data) {
      dispatch({
        type: 'trainingModule/changeSortOrder',
        payload: data.sortOrder,
      });
    },
    currentValue: selectItem && selectItem.sortOrder,
  };

  const onAdd = () => {
    dispatch({ type: 'trainingModule/onAdd' });
  };

  return (
    <div className={styles.trainingModule}>
      <div>培训模块</div>
      <Button onClick={onAdd}>新建培训模块</Button>
      <MagicTable {...tableProps} />
      <MagicFormModal {...modalProps} />
      <MagicFormModal {...relationModalProps} />
      <SortOrderModal {...sortModalProps} />
    </div>
  );
};

export default connect(({ trainingModule, loading }) => ({
  trainingModule,
  loading,
}))(TrainingModule);

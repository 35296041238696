import React, { useState, useEffect } from 'react';

import { message, Card, Input } from 'antd';
import { useIntl, history, connect } from 'umi';

import { GetTrialList } from 'services/trialPlan';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect';
import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

import { SHOWTYPE } from '../../Center/Detail/SubjectDetail/models/subjectDetail';

import { showAttributeName } from 'utils/feedback';

import moment from 'moment';

const { Search } = Input;

const timeFormat = 'MM/DD/YYYY HH:mm';

const TrialSessionList = ({ currentUser }) => {
  const intl = useIntl();

  const { adminRoles, current, canChangeList } = currentUser;
  const isAdmin = adminRoles.length > 0 && current === null;

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [keyword, setKeyword] = useState('');

  const [currentTenant, setCurrentTenant] = useState({
    id: '',
    displayName: intl.formatMessage({ id: 'statisticsTotal' }),
  });

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const listSupport = {
    creationTime: {
      showText: intl.formatMessage({ id: 'creationTime' }),
      showType: 'Time',
      addtional: {
        format: timeFormat,
      },
    },
    operator: {
      showText: intl.formatMessage({ id: 'Operator' }),
      showType: 'Text',
    },
    trainingDate: {
      showText: intl.formatMessage({ id: 'Training Date' }),
      showType: 'Text',
    },
    userName: {
      showText: intl.formatMessage({ id: 'subject' }),
      showType: 'Text',
    },
    types: {
      showText: intl.formatMessage({ id: 'Types' }),
      showType: 'Text',
    },
    programs: {
      showText: intl.formatMessage({ id: 'Programs' }),
      showType: 'Text',
    },
    percent: {
      showText: intl.formatMessage({ id: 'musicProgress' }),
      showType: 'Text',
      render: (text) => Math.round(text * 100) + '%',
    },
    trained: {
      showText: intl.formatMessage({ id: 'Is Training Trained' }),
      showType: 'Text',
      render: (text) => intl.formatMessage({ id: text ? 'yes' : 'no' }),
    },
  };

  const changedTenantList = (id) => {
    if (id) {
      const newCurrentTenant = canChangeList.find((item) => item.id == id);
      setCurrentTenant({ ...newCurrentTenant });
    } else {
      setCurrentTenant({
        id: '',
        displayName: intl.formatMessage({ id: 'statisticsTotal' }),
      });
    }
  };

  const initData = async () => {
    setLoading(true);

    const { current, pageSize } = pagination;
    const res = await GetTrialList({
      SkipCount: (current - 1) * pageSize,
      MaxResultCount: pageSize,
      Filter: keyword,
      TenantId: currentTenant.id,
    });

    if (res.response.ok) {
      const { items, totalCount } = res.data;
      setDataSource(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    initData();
  }, [pagination.current, pagination.pageSize, currentTenant.id, keyword]);

  const tableProps = {
    listData: dataSource.map((item) => {
      const { items, sessionFeedbacks } = item || {};
      const findData =
        sessionFeedbacks.find(
          (i) => i.programFeedbackProperty.name === 'courseTime',
        ) || {};

      const { value: courseTimeValue } = findData || {};

      return {
        ...item,
        types: items.length,
        trainingDate:
          courseTimeValue && moment(courseTimeValue).format(timeFormat),
        programs: items.reduce((accumulator, currentValue) => {
          const { trialPlanItems = [] } = currentValue || {};
          return accumulator + trialPlanItems.length;
        }, 0)
      };
    }),
    listSupport,
    loading,
    pagination,
    onTableChange(page) {
      const { current, pageSize } = page;
      setPagination({
        ...pagination,
        current,
        pageSize,
      });
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'detail' }),
        itemRender(record) {
          const { userId } = record;
          return (
            <a
              onClick={() => {
                history.push({
                  pathname: isAdmin
                    ? '/system/manage/subjectDetail'
                    : '/system/center/detail/subjectDetail',
                  query: {
                    id: userId,
                    tab: SHOWTYPE.TrialSession,
                  },
                });
              }}
            >
              {intl.formatMessage({ id: 'detail' })}
            </a>
          );
        },
      },
    ],
  };

  return (
    <Card
      title={
        <b style={{ fontSize: '20px' }}>
          {intl.formatMessage({ id: 'menu.TrainingPlanList' })}
        </b>
      }
      extra={
        isAdmin && (
          <AnalysisTenantSelect
            showAll={true}
            currentTenant={currentTenant}
            onChange={changedTenantList}
          />
        )
      }
      bordered={false}
    >
      <Search
        placeholder={intl.formatMessage({ id: 'trialListSearch' })}
        allowClear
        onSearch={(value) => setKeyword(value)}
        style={{ width: 200, margin: '0 30px 10px 0' }}
      />
      <p />
      <MagicTable {...tableProps} />
    </Card>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  TrialSessionList,
);

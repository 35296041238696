import React, { useState, useEffect } from 'react';
import { history, useIntl, connect } from 'umi';
import { Button, Space, Card, Spin } from 'antd';
import KeyIndicators from './KeyIndicators';
import ContractInfo from './ContractInfo';

import { GetKeyIndicators } from 'services/userContract';

const UserContractDetail = ({ currentUser, location }) => {
  const intl = useIntl();

  const { adminRoles, current } = currentUser;
  const isAdmin = adminRoles.length > 0 && current === null;

  const { name, userId } = location.query || {};

  const [keyIndicatorsData, setKeyIndicatorsData] = useState({});

  const initData = async (callback) => {
    const res = await GetKeyIndicators({ userId });
    if (res.response.ok) {
      const { contracts = [] } = res.data || {};
      setKeyIndicatorsData({
        ...res.data,
        contractCount: contracts.length,
      });
      callback && callback(contracts);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  if (Object.keys(keyIndicatorsData).length === 0) {
    return (
      <div
        style={{
          minHeight: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    );
  }

  const props = {
    userData: {
      name,
      userId,
    },
    isAdmin,
    keyIndicatorsData,
    reloadPage: initData,
  };

  return (
    <Card
      bordered={false}
      title={
        <Space>
          <Button onClick={() => history.goBack()}>
            {intl.formatMessage({ id: 'back' })}
          </Button>
          <b style={{ fontSize: '20px' }}>
            {intl.formatMessage({ id: 'User Contract Details' })} —— {name}
          </b>
        </Space>
      }
    >
      <div style={{ padding: '15px' }}>
        <KeyIndicators {...props} />
        <ContractInfo {...props} />
      </div>
    </Card>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  UserContractDetail,
);

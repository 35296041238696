import React, { useState, useEffect, useRef } from 'react';
import { history, useIntl } from 'umi';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Space, message, Modal, Card, Input } from 'antd';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';
import {
  GetProgramGamification,
  PutGamificationStatus,
  PutTrainingProgramSuggestion,
  PostTrainingProgramSuggestion,
} from 'services/suggestion';

import moment from 'moment';

const { confirm } = Modal;
const { TextArea } = Input;

const dateFormat = 'MM/DD/YYYY HH:mm';

const listSupport = {
  creationTime: {
    showText: '提交时间',
    showType: 'Time',
    addtional: {
      format: dateFormat,
    },
  },
  content: {
    showText: '提交内容',
    showType: 'Remark',
    addtional: {
      showLength: 10,
    },
  },
  creator: {
    showText: '提交人',
    showType: 'Text',
  },
  valid: {
    showText: '状态',
    showType: 'Text',
    render: (text, record) => {
      return text ? '有效' : '无效';
    },
  },
  likes: {
    showText: 'Likes',
    showType: 'Text',
    render: (text) => text.length,
  },
};

const SuggestionDetail = ({ location }) => {
  const intl = useIntl();

  const { id, title } = location.query || {};

  const editContent = useRef('');

  const [listData, setListData] = useState([]);
  const [currentItem, setCurrentItem] = useState({});

  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
    modalLoading: false,
    gamificationLoading: false,
  });

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const res = await GetProgramGamification({
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
      TrainingProgramId: id,
    });

    if (res.response.ok) {
      const { totalCount, items } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current]);

  const tableProps = {
    listData,
    listSupport,
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: '查看',
        itemRender(record) {
          return (
            <a
              onClick={(e) => {
                e.stopPropagation();
                onView(record);
              }}
            >
              查看
            </a>
          );
        },
      },
      {
        showText: '切换状态',
        itemRender(record) {
          const { valid, id } = record || {};
          return (
            <a
              onClick={async (e) => {
                e.stopPropagation();
                const res = await PutGamificationStatus({
                  valid: !valid,
                  id,
                });

                if (res.response.ok) {
                  message.success('切换成功');
                  initData();
                } else {
                  const {
                    data: { error },
                  } = res;
                  message.error(error.message);
                }

                // 二次确认弹窗
                // Modal.confirm({
                //   title: (
                //     <span style={{ color: 'red' }}>{`确定${
                //       valid ? '停用' : '启用'
                //     }此培训模块吗？`}</span>
                //   ),
                //   icon: <ExclamationCircleOutlined />,
                //   content: '',
                //   okText: valid ? '停用' : '启用',
                //   onOk: async () => {
                //     const res = await PutGamificationStatus({
                //       valid: !valid,
                //       id,
                //     });

                //     if (res.response.ok) {
                //       initData();
                //     } else {
                //       const {
                //         data: { error },
                //       } = res;
                //       message.error(error.message);
                //     }
                //   },
                //   onCancel() {},
                // });
              }}
            >
              切换状态
            </a>
          );
        },
      },
    ],
  };

  const onView = (record) => {
    const { content, updater, updateTime } = record || {};

    confirm({
      title: 'Gamification',
      width: 600,
      icon: <></>,
      content: (
        <>
          <div style={{ margin: '12px 0' }}>{content}</div>
          {updater && (
            <div style={{ color: 'grey' }}>
              {updater} {intl.formatMessage({ id: 'Edited' })}{' '}
              {moment(updateTime).format(dateFormat)}
            </div>
          )}
        </>
      ),
      okText: '编辑',
      onOk() {
        onEdit(record);
      },
      onCancel() {},
    });
  };

  const onEdit = (record, type = 'edit') => {
    const { content } = record || {};

    confirm({
      title: `游戏化建议 - ${title}`,
      width: 600,
      icon: <></>,
      content: (
        <TextArea
          rows={4}
          defaultValue={content}
          placeholder="欢迎对训练项目内容提出建议..."
          onChange={(e) => {
            editContent.current = e.target.value;
          }}
        />
      ),
      okText: 'Submit',
      confirmLoading: loading.gamificationLoading,
      onOk: () => onEditSubmit(record, type),
      onCancel() {},
    });
  };

  const onEditSubmit = async (record, type) => {
    setLoading({
      ...loading,
      gamificationLoading: true,
    });

    let res;
    if (type == 'add') {
      res = await PostTrainingProgramSuggestion({
        content: editContent.current || content,
        trainingProgramId: id,
        type: 'Gamification',
      });
    } else {
      const { id, content } = record || {};

      res = await PutTrainingProgramSuggestion({
        content: editContent.current || content,
        id,
      });
    }

    if (res.response.ok) {
      initData();
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading({
      ...loading,
      gamificationLoading: false,
    });
  };

  return (
    <Card
      bordered={false}
      title={
        <Space>
          <Button onClick={() => history.goBack()}>返回</Button>
          <div>{title}</div>
        </Space>
      }
      extra={
        <Button type="primary" onClick={() => onEdit('', 'add')}>
          添加
        </Button>
      }
    >
      <p />
      <MagicTable {...tableProps} />
    </Card>
  );
};

export default SuggestionDetail;

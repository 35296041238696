import { Button, Input, Table, DatePicker, Spin, Row, Col, Divider, Space, message } from 'antd'
import DocumentTitle from 'react-document-title'
import moment from 'moment'
import { useEffect } from 'react'
import { connect, useIntl, history } from 'umi'
import { getFormatDate, getFormatShowText } from 'utils/utils'
import MagicFormModal from 'cognitiveleap-core-us/components/MagicFormModal/MagicFormModal'
import PaginationCom from 'components/Pagination/Pagination'
import styles from '../center.less'
import myStyles from './index.less'
import { GetEnoughVratNum } from 'services/vrat';

const { RangePicker } = DatePicker
const Search = Input.Search

const defaultPagination = {
  pageIndex: 1,
  pageSize: 12,
}

const TestList = ({ testList, dispatch, currentUser }) => {

  const intl = useIntl()

  useEffect(() => {
    dispatch({ type: 'testList/fetch', payload: {} });
    return () => {
      dispatch({ type: 'testList/stopInterval' });
    };
  }, [])

  const {
    requestList,
    isLoading,
    pageIndex,
    Status,
    total,
    pageSize,
    searchField,
    StartTime,
    StartTimeGenerate,
    EndTimeGenerate,
    EndTime,
    OrderBySorter,
    content,
    modalVisible,
    btnLoading,
    currectRecord
  } = testList

  const pageInfo = { pageIndex, total, pageSize }
  const { auth, changedTenant, current } = currentUser
  const { projectStatus, institutionType, reportTypes = [], medicalPublicWithName = false, reportKinds = [] } = changedTenant || {};
  const { grantedPolicies } = auth
  const { roles = [] } = current || {}

  const hasLookDetailPermission = grantedPolicies['VrClassroom.Center'] === true
  const hasAddVRATCasePermission = grantedPolicies['VrClassroom.TestRequest.Create'] === true
  const hasQRCodePermission = grantedPolicies['VrClassroom.TestRequest.Start'] === true
  const ShowRangeTime = [StartTime ? moment(StartTime) : null, EndTime ? moment(EndTime) : null]
  const ShowReportRangeTime = [StartTimeGenerate ? moment(StartTimeGenerate) : null, EndTimeGenerate ? moment(EndTimeGenerate) : null]
  const vratProjectStatus = projectStatus.find(item => item.name == 'Project.Vrat')['status'];

  const isEnterpriseAgent = roles.some(item => item.name === 'EnterpriseAgent')

  const onTimePicker = value => {
    let StartTime, EndTime
    if (value && value.length > 0) {
      StartTime = getFormatDate(value[0], true)
      EndTime = getFormatDate(value[1], false)
    } else {
      StartTime = null
      EndTime = null
    }
    dispatch({ type: 'testList/fetch', payload: { StartTime, EndTime, ...defaultPagination } })
  }

  const onReportTimePicker = value => {
    let StartTimeGenerate, EndTimeGenerate
    if (value && value.length > 0) {
      StartTimeGenerate = getFormatDate(value[0], true)
      EndTimeGenerate = getFormatDate(value[1], false)
    } else {
      StartTimeGenerate = null
      EndTimeGenerate = null
    }
    dispatch({ type: 'testList/fetch', payload: { StartTimeGenerate, EndTimeGenerate, ...defaultPagination } })
  }

  const onClear = () => {
    dispatch({ type: 'testList/clearAllFilterAndSorter' })
  }

  const searchProps = {
    placeholder: `${intl.formatMessage({ id: 'search' })}: ${intl.formatMessage({
      id: 'subjectName',
    })}, ${intl.formatMessage({ id: 'caseId' })}`,
    style: { width: 300 },
    onSearch(value) {
      dispatch({ type: 'testList/fetch', payload: { searchField: value, ...defaultPagination } })
    },
    onChange(value) {
      dispatch({ type: 'testList/changeSearch', payload: value.target.value })
    },
    value: searchField,
  }


  const onPreventDefault = record => {
    dispatch({
      type: 'testList/downloadReport',
      payload: { record, intl },
    })
  }

  const onViewReport2 = record => {
    dispatch({
      type: 'testList/viewReport2',
      payload: record,
    })
  }

  const onMedicalVratReport = record => {
    dispatch({
      type: 'testList/medicalReportView',
      payload: record,
    })
  }

  const getReport = record => {
    dispatch({
      type: 'testList/getReport',
      payload: { record }
    })
  }

  const onMedicalChnVratReport = record => {
    dispatch({
      type: 'testList/medicalChnReportView',
      payload: record,
    })
  }

  const onParentEditionReport = (record, model) => {
    dispatch({
      type: 'testList/ParentEditionReportView',
      payload: {
        record,
        model
      },
    })
  }

  const onEducationEditionReport = record => {
    message.info('暂未完成')
    return
  }

  const onClinicianEditionReport = record => {
    message.info('暂未完成')
    return
  }

  const onGoToDetail = record => {
    const requestId = record.key
    dispatch({
      type: 'testList/goToTestDetail',
      payload: requestId,
    })
  }

  const onTestSwitch = (text, record, index) => {
    switch (record.Status.props.children) {
      case intl.formatMessage({ id: 'reportReady' }):
        return (
          <a onClick = {(e) => {
              e.stopPropagation()
              getReport(record)
            }}
          >
            {intl.formatMessage({ id: 'Acquire Report' })}
          </a>
        )
      case intl.formatMessage({ id: 'reportAcquired' }):
        return (
          <Space split={<Divider type="vertical" />} size={0.1} >
            {institutionType !== 'Education_CHN' && <>
              <a onClick={() => onViewReport2(record)}>{intl.formatMessage({ id: 'view' })}</a>
              <a onClick={() => onPreventDefault(record)}>{intl.formatMessage({ id: 'downloadPDF' })}</a>
            </>}
            {institutionType === 'Education_CHN' && <a onClick={() => onMedicalChnVratReport(record)}>{intl.formatMessage({ id: 'medicalVratView' })}</a>}
            {/* {reportKinds.includes('ClinicianEdition') && <a onClick={() => onClinicianEditionReport(record)}>{intl.formatMessage({ id: 'Clinician Edition' })}</a>}
            {reportKinds.includes('EducationEdition') && <a onClick={() => onEducationEditionReport(record)}>{intl.formatMessage({ id: 'Educator Edition' })}</a>} */}
            {reportKinds.includes('ParentEdition') && <a onClick={() => onParentEditionReport(record)}>{intl.formatMessage({ id: 'Parent Edition' })}</a>}
            {reportKinds.includes('ParentScreenEdition') && <a onClick={() => onParentEditionReport(record, 'screen')}>{intl.formatMessage({ id: 'Parent Screen Edition' })}</a>}
          </Space>
        )
      case intl.formatMessage({ id: 'testFailed' }):
        return <span>{intl.formatMessage({ id: 'noReport' })}</span>
      default:
        return (
          <span>
            <span>{intl.formatMessage({ id: 'noReport' })}</span>
          </span>
        )
    }
  }

  const modalProps = {
    content,
    title: intl.formatMessage({ id: 'vratTest' }),
    visible: modalVisible,
    onCancel() {
      dispatch({ type: 'testList/save', payload: { modalVisible: false } })
    },
    onOk(value) {
      dispatch({ type: 'testList/submitAddTest', payload: { value, intl } })
    }
  }

  const regenerate = (record) => {
    dispatch({
      type: 'testList/regenerate',
      payload: record,
    })
  }

  const reReport = record => {
    dispatch({
      type: 'testList/regenerationReport',
      payload: { record, intl }
    })
  }

  const getCode = (record, type) => {
    dispatch({
      type: 'testList/getCode',
      payload: {
        intl,
        record,
        type
      },
    })
  }

  const onRenderMore = (text, record, index) => {
    const { status, Subject, Case } = record || {}
    //const { hasLookDetailPermission, hasAddVRATCasePermission, vratProjectStatus, hasQRCodePermission } = this.props
    const { IsBind, IsLogout } = Subject || {}
    return <span>
      {
        hasLookDetailPermission ?
          <a onClick={(e) => {
            e.stopPropagation()
            onGoToDetail(record)
          }
          }>
            {intl.formatMessage({ id: 'detail' })}
          </a>
          : null
      }
      {
        hasAddVRATCasePermission && vratProjectStatus &&
        <span>
          <Divider type="vertical" />
          <a onClick={(e) => {
            e.stopPropagation()
            dispatch({ type: 'testList/addTest', payload: record })
          }}>
            {intl.formatMessage({ id: 'edit' })}
          </a>
        </span>
      }
      {
        hasAddVRATCasePermission && vratProjectStatus && status === "Failed" && !IsLogout &&
        <span>
          <Divider type="vertical" />
          <a onClick={(e) => {
            e.stopPropagation()
            regenerate(record)
          }}>
            {intl.formatMessage({ id: 'recreate' })}
          </a>
        </span>
      }
      {
        hasLookDetailPermission && status === "UnTested" && hasQRCodePermission && !IsLogout &&
        <span>
          <Divider type="vertical" />
          <a onClick={(e) => {
            e.stopPropagation()
            getCode(record, 'copy')
          }}>
            {intl.formatMessage({ id: 'copyTestCode' })}
          </a>
        </span>
      }
      {
        Case?.IsDataPersisted && vratProjectStatus && status === 'Tested' &&
        <span>
        <Divider type="vertical" />
          <a onClick={(e) => {
            e.stopPropagation()
            reReport(record)
          }}>
            {intl.formatMessage({ id: 'Regenerate Report' })}
          </a>
        </span>
      }
    </span>
  }

  const onPageChange = (pageIndex, pageSize) => {
    dispatch({ type: 'testList/fetch', payload: { pageIndex, pageSize } })
  }

  const numToStatus = num => {
    switch (num) {
      case 'UnTested':
        return <span>{intl.formatMessage({ id: 'untested' })}</span>
      case 'Testing':
        return <span>{intl.formatMessage({ id: 'testing' })}</span>
      case 'DataUploading':
        return <span>{intl.formatMessage({ id: 'dataUploading' })}</span>
      case 'ReportGenerating':
        return <span>{intl.formatMessage({ id: 'reportGenerating' })}</span>
      case 'ReportReady':
        return <span>{intl.formatMessage({ id: 'reportReady' })}</span>
      case 'ReportAcquired':
        return <span>{intl.formatMessage({ id: 'reportAcquired' })}</span>
      case 'Failed':
        return <span>{intl.formatMessage({ id: 'testFailed' })}</span>
      case 'TestAbnormal':
        return <span>{intl.formatMessage({ id: 'testAbnormal' })}</span>
      case 'ReportGenerationFailed':
        return <span>{intl.formatMessage({ id: 'reportGenerationFailed' })}</span>
      default:
        return <span></span>
    }
  }

  const numToGender = num => {
    switch (num) {
      case 'Unknown':
        return <span>{intl.formatMessage({ id: 'unknown' })}</span>
      case 'Male':
        return <span>{intl.formatMessage({ id: 'male' })}</span>
      case 'Female':
        return <span>{intl.formatMessage({ id: 'female' })}</span>
      case 'Others':
        return <span>{intl.formatMessage({ id: 'other' })}</span>
      default:
        return <span></span>
    }
  }

  const expendRender = record => {
    return (
      <Row>
        <Col span={4}>
          <div>
            <span>{intl.formatMessage({ id: 'testLocation' })}:</span>
          </div>
          <div>&nbsp;{record.Location}</div>
        </Col>
        {/* <Col span={6}>
          <div className={styles.rowExpandTitle}>
            <span>{intl.formatMessage({ id: 'birthday' })}:</span>
          </div>
          <div className={styles.rowExpandDetail}>&nbsp;{record.BirthDay}</div>
        </Col> */}
        <Col span={4}>
          <div>
            <span>{intl.formatMessage({ id: 'testAge' })}:</span>
          </div>
          <div>&nbsp;{record.TestAge}</div>
        </Col>
        <Col span={4}>
          <div>
            <span>{intl.formatMessage({ id: 'gender' })}: </span>
          </div>
          <div>&nbsp;{record.Gender.props.children}</div>
        </Col>
        <Col span={6}>
          <div>
            <span>{intl.formatMessage({ id: 'attentionIndex' })}:</span>
          </div>
          <div>&nbsp;{record.AttentionIndex}</div>
        </Col>
        <Col span={6}>
          <div>
            <span>{intl.formatMessage({ id: 'motionIndex' })}:</span>
          </div>
          <div>&nbsp;{record.MotionIndex}</div>
        </Col>
      </Row>
    )
  }

  const handleTablechange = (p, f, s) => {
    const Status = !f.Status ? [] : f.Status
    const OrderBy = !s.order
      ? null
      : {
        PropertyName: s.field,
        IsDESC: s.order === 'descend' ? 'descend' : 'ascend',
      }
    dispatch({
      type: 'testList/fetch',
      payload: { Status, OrderBySorter: OrderBy, ...defaultPagination },
    })
  }


  const showTextList = getFormatShowText(intl, [
    'createdTime',
    'subject',
    'status',
    'untested',
    'testing',
    'dataUploading',
    'reportGenerating',
    'reportReady',
    'reportAcquired',
    'testFailed',
    'testAbnormal',
    'reportGenerationFailed',
    'report',
    // 'parentalAdvice',
    'reportedDate',
    'action'
  ])

  let TimeOrder
  let NameOrder
  if (!OrderBySorter) {
    TimeOrder = false
    NameOrder = false
  } else {
    if (OrderBySorter.PropertyName === 'Name') {
      TimeOrder = false
      NameOrder = OrderBySorter.IsDESC
    } else if (OrderBySorter.PropertyName === 'RequestTime') {
      NameOrder = false
      TimeOrder = OrderBySorter.IsDESC
    } else {
      TimeOrder = false
      NameOrder = false
    }
  }

  const columns = isEnterpriseAgent ? [
    {
      title: showTextList.createdTime,
      dataIndex: 'RequestTime',
      sorter: true,
      sortOrder: TimeOrder,
    },
    {
      title: showTextList.caseId,
      dataIndex: 'caseId',
    },
    {
      title: showTextList.testLocation,
      dataIndex: 'Location',
    },
    {
      title: showTextList.status,
      dataIndex: 'Status',
      filters: [
        {
          text: showTextList.untested,
          value: 'UnTested',
        },
        {
          text: showTextList.testing,
          value: 'Testing',
        },
        {
          text: showTextList.dataUploading,
          value: 'DataUploading',
        },
        {
          text: showTextList.reportGenerating,
          value: 'ReportGenerating',
        },
        {
          text: showTextList.reportReady,
          value: 'ReportReady',
        },
        {
          text: showTextList.reportAcquired,
          value: 'ReportAcquired',
        },
        {
          text: showTextList.failed,
          value: 'TestFailed'
        },
        {
          text: showTextList.testAbnormal,
          value: 'TestAbnormal'
        },
        {
          text: showTextList.reportGenerationFailed,
          value: 'ReportGenerationFailed'
        }
      ],
      filteredValue: Status,
    },
    {
      title: showTextList.reportedDate,
      dataIndex: 'lastModificationTime',
    },
  ] : [
    {
      title: showTextList.createdTime,
      dataIndex: 'RequestTime',
      sorter: true,
      sortOrder: TimeOrder,
    },
    {
      title: showTextList.clinicNumber,
      dataIndex: 'OutpatientNumber',
      sorter: false,
      sortOrder: NameOrder,
      isHidden: !medicalPublicWithName
    },
    {
      title: (institutionType !== 'Medical_Public' || medicalPublicWithName) ? showTextList.subject : showTextList.clinicNumber,
      dataIndex: 'Name',
      sorter: false,
      sortOrder: NameOrder,
    },
    {
      title: showTextList.status,
      dataIndex: 'Status',
      filters: [
        {
          text: showTextList.untested,
          value: 'UnTested',
        },
        {
          text: showTextList.testing,
          value: 'Testing',
        },
        {
          text: showTextList.dataUploading,
          value: 'DataUploading',
        },
        {
          text: showTextList.reportGenerating,
          value: 'ReportGenerating',
        },
        {
          text: showTextList.reportReady,
          value: 'ReportReady',
        },
        {
          text: showTextList.reportAcquired,
          value: 'ReportAcquired',
        },
        {
          text: showTextList.testFailed,
          value: 'Failed'
        },
        {
          text: showTextList.testAbnormal,
          value: 'TestAbnormal'
        },
        {
          text: showTextList.reportGenerationFailed,
          value: 'ReportGenerationFailed'
        }
      ],
      filteredValue: Status,
    },
    {
      title: showTextList.report,
      dataIndex: 'Report',
      render: (text, record, index) => onTestSwitch(text, record, index),
    },
    {
      title: showTextList.reportedDate,
      dataIndex: 'lastModificationTime',
    },
    {
      title: showTextList.action,
      dataIndex: 'Action',
      render: (text, record, index) => onRenderMore(text, record, index),
    },
  ].filter(item => !item.isHidden)

  // const testListComProps = {
  //   record: requestList ? requestList : [],
  //   pageInfo,
  //   Status,
  //   OrderBySorter,
  //   dispatch,
  //   hasLookDetailPermission,
  //   hasAddVRATCasePermission,
  //   vratProjectStatus,
  //   hasQRCodePermission
  // }

  const data = requestList.map(item => {
    const RequestTime = moment(item.RequestTime)
      .local()
      .format('MM/DD/YYYY HH:mm')
    const birthDay = moment.utc(item.Subject.BirthDay).format('MM/DD/YYYY')
    const TestAge = moment(item.RequestTime).diff(moment(item.Subject.BirthDay), 'years')

    const testCase = item.Case
    const { OverView } = testCase
    const { TestLocation, LastModificationTime, Case } = item || {}
    const { Reports, TargetReportVersion } = Case || {}
    let GenerateTime = ""
    if (Reports && Reports.length > 0) {
      GenerateTime = Reports.find(report => report?.Version === TargetReportVersion)?.GenerateTime
      Reports.forEach(item => {
        if (moment(item.GenerateTime).isSameOrAfter(GenerateTime)) {
          GenerateTime = item.GenerateTime
        }
      })
    }
    return {
      ...item,
      key: item.Id,
      RequestTime: RequestTime,
      status: item.Status,
      Status: numToStatus(item.Status),
      TestAge,
      BirthDay: birthDay,
      Gender: numToGender(item.Subject.Gender),
      Name: item.Subject.Name,
      Phone: item.Subject.Phone,
      EmailAddress: item.Subject.EmailAddress,
      SubjectId: item.Subject.Id,
      caseId: item.Case.Id,
      // parentalAdvice: item.Case.registerTimeForWix,
      Location: TestLocation ? TestLocation.Name : '',
      AttentionIndex: OverView ? (OverView.AttentionPerformanceIndex * 100).toFixed(0) : '',
      MotionIndex: OverView ? (OverView.MotionPerformanceIndex * 100).toFixed(0) : '',
      lastModificationTime: GenerateTime && moment(GenerateTime).isValid() ? moment(GenerateTime).format('YYYY/MM/DD HH:mm') : '',
    }
  })

  const paginationComProps = {
    onPageChange: onPageChange,
    ...pageInfo,
  }

  const exportExcel = () => {
    dispatch({
      type: 'testList/exportExcel',
      payload: {
        currentUser
      }
    })
  }

  const addEasyVrat = async () => {
    const res = await GetEnoughVratNum();
    if (res.response.ok) {
      if (res.data) {
        history.push('/system/vratProcess')
      } else {
        message.error(intl.formatMessage({ id: 'addVratError' }))
      }
    } else {
      const { error } = res.data
      message.error(error.message)
    }
  }

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'testList.title' })}>
      <div>
        <div className={myStyles.header}>
          <h1 className={styles.pageName}>{intl.formatMessage({ id: 'testList.title' })}</h1>
          <Button onClick={addEasyVrat} type="primary" >{intl.formatMessage({ id: 'subject.newVRATTest' })}</Button>
        </div>
        <Divider />
        <div className={styles.table}>
          <div className={myStyles.search}>
            <Search {...searchProps} />
            &nbsp;&nbsp;
            <Space>
              <div>
                {showTextList.createdTime}:
              </div>
              <RangePicker onChange={onTimePicker} value={ShowRangeTime} className={myStyles.searchPicker} />
            </Space>
            &nbsp;&nbsp;
            <Space>
              <div>
                {showTextList.reportedDate}:
              </div>
              <RangePicker onChange={onReportTimePicker} value={ShowReportRangeTime} className={myStyles.searchPicker} />
            </Space>
            <Button onClick={onClear} className={myStyles.searchButton}>{intl.formatMessage({ id: 'clear' })}</Button>
            <Button loading={btnLoading} onClick={exportExcel} disabled={data.length === 0} className={myStyles.searchButton}>{intl.formatMessage({ id: 'exportList' })}</Button>

          </div>
          <Spin spinning={isLoading}>
            <Table
              pagination={false}
              onChange={handleTablechange}
              expandRowByClick={true}
              expandedRowRender={record => expendRender(record)}
              columns={columns}
              dataSource={data}
            />
            <div>
              <PaginationCom {...paginationComProps} />
            </div>
          </Spin>
        </div>
        <MagicFormModal {...modalProps} />
      </div>
    </DocumentTitle>
  )
}
export default connect(({ testList, user }) => ({ testList, currentUser: user.currentUser }))(TestList)

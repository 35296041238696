
import React from 'react'
import { connect, getDvaApp } from 'umi'
import styles from './index.less'
import { Avatar, Progress, message, Modal, Tooltip, notification } from 'antd'
import { StepBackwardOutlined, StepForwardOutlined, PauseOutlined, CaretRightOutlined } from '@ant-design/icons';
import { convertDuration, showMusicStatusMsg } from 'utils/utils'
import subjectDefault from 'assets/subject_default.png'
import { IntlManager } from 'utils/helper'
import musicloading from 'assets/musicloading.gif'

class MusicPlayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            subjectInfo: null, // 孩子信息
            planInfo: null,  // 计划信息
            currentIndex: 0, // 当前播放音乐的下标
            isOpen: false, // 是否在播放中
            currentTime: 0, // 当前播放的位置
            userCourseNum: 1, // 一对多儿童播放到第几节了
            from: '',  // 当前是从那个入口进来的
            canPlay: true, // 音乐播放次数超过三次灰色禁用模式
            bluetoothCheckFlag: false,
            cacheLoading: false,
            statusLoading: false
        }
    }

    renderPropsData = (props) => {
        const {
            subjectInfo,
            planInfo,
            currentIndex,
            isOpen,
            currentTime,
            from,
            userCourseNum,
            duration,
            canPlay,
            cacheLoading,
            statusLoading
        } = props;
        this.setState({
            subjectInfo,
            planInfo,
            currentIndex,
            isOpen,
            currentTime,
            from,
            userCourseNum,
            duration,
            canPlay,
            cacheLoading,
            statusLoading
        })
    }

    changeMusicByType = (type) => {
        const { dispatch } = this.props
        dispatch({ type: 'musicplayer/changeMusicByType', payload: { type } })
    }

    openMusic = () => {
        // this.changeMusicStatus(true)
        const { musicplayer: { showGlobalMusicplayer, bluetoothCheckFlag }, dispatch } = this.props;
        if (!showGlobalMusicplayer) {
            if(!bluetoothCheckFlag) {
                dispatch({ type: 'musicplayer/updateState', payload: { testMusicModalVisible: true, bluetoothCheckFlag: true } })
                this.setState({isFirstPlayClick: false})
            } else {
                getDvaApp()._store.dispatch({
                    type: 'musicplayer/changeMusicStatus',
                    payload: { status: true },
                })
            }
        } else {
            this.changeMusicStatus(true)
        }
    }

    changeMusicStatus = (status) => {
        const { dispatch } = this.props;
        dispatch({ type: 'musicplayer/changeMusicStatus', payload: { status } })
    }

    static getDerivedStateFromProps(props, state) {
        const { musicplayer } = props;
        const {
            subjectInfo,
            planInfo,
            currentIndex,
            isOpen,
            currentTime,
            from,
            userCourseNum,
            duration,
            canPlay,
            cacheLoading,
            statusLoading
        } = musicplayer;
        return {
            subjectInfo,
            planInfo,
            currentIndex,
            isOpen,
            currentTime,
            from,
            userCourseNum,
            duration,
            canPlay,
            cacheLoading,
            statusLoading
        }
    }

    // componentWillReceiveProps(props) {
    //     const { musicplayer } = props;
    //     this.renderPropsData(musicplayer);
    // }

    componentDidMount() {
        let oldProgress = 0;

        window.audio = document.getElementById('musicplayer');

        const { dispatch, musicplayer } = this.props;
        // 渲染父级数据
        this.renderPropsData(musicplayer)


        // 监听播放进度
        window.audio.ontimeupdate = () => {
            // 非最后一首音乐播放到50，75，85进度时，检测后续音乐缓冲状态
            // 后续音乐还在缓冲中时提醒：音频正在缓存中，您当前的网络较差，请及时检查网络，避免影响后续音乐播放！
            const progress = parseInt((window.audio.currentTime / window.audio.duration) * 100);

            if (oldProgress != progress) {
                // 同步新进度
                oldProgress = progress;

                const { musicplayer } = this.props;

                const { currentIndex, planInfo } = musicplayer;
                const tracks = planInfo.iLs && planInfo.iLs.tracks
                const musicList = tracks ? tracks.map(item => item.track && item.track.url) : [];

                // 说明是最后一首，就不检测缓存进度
                if (currentIndex != musicList.length - 1) {
                    const { myMusicBlobList } = musicplayer;

                    if ((progress == 50 || progress == 75 || progress == 85) && !Boolean(myMusicBlobList.length)) {
                        notification.destroy();
                        showMusicStatusMsg(IntlManager.intl, 'currentlyCachingAudio')
                        // message.error(IntlManager.intl.formatMessage({ id: 'currentlyCachingAudio' }));
                    }
                }
            }

            dispatch({
                type: 'musicplayer/updateState', payload: {
                    currentTime: window.audio.currentTime,
                }
            })
        }

        window.audio.onplay = () => {
            const { musicplayer } = this.props;
            const { isOpen } = musicplayer;
            if (!isOpen) {
                this.changeMusicStatus(true)
            }
        }

        window.audio.onpause = () => {
            const { musicplayer } = this.props;
            const { isOpen } = musicplayer;
            if (isOpen && window.audio.currentTime != window.audio.duration) {
                this.changeMusicStatus(false)
            }
        }

        window.audio.onloadedmetadata = () => {
            oldProgress = 0;
            dispatch({
                type: 'musicplayer/updateState', payload: {
                    duration: window.audio.duration,
                }
            })
        }

        window.audio.onended = () => {
            const { currentIndex, planInfo } = this.state;
            const tracks = planInfo.iLs && planInfo.iLs.tracks
            const musicList = tracks ? tracks.map(item => item.track && item.track.url) : [];
            // 说明是最后一首，就不自动播放下一首，否则自动播放下一首
            // if (navigator.onLine) {
                if (currentIndex == musicList.length - 1) {
                    dispatch({ type: 'musicplayer/changeMusicStatus', payload: { status: false } })
                } else {
                    dispatch({ type: 'musicplayer/changeMusicByType', payload: { type: 'right' } })
                }
            // } else {
            //     // 断网
            //     dispatch({
            //         type: 'musicplayer/changeMusicStatus',
            //         payload: { status: false, needSubmitProgress: false, canRequest: false },
            //     })
            //     notification.destroy()
            //     showMusicStatusMsg(IntlManager.intl, 'error')
            // }
        }

        window.audio.onerror = () => {
            Modal.warning({
                title: IntlManager.intl.formatMessage({ id: 'currentFileCannotplayed' }),
                content: '',
            })

            dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' })
        }

        window.parmEvent = new CustomEvent('rePlayMusic');
        window.addEventListener('rePlayMusic', async (e) => {
            e.preventDefault()
            dispatch({ type: 'musicplayer/changeMusicByIndex', payload: { index: 0 } })
        })
    }

    render() {
        const intl = IntlManager.MyIntl()

        const { subjectInfo, planInfo, currentIndex, isOpen, currentTime, duration, from, userCourseNum, canPlay, cacheLoading, statusLoading } = this.state;
        // const musicCanPlay = navigator.onLine && canPlay
        const musicCanPlay = canPlay
        const tracks = planInfo && planInfo.iLs && planInfo.iLs.tracks || [];
        // const musicList = tracks.length > 0 ? tracks.map(item => item.track && item.track.url) : [];
        const isLoading = cacheLoading || statusLoading
        const track = tracks[currentIndex]

        let title = intl('whichMusic', { num: planInfo && planInfo.num || 1 });
        if (from === 'groupClassDetail') {
            // 一对多显示音乐名称 + 儿童上的总的课时数
            title = track && track.courseCode.split(' ')[0] + ' ' + userCourseNum
        }
        return (
            <div className={styles.musicPlayer} id='musicPlayer'>
                <div className={styles.musicPlayerInfo}>
                    <div className={styles.musicPlayerAvatar}>
                        <Avatar src={subjectInfo && subjectInfo.picture || subjectDefault} className={styles.musicPlayerAvatarUI} />
                        <div className={styles.musicPlayerAvatarName}>{subjectInfo && subjectInfo.name}</div>
                    </div>
                    {
                        from === 'trialSessionPlan' ? (
                            <div className={styles.musicPlayerMusicInfo}>
                                <div className={styles.musicPlayerInfoName}>{currentIndex + 1} {tracks.length > 0 && `${track && track.code}`}</div>
                            </div>
                        ) : (
                            <div className={styles.musicPlayerMusicInfo}>
                                <div className={styles.musicPlayerInfoName}>{currentIndex + 1} {tracks.length > 0 && `${track && track.courseCode}-${track && track.code}`}</div>
                                <div className={styles.musicPlayerInfoCourseName}>{title}</div>
                            </div>
                        )
                    }
                    <div className={styles.musicPlayerSwitch}>
                        <StepBackwardOutlined
                            className={/*currentIndex == 0 ? styles.musicPlayerSwitchLeftDisabled : styles.musicPlayerSwitchLeft */styles.musicPlayerSwitchLeftDisabled}
                            onClick={() => { /* currentIndex != 0 && this.changeMusicByType('left') */ }}
                        />
                        <div className={styles.musicPlayerSwitchCenter}>
                            <div className={styles.musicPlayerSwitchCenterTop}>
                                <Progress
                                    type="circle"
                                    percent={(currentTime / duration * 100)}
                                    width={60}
                                    strokeWidth={8}
                                    className={styles.musicPlayerSwitchProgress}
                                    showInfo={false}
                                    strokeColor={musicCanPlay ? '#1890FF' : '#b3b5b6'}
                                />
                                <div className={styles.musicPlayerSwitchController}>
                                    {
                                        isLoading ?
                                            <img
                                                src={musicloading}
                                                width='50'
                                                onClick={() => {
                                                    if (cacheLoading) {
                                                        message.info(intl('resourceBuffering'))
                                                    }
                                                }}
                                            />
                                            :
                                            isOpen ?
                                                <PauseOutlined
                                                    className={musicCanPlay ? styles.musicPlayerSwitchControllerOpenIcon : styles.musicPlayerSwitchControllerDisableIcon}
                                                    onClick={() => {musicCanPlay && this.changeMusicStatus(false) }}
                                                />
                                                :
                                                <CaretRightOutlined
                                                    className={musicCanPlay ? styles.musicPlayerSwitchControllerCloseIcon : styles.musicPlayerSwitchControllerDisableIcon}
                                                    onClick={() => { musicCanPlay && this.openMusic() }}
                                                />
                                    }
                                </div>
                            </div>
                            <div className={styles.musicPlayerSwitchCenterBottom}>
                                {`${convertDuration(currentTime)}/${convertDuration(duration)} `}
                            </div>
                        </div>
                        <StepForwardOutlined
                            className={/*currentIndex == musicList.length - 1 ? styles.musicPlayerSwitchRightDisabled : styles.musicPlayerSwitchRight*/styles.musicPlayerSwitchRightDisabled}
                            onClick={() => {
                                // !(currentIndex == musicList.length - 1) && this.changeMusicByType('right')
                            }}
                        />
                    </div>
                </div>

                <audio id='musicplayer' preload="auto"></audio>

            </div>
        )
    }
}

export default connect(({ musicplayer, loading }) => ({ musicplayer, loading }))(MusicPlayer)


import { useEffect, useState } from 'react';
import { Modal, Button, message } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import styles from './index.less';
import { IntlManager } from 'utils/helper';

import { PostTrainingProgramSuggestionReview } from 'services/suggestion';

const ApplyModal = ({ visible, currentItem, reloadPage, onCloseModal }) => {
  const intl = IntlManager.MyIntl();

  const { id, passed } = currentItem || {};

  const [auditResult, setAuditResult] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (passed != null) {
      setAuditResult(passed ? 'Passed' : 'NotPassed');
    }
    return () => {
      setAuditResult('');
    };
  }, [passed]);

  const onOk = async () => {
    setLoading(true);
    if (!auditResult) {
      message.warning('请选择审核结果');
      setLoading(false);
      return;
    }

    const params = {
      passed: auditResult === 'Passed',
      id,
    };

    const res = await PostTrainingProgramSuggestionReview(params);

    if (res.response.ok) {
      reloadPage();
      onCloseModal();
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }

    setLoading(false);
  };

  const onCancel = () => {
    onCloseModal();
  };

  return (
    <Modal
      title={<b style={{ fontSize: '17px' }}>{intl('gameGamification')}</b>}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
    >
      <div className={styles.applyModal}>
        <div className={styles.applyModalButton}>
          <div
            className={styles.applyModalButtonItem}
            style={{
              background: auditResult === 'Passed' ? '#1890FF' : 'white',
              color: auditResult === 'Passed' ? 'white' : '#000',
              border:
                auditResult === 'Passed'
                  ? '1px solid rgba(0,0,0,0)'
                  : '1px solid #ccc',
            }}
            onClick={() => setAuditResult('Passed')}
          >
            <CheckOutlined /> {intl('pass')}
          </div>
          <div
            className={styles.applyModalButtonItem}
            style={{
              background: auditResult === 'NotPassed' ? '#FF6600' : 'white',
              color: auditResult === 'NotPassed' ? 'white' : '#000',
              border:
                auditResult === 'NotPassed'
                  ? '1px solid rgba(0,0,0,0)'
                  : '1px solid #ccc',
            }}
            onClick={() => setAuditResult('NotPassed')}
          >
            <CloseOutlined /> {intl('decline')}
          </div>
        </div>
        <div className={styles.applyModalBottom}>
          <Button style={{ width: '100px' }} onClick={() => onCancel()}>
            {intl('cancel')}
          </Button>
          <Button
            type="primary"
            loading={loading}
            style={{ width: '100px', marginLeft: '10px' }}
            onClick={() => onOk()}
          >
            {intl('submitOther')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ApplyModal;

import React from 'react';
import { Image } from 'antd';
import { useIntl } from 'umi';
import styles from './index.less';

const CategoryDescription = ({ trialData }) => {
  const intl = useIntl();

  const { items = [] } = trialData || {};

  const getSplitType = (content) => {
    if (content) {
      if (content.includes('：')) {
        return '：';
      }
      if (content.includes(':')) {
        return ':';
      }
    }

    return ':';
  };

  const filterData = items.filter((item) => item.trialPlanItems.length > 0);

  return (
    <div className={styles.categoryDescriptionContent}>
      {filterData.map((item, index) => {
        const { category } = item || {};
        const {
          brainImages = [],
          deficits = [],
          works = [],
          displayName,
          id,
        } = category || {};

        let url;

        const isNeedBreak = index !== 0 && index % 2 === 0;

        if (brainImages && brainImages.length > 0) {
          url = brainImages[0]?.url;
        }

        return (
          <div
            key={id}
            className={styles.descItemTotal}
            style={isNeedBreak ? { pageBreakBefore: 'always' } : {}}
          >
            <div className={styles.descItem}>
              <div className={styles.itemLeft}>
                <div className={styles.itemTitle}>{displayName}</div>
                <hr className={styles.itemLine} />
                <div className={styles.itemContent}>
                  <div className={styles.itemContentTitle}>
                    {intl.formatMessage({ id: 'Mechanism of Action' })}
                  </div>
                  {works?.map((work) => {
                    const { content } = work || {};
                    const [title, des] = content?.split(getSplitType(content));

                    return (
                      <div className={styles.itemContentDetail} key={title}>
                        <span className={styles.itemDetailTitle}>
                          {title}:{' '}
                        </span>
                        <span>{des}</span>
                      </div>
                    );
                  })}
                </div>
                <hr />
                <div className={styles.itemContent}>
                  <div className={styles.itemContentTitle}>
                    {intl.formatMessage({
                      id: 'Challenges Areas for Improvement',
                    })}
                  </div>
                  {deficits?.map((deficit) => {
                    const { content, example } = deficit || {};
                    const [title, des] = content?.split(getSplitType(content));
                    return (
                      <div className={styles.itemContentDetail} key={title}>
                        <span className={styles.itemDetailTitle}>
                          {title}:{' '}
                        </span>
                        <span>{des}</span>
                        <div className={styles.everydayLives}>
                          <div className={styles.everydayLivesTitle}>
                            {intl.formatMessage({
                              id: 'Examples of daily life',
                            })}
                            :{' '}
                          </div>
                          <div>{example}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={styles.itemRight}>
                {url && <Image width={240} src={url} preview={false} alt="" />}
              </div>
            </div>

            <hr />
            {index == filterData.length - 1 && (
              <div className={styles.courtesy}>{intl.formatMessage({ id: 'Image courtesy' })}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default CategoryDescription;

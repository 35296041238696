import React from 'react';
import { Card, Progress } from 'antd';
import { history, useIntl } from 'umi';
import WeekCalendar from './WeekCalendar';

import styles from './index.less';

const TrainingCom = ({ data }) => {
  const intl = useIntl();

  const { finishDays, status, plan, coach, group, subject } = data || {};
  const { currentNum, totalNum, userId } = plan || {};
  const { name, groupTrainingPlans = [], id: classId } = group || {};
  const groupTrainingPlan = groupTrainingPlans.length
    ? groupTrainingPlans[0]
    : {};
  const isOneToMore = plan === null;

  const TopCom = () => (
    <div className={styles.titleCom}>
      <div className={styles.leftCom}>
        <div className={styles.childName}>{isOneToMore ? name : subject}</div>
        {isOneToMore && (
          <div className={styles.groupName}>
            {intl.formatMessage({ id: 'subjectClass' })}:{' '}
            {subject || intl.formatMessage({ id: 'noYet' })}
          </div>
        )}
        <div className={styles.trainerName}>
          {intl.formatMessage({ id: 'trainer' })}:{' '}
          {coach || intl.formatMessage({ id: 'noYet' })}
        </div>
      </div>
      <div className={styles.rightCom}>
        {isOneToMore ? (
          <div className={styles.rightComGroupTitle}>
            {intl.formatMessage(
              { id: 'whichClass' },
              {
                currentNum: (
                  <span className={styles.activeProgress}>
                    {groupTrainingPlan?.currentNum}
                  </span>
                ),
              },
            )}
          </div>
        ) : (
          <div>
            <div className={styles.rightComTitle}>
              {intl.formatMessage({ id: 'trainProgress' })}:
              <span>
                <span className={styles.activeProgress}>{currentNum}</span>/
                {totalNum}
              </span>
            </div>
            <Progress
              size="small"
              percent={(currentNum / totalNum) * 100}
              showInfo={false}
              strokeColor="#55B3B7"
            />
          </div>
        )}

        <div className={styles.classCom}>
          {intl.formatMessage({ id: 'classStatus' })}:{' '}
          <div className={styles.classStatus}>{status}</div>
        </div>
      </div>
    </div>
  );

  const BottomCom = () => (
    <div className={styles.bottomCom}>
      <div className={styles.bottomTitle}>
        {intl.formatMessage({ id: 'weekProgress' })}
      </div>
      <WeekCalendar finishDays={finishDays} />
    </div>
  );

  const getScreenWidthSize = () => {
    const screenWidth = screen.width;

    if (screenWidth <= 1100) {
      return '45vw';
    }
    if (screenWidth <= 1500) {
      return '32vw';
    }
    return '23vw';
  }

  return (
    <Card.Grid
      style={{ width: getScreenWidthSize(), cursor: 'pointer', overflow: 'hidden' }}
      onClick={() => {
        if (isOneToMore) {
          window.open(
            `/system/center/detail/groupClassDetail?classId=${classId}`,
            '_blank',
          );
        } else {
          history.push({
            pathname: '/system/center/detail/subjectDetail',
            query: {
              id: userId,
            },
          });
        }
      }}
    >
      <div className={styles.TrainingCom}>
        <TopCom />
        <hr
          style={{
            backgroundColor: '#E9E9E9',
            height: '1px',
            border: 'none',
            margin: '14px 0',
          }}
        />
        <BottomCom />
      </div>
    </Card.Grid>
  );
};

export default React.memo(TrainingCom);

import React from 'react';
import { Modal } from 'antd';
import { useIntl, connect } from 'umi';
import moment from 'moment';
import { DownloadOutlined } from '@ant-design/icons';

import styles from './index.less';

const AssessmentQrCode = ({
  type,
  visible,
  url,
  currentUser,
  assessmentInfo,
  subjectDetail,
  downloadAssessmentQR,
  onCancel,
}) => {
  const intl = useIntl();

  // const { changedTenant, name } = currentUser || {};
  // const { displayName } = changedTenant || {};
  const { title, userAssessment } = assessmentInfo || {};
  const { creationTime } = userAssessment || {};
  const { subjectInfo } = subjectDetail || {};
  const { name } = subjectInfo || {};

  return (
    <Modal
      open={visible}
      footer={null}
      closable={true}
      width={300}
      className={styles.centerQrModal}
      onCancel={onCancel}
    >
      <div>
        <h2>
          <b>{title}</b>
        </h2>
      </div>
      {/* <div>
        <h3>{intl.formatMessage({ id: 'useWechatSee' })}</h3>
      </div> */}
      <div>
        <h3><b>{name}</b></h3>
      </div>
      <img src={url} width={200} />
      {/* <div className={styles.centerName}>
        {displayName}_{name}
      </div> */}
      <div>
        {intl.formatMessage({ id: 'assessmentExpiryDate' })}
        {moment(creationTime).local().add(30, 'd').format('MM/DD/YYYY')}
      </div>
      <p />
      <div className={styles.downloadCenterQr} onClick={downloadAssessmentQR}>
        {intl.formatMessage({ id: 'saveImage' })}&nbsp;
        <DownloadOutlined />
      </div>
    </Modal>
  );
};

export default connect(({ subjectDetail }) => ({
  subjectDetail,
}))(AssessmentQrCode);

import React, { useState, useEffect } from 'react';
import { connect, useIntl } from 'umi';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import FeedbackDrawer from 'components/GroupFeedbackDrawer';

import {
  GetFeedBack,
  PostFeedBack,
  PutFeedBack,
  PostFeedBackTotal,
  PutFeedBackTotal,
  PostChildFeedback,
  Done,
  DoneNum,
  GetChildFeedback,
  PutChildFeedback,
  GetGroupCategoryPunch,
  PutGroupCategoryPunch,
  PostGroupCategoryPunch,
} from 'services/group';
import { getNextKey, getDisabledValue, getHasDoneValue } from 'utils/utils';

const feedback = ({
  currentUser,
  drawVisible,
  CourseNum,
  isStuding,
  defaultTab = 'sessionFeedback',
  guardianSubmit = () => {},
  GroupTrainingPlanId,
  onClose = () => {},
}) => {
  const intl = useIntl()

  const {
    auth: { grantedPolicies },
    changedTenant,
  } = currentUser;

  const [activeKey, setActiveKey] = useState([]);
  const [activeTab, setActiveTab] = useState(defaultTab === 'sessionFeedback' ? 'class' : 'child');
  const [childEdit, setChildEdit] = useState(false);
  const [childFeedbackData, setChildFeedbackData] = useState([]);
  const [selectCategoryInfo, setSelectCategoryInfo] = useState([]);
  const [loading, setLoading] = useState({
    itemLoading: false,
    drawLoading: false,
  });
  const [selectCourseInfo, setSelectCourseInfo] = useState({});
  const [selectGuardianCourseInfo, setSelectGuardianCourseInfo] = useState({});

  const hasOfflinePlanWritePermission =
    grantedPolicies['RocketSystem.UserOfflinePlan.Write'] === true;
  const hasOfflinePlanReadPermission =
    grantedPolicies['RocketSystem.UserOfflinePlan'];
  const rocketProjectStatus =
    changedTenant && changedTenant.projectStatus
      ? changedTenant.projectStatus.find(
          (item) => item.name == 'Project.Rocket',
        )['status']
      : true;


  const onCategoryFeedback = async (data = []) => {
    setLoading({
      ...loading,
      drawLoading: true,
    });

    const resList = await Promise.all(
      data.map((item) =>
        GetGroupCategoryPunch({
          GroupTrainingPlanId,
          CourseNum,
          SubjectId: item,
        }),
      ),
    );

    if (resList.every((item) => item.response.ok)) {
      const _selectCategoryInfo = resList.map((item, index) => {
        const { feedbacks } = item.data || {};
        const userId = data[index];
        return {
          userId,
          id: `category${userId}`,
          hasDone: Object.keys(feedbacks || {}).length > 0,
          ...item.data,
          feedbacks: [feedbacks],
        };
      });

      // 单个数据更新
      if (data.length == 1 && selectCategoryInfo.length > 0) {
        const findIndex = selectCategoryInfo.findIndex(item => item.userId == data[0]);
        if (findIndex > -1) {
          selectCategoryInfo[findIndex] = _selectCategoryInfo[0];
          setSelectCategoryInfo([...selectCategoryInfo]);
        }
      } else {
        setSelectCategoryInfo(_selectCategoryInfo);
      }
    }

    setLoading({
      ...loading,
      drawLoading: false,
    });
  };

  const getClassData = async () => {
    setLoading({
      ...loading,
      drawLoading: true,
    });
    const res = await GetFeedBack({ GroupTrainingPlanId, CourseNum });
    if (res.response.ok) {
      const { groupTrainingPlanCourse, groupTrainingPlan } = res.data;
      const { trainingPlanV2, currentNum } = groupTrainingPlan;
      const {
        feedbacks,
        courseItems,
        num,
        feedbackProperties,
        id,
        hasDone,
        nonePlaybook = [],
        minStartTime,
      } = groupTrainingPlanCourse;

      const allData = {
        num,
        currentNum,
        nonePlaybook, // 静态活动数据
        planItems: courseItems,
        userOfflineTrainingPlan: trainingPlanV2,
      };

      // 课时整体反馈
      const guardianCourseInfo = {
        id,
        num,
        feedbacks:
          feedbacks.length > 0
            ? [
                {
                  creationTime: feedbacks[0].creationTime,
                  feedbackProperties: feedbacks[0].feedbackProperties.map(
                    (item) => {
                      return {
                        ...item,
                        displayName: item.programFeedbackProperty.displayName,
                      };
                    },
                  ),
                },
              ]
            : [],
        feedbackProperties,
        minStartTime,
        userOfflineTrainingPlan: trainingPlanV2,
        hasDone,
      };
      setSelectCourseInfo(allData);
      setSelectGuardianCourseInfo(guardianCourseInfo);

      findActiveKeys('guardian', guardianCourseInfo, allData);
    }
    setLoading({
      ...loading,
      drawLoading: false,
    });
  };

  const getChildData = async () => {
    setLoading({
      ...loading,
      drawLoading: true,
    });
    const params = {
      GroupTrainingPlanId,
      CourseNum,
    };
    const res = await GetChildFeedback(params);
    if (res.response.ok) {
      const { planItems = [], nonePlaybook = [] } = selectCourseInfo || {};

      const categoryDisabled = planItems.some(item => item.hasDone == null) || nonePlaybook.some(item => item.hasDone == null)

      if (!categoryDisabled) {
        onCategoryFeedback(res.data.map(item => item.userId))
      }

      setChildFeedbackData(res.data);
      findChildActiveKeys(res.data);
    }
    setLoading({
      ...loading,
      drawLoading: false,
    });
  };

  useEffect(() => {
    activeTab === 'class' ? getClassData() : getChildData();
  }, [activeTab]);

  const { num, currentNum } = selectCourseInfo
  const isCurrentNum = num === currentNum

  const findChildActiveKeys = (data = []) => {
    // 出席并且没填反馈的儿童
    const res = data.find(item => item.hasDone === null && item.attendance === "Attend");
    const { id = '' } = res || {}
    setActiveKey(id !== '' ? [id] : []);
  }

  const findActiveKeys = (
    type,
    selectGuardianCourseInfo,
    selectCourseInfo,
    id,
  ) => {
    const { hasDone: guardianHasDone, userOfflineTrainingPlan } =
      selectGuardianCourseInfo;
    const { planItems, nonePlaybook } = selectCourseInfo;

    const guardianId = !isStuding
      ? getDisabledValue({
          hasDone: guardianHasDone,
          rocketProjectStatus,
          hasOfflinePlanWritePermission,
        }) === false &&
        getHasDoneValue({
          hasDone: guardianHasDone,
          rocketProjectStatus,
          hasOfflinePlanWritePermission,
        })
        ? userOfflineTrainingPlan.id
        : null
      : null;

    let tempActiveKey;

    switch (type) {
      case 'guardian':
        tempActiveKey =
          guardianId ||
          getNextKey({
            planitems: planItems,
            nonePlayBooks: nonePlaybook,
            rocketProjectStatus,
            hasOfflinePlanWritePermission,
          });
        break;
      case 'music':
        tempActiveKey =
          getNextKey({
            planitems: planItems,
            nonePlayBooks: nonePlaybook,
            rocketProjectStatus,
            hasOfflinePlanWritePermission,
          }) || guardianId;
        break;
      default:
        if (id)
          tempActiveKey =
            getNextKey({
              id,
              planitems: planItems,
              nonePlayBooks: nonePlaybook,
              rocketProjectStatus,
              hasOfflinePlanWritePermission,
            }) ||
            guardianId ||
            getNextKey({
              planitems: planItems,
              nonePlayBooks: nonePlaybook,
              rocketProjectStatus,
              hasOfflinePlanWritePermission,
            });
        else {
          tempActiveKey =
            guardianId ||
            getNextKey({
              planitems: planItems,
              nonePlayBooks: nonePlaybook,
              rocketProjectStatus,
              hasOfflinePlanWritePermission,
            });
        }
        break;
    }

    setActiveKey(tempActiveKey !== null ? [tempActiveKey] : []);
  };

  const onFeedback = async (id) => {
    findActiveKeys('training', selectGuardianCourseInfo, selectCourseInfo, id);
  };

  const onChangeTabs = (key) => {
    setChildEdit(false);
    setActiveTab(key);
  };

  const drawerProps = {
    rocketProjectStatus,
    childFeedbackData,
    hasOfflinePlanWritePermission,
    hasOfflinePlanReadPermission,
    guardianCourseInfo: selectGuardianCourseInfo,
    categoryInfo: selectCategoryInfo,
    loading: loading.itemLoading,
    drawLoading: loading.drawLoading,
    allData: selectCourseInfo,
    visible: drawVisible,
    activeKey,
    activeTab,
    childEdit,
    isCurrentNum,
    onClose,
    onChangeTabs,
    onSubmit: async (feedbacks, trainingProgramId, planItemId, hasDone) => {
      // 训练项目提交
      setLoading({
        ...loading,
        itemLoading: true,
      });
      const res =
        hasDone == null
          ? await PostFeedBack({
              feedbacks,
              groupCourseItemId: planItemId,
            })
          : await PutFeedBack({
              feedbacks,
              groupCourseItemId: planItemId,
            });
      if (res.response.ok) {
        getClassData();
        onFeedback(planItemId);
      }
      setLoading({
        ...loading,
        itemLoading: false,
      });
    },
    onCategorySubmit: async (data) => {
      const { hasDone, subjectId } = data
      // 如果hasDone==null代表的是从未提交过，使用PostPunch，如果是修改就使用PutPunch
      const res =
        hasDone == null ?
        await PostGroupCategoryPunch(data) :
        await PutGroupCategoryPunch(data);

      if (res.response.ok) {
        onCategoryFeedback([subjectId])
      } else {
        const { error } = res.data || {}
        message.error(error.message)
      }
    },
    onChildSubmit: async (feedbacks, groupUserCourseItemId, hasDone) => {
      setLoading({
        ...loading,
        itemLoading: true,
      });
      const res =
        hasDone == null
          ? await PostChildFeedback({
              feedbacks,
              groupUserCourseItemId,
            })
          : await PutChildFeedback({
              feedbacks,
              groupUserCourseItemId,
            });
      if (res.response.ok) {
        getChildData();
        childEdit && setChildEdit(false);
      }
      setLoading({
        ...loading,
        itemLoading: true,
      });
    },
    onChangeCollapse(data, type, hasDone, item) {
      // 第一次提交整体反馈需结束上课
      if (isStuding && type === 'guardian' && !hasDone) {
        Modal.confirm({
          title: intl.formatMessage({ id: 'notStartedFillFeedback' }),
          icon: <ExclamationCircleOutlined />,
          content: null,
          okText: intl.formatMessage({ id: 'submit' }),
          cancelText: intl.formatMessage({ id: 'cancel' }),
          onOk: guardianSubmit,
        });
        return;
      }
      let { planItems, nonePlaybook } = selectCourseInfo;

      setActiveKey([...data]);

      if (type === 'guardian') {
        // 代表已做
        if (hasDone == true) {
          setSelectGuardianCourseInfo({
            ...selectGuardianCourseInfo,
            isEdit: false,
          });
        } else {
          setSelectGuardianCourseInfo({
            ...selectGuardianCourseInfo,
            isEdit: true,
          });
        }
      }

      if (type === 'activity') {
        let current = null;
        planItems = planItems.map((item) => {
          if (item.id === data[data.length - 1]) {
            const { hasDone } = item || {};
            current = item;
            if (hasDone == true) {
              item.isEdit = false;
            } else {
              item.isEdit = true;
            }
          }
          return item;
        });
        if (!current) {
          nonePlaybook = nonePlaybook.map((item) => {
            if (
              item.id ===
              (activeKey && activeKey.length && activeKey[activeKey.length - 1])
            ) {
              const { hasDone } = item || {};
              current = item;
              if (hasDone == true) {
                item.isEdit = false;
              } else {
                item.isEdit = true;
              }
            }
            return item;
          });
        }

        setSelectCourseInfo({
          ...selectCourseInfo,
          planItems,
          nonePlaybook,
        });
      }

      if (type === 'category') {
        const { userId } = item;
        onCategoryFeedback([userId])
      }

      if (type === 'child') {
        setChildEdit(false);
      }
    },
    onGuardianFeedback: async (submitData, guardianHasDone) => {
      setLoading({
        ...loading,
        itemLoading: true,
      });
      // 整体反馈提交
      const { feedbacks } = submitData;
      const props = {
        feedbacks,
        groupTrainingPlanCourseId: selectGuardianCourseInfo.id,
      };
      const res =
        guardianHasDone == null
          ? await PostFeedBackTotal(props)
          : await PutFeedBackTotal(props);
      if (res.response.ok) {
        getClassData();
      } else {
        const { error } = res.data;
        message.error(error.message);
      }
      setLoading({
        ...loading,
        itemLoading: false,
      });
    },
    onNotDo: async (id) => {
      setLoading({
        ...loading,
        itemLoading: true,
      });
      const res = await Done({
        groupCourseItemId: id,
      });
      if (res.response.ok) {
        getClassData();
        onFeedback(id);
      } else {
        const { error } = res.data || {};
        message.error(error.message);
      }
      setLoading({
        ...loading,
        itemLoading: false,
      });
    },
    onGuardianNotDo: async ({ id, guardianNum, notFinishAudios }) => {
      // 第一次提交整体反馈需结束上课
      if (isStuding) {
        Modal.confirm({
          title: intl.formatMessage({ id: 'notStartedFillFeedback' }),
          icon: <ExclamationCircleOutlined />,
          content: null,
          okText: intl.formatMessage({ id: 'submit' }),
          cancelText: intl.formatMessage({ id: 'cancel' }),
          onOk: guardianSubmit,
        });
        return;
      }
      setLoading({
        ...loading,
        itemLoading: true,
      });
      const res = await DoneNum({
        groupTrainingPlanCourseId: selectGuardianCourseInfo.id,
        hasDone: false,
      });
      if (res.response.ok) {
        getClassData();
        onFeedback();
      } else {
        const { error } = res.data;
        message.error(error.message);
      }
      setLoading({
        ...loading,
        itemLoading: false,
      });
    },
    eidtItem(data, type) {
      if (type === 'guardian') {
        const { userOfflineTrainingPlan } = selectGuardianCourseInfo;
        const { id } = userOfflineTrainingPlan || {};
        setActiveKey(id);
        setSelectGuardianCourseInfo({
          ...selectGuardianCourseInfo,
          isEdit: true,
        });
      }

      if (type === 'activity') {
        let { planItems, nonePlaybook } = selectCourseInfo;
        const { hasDone, id } = data || {};
        setActiveKey(id);
        if (hasDone == true) {
          planItems = planItems.map((item) => {
            if (item.id === id) {
              item.isEdit = true;
            }
            return item;
          });
          nonePlaybook = nonePlaybook.map((item) => {
            if (item.id === id) {
              item.isEdit = true;
            }
            return item;
          });
        } else {
          planItems = planItems.map((item) => {
            if (item.id === id) {
              item.isEdit = false;
            }
            return item;
          });
          nonePlaybook = nonePlaybook.map((item) => {
            if (item.id === id) {
              item.isEdit = false;
            }
            return item;
          });
        }
        setSelectCourseInfo({
          ...selectCourseInfo,
          planItems,
          nonePlaybook,
        });
      }

      if (type === 'category') {
        const { id, userId } = data;
        setActiveKey(id);

        const findIndex = selectCategoryInfo.findIndex(
          (item) => item.userId == userId,
        );
        if (findIndex > -1) {
          selectCategoryInfo[findIndex].isEdit = true;
          setSelectCategoryInfo([...selectCategoryInfo]);
        }
      }

      if (type === 'child') {
        const { id } = data
        setActiveKey(id);
        setChildEdit(true);
      }
    },
  };

  return <FeedbackDrawer {...drawerProps} />;
};

export default connect(({ user }) => ({
  currentUser: user.currentUser,
}))(feedback);

import { useState, useEffect } from 'react';
import { Button, Card, message, Input } from 'antd';
import { useIntl, history, connect } from 'umi';

import { GetUserList } from 'services/userContract';

import AddContractModal from 'components/AddContractModal';
import AnalysisTenantSelect from 'components/AnalysisTenantSelect';

import MagicTable from 'cognitiveleap-core-us/components/MagicTable/MagicTable';

const { Search } = Input;

const listSupport = (intl, isAdmin, grantedPolicies) => {
  const havePlanUserDetailPermission = grantedPolicies['RocketSystem.PlanUser'];

  return {
    creationTime: {
      showText: intl.formatMessage({ id: 'Last Added Date' }),
      showType: 'Time',
    },
    subjectName: {
      showText: intl.formatMessage({ id: 'subjectName' }),
      showType: 'Text',
      render: (text, record) => {
        const { userId } = record || {};

        return havePlanUserDetailPermission ? (
          <a
            onClick={() => {
              history.push({
                pathname: isAdmin
                  ? '/system/manage/subjectDetail'
                  : '/system/center/detail/subjectDetail',
                query: {
                  id: userId,
                },
              });
            }}
          >
            {text}
          </a>
        ) : <span>{text}</span>;
      },
    },
    contractCount: {
      showText: intl.formatMessage({ id: 'Number of Contracts' }),
      showType: 'Text',
    },
    totalClass: {
      showText: intl.formatMessage({ id: 'PrePaid Sessions' }),
      showType: 'Text',
    },
    paidClass: {
      showText: intl.formatMessage({ id: 'Paid Sessions' }),
      showType: 'Text',
    },
    usedClass: {
      showText: intl.formatMessage({ id: 'Used Sessions' }),
      showType: 'Text',
    },
    remainingClass: {
      showText: intl.formatMessage({ id: 'User Remaining Sessions' }),
      showType: 'Text',
      render: (text, _) => {
        return (
          <span style={{ color: text <= 3 ? 'red' : 'black' }}>{text}</span>
        );
      },
    },
  };
};

const UserContractConfiguration = ({ currentUser }) => {
  const intl = useIntl();

  const { adminRoles, current, canChangeList, auth  } = currentUser;
  const { grantedPolicies } = auth || {};

  const haveCreateContractPermission = grantedPolicies['RocketSystem.UserContract.Create'];

  const isAdmin = adminRoles.length > 0 && current === null;

  const [visible, setVisible] = useState({
    addContractModalVisible: false,
  });

  const [searchKey, setSearchKey] = useState('');
  const [listData, setListData] = useState([]);
  // 翻页数据
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [loading, setLoading] = useState({
    tableLoading: false,
    buttonLoading: false,
    modalLoading: false,
  });

  const [currentTenant, setCurrentTenant] = useState({
    id: '',
    displayName: intl.formatMessage({ id: 'statisticsTotal' }),
  });

  const changedTenantList = (id) => {
    if (id) {
      const newCurrentTenant = canChangeList.find((item) => item.id == id);
      setCurrentTenant({ ...newCurrentTenant });
    } else {
      setCurrentTenant({
        id: '',
        displayName: intl.formatMessage({ id: 'statisticsTotal' }),
      });
    }
  };

  const initData = async () => {
    setLoading({
      ...loading,
      tableLoading: true,
    });
    const res = await GetUserList({
      SkipCount: (pagination.current - 1) * pagination.pageSize,
      MaxResultCount: pagination.pageSize,
      Filter: searchKey,
      TenantId: currentTenant.id,
    });

    if (res.response.ok) {
      const { totalCount, items } = res.data || {};
      setListData(items);
      setPagination({
        ...pagination,
        total: totalCount,
      });
    } else {
      const {
        data: { error },
      } = res;
      message.error(error.message);
    }
    setLoading({
      ...loading,
      tableLoading: false,
    });
  };

  useEffect(() => {
    initData();
  }, [pagination.pageSize, pagination.current, searchKey, currentTenant.id]);

  const tableProps = {
    listData: listData.map((item) => {
      const { subject, paidClass, usedClass } = item || {};
      const { name } = subject || {};
      return {
        ...item,
        subjectName: name,
        remainingClass: paidClass - usedClass,
      };
    }),
    listSupport: listSupport(intl, isAdmin, grantedPolicies),
    loading: loading.tableLoading,
    pagination,
    onTableChange(page) {
      if (JSON.stringify(page) !== '{}') {
        const { current, pageSize } = page;
        setPagination({
          ...pagination,
          current,
          pageSize,
        });
      }
    },
    Actions: [
      {
        showText: intl.formatMessage({ id: 'detail' }),
        itemRender(record) {
          const { userId, subject } = record || {};
          const { name } = subject || {};
          return haveCreateContractPermission ? (
            <a
              onClick={(e) => {
                e.stopPropagation();
                history.push({
                  pathname: isAdmin
                    ? '/system/manage/userContractDetail'
                    : '/system/center/detail/userContractDetail',
                  query: {
                    userId,
                    name,
                  },
                });
              }}
            >
              {intl.formatMessage({ id: 'detail' })}
            </a>
          ) : null;
        },
      },
    ],
  };

  const modalProps = {
    visible: visible.addContractModalVisible,
    type: 'add',
    closeModal: () => {
      setVisible({
        ...visible,
        addContractModalVisible: false,
      });
    },
    reloadPage: initData,
  };

  return (
    <Card
      title={
        <b style={{ fontSize: '20px' }}>
          {intl.formatMessage({ id: 'User Contract Configuration' })}
        </b>
      }
      bordered={false}
      extra={
        isAdmin && (
          <AnalysisTenantSelect
            showAll={true}
            currentTenant={currentTenant}
            onChange={changedTenantList}
          />
        )
      }
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Search
          placeholder={intl.formatMessage({ id: 'searchSubject' })}
          style={{ height: 32, width: 220 }}
          onSearch={(value) => {
            setPagination({
              current: 1,
              pageSize: 10,
              total: 0,
            });
            setSearchKey(value);
          }}
        />
        {(!isAdmin && haveCreateContractPermission) && (
          <Button
            type="primary"
            onClick={() => {
              setVisible({
                ...visible,
                addContractModalVisible: true,
              });
            }}
          >
            {intl.formatMessage({ id: 'Add Contract' })}
          </Button>
        )}
      </div>
      <p />
      <MagicTable {...tableProps} />
      <AddContractModal {...modalProps} />
    </Card>
  );
};

export default connect(({ user }) => ({ currentUser: user.currentUser }))(
  UserContractConfiguration,
);

import React from 'react';
import { getLocale } from 'umi';

const Comments = ({ planItems = [], getImage }) => {
  if (planItems.length === 0) {
    return <></>;
  }

  const { feedbackProperties = [] } = planItems[0];

  const showNoteList = [
    'activityCompletion',
    'adherenceToStandards',
    'confidenceLevel',
    'enjoymentLevel',
  ];

  // 反馈多语言 匹配zh en key
  const language = getLocale() === 'zh-CN' ? 'zh' : 'en';

  return (
    <>
      {feedbackProperties
        .filter((item) => showNoteList.includes(item.name))
        .map((item) => {
          const { displayName, name, extraProperties } = item || {};

          const { options } = extraProperties[language] ?? extraProperties;

          return (
            <tr key={name}>
              <td style={{ width: '20vw' }}>
                <span style={{ fontWeight: 'bold' }}>{displayName}</span>
              </td>
              <td style={{ width: '80vw', textAlign: 'left' }}>
                {options.map((item) => {
                  const { text, value } = item || {};

                  return (
                    <span
                      key={value}
                      style={{
                        marginRight: '8px',
                        display: 'inline-flex',
                        alignItems: 'center',
                      }}
                    >
                      {getImage({
                        imageType: name,
                        imageCount: value,
                        isFeed: true,
                      })}
                      {text};
                    </span>
                  );
                })}
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default Comments;
